import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import QuestionBuilderList from '../components/QuestionBuilderList';
import LoadingBackground from '../components/LoadingBackground';
import useApi from '../functions/useApi';

export default function QuestionBuilder(props) {
  const { get, put } = useApi();
  const [questions, setQuestions] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = React.useState('');

  useEffect(async () => {
    const result = await get({ url: 'questions' });
    setQuestions(result.message);
    setLoading(false);
  }, [refresh]);

  const handleSubmit = async (data) => {
    // console.log(data);
    try {
      await put({
        url: `question/${data.id}`,
        body: JSON.stringify({
          enable: data.enabled,
          default: data.default_question,
        }),
      });
      setRefresh((r) => r + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const filteredSearch = questions?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(query.toLowerCase()) !== -1
  );

  return (
    <div>
      {loading ? (
        <LoadingBackground />
      ) : (
        <div>
          <PageHeader
            title="Question Creation"
            subheader="Disable questions to remove them from current list of questions that can be selected"
            backPath="back"
            showSearch
            setQuery={(q) => setQuery(q)}
          />
          <br />
          {questions && (
            <QuestionBuilderList
              questions={filteredSearch}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      )}
    </div>
  );
}

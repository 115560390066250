import React, { useState, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { ColorPicker } from 'mui-color';
import { makeStyles } from '@mui/styles';
import { DropzoneDialog } from 'react-mui-dropzone';
import useApi from '../functions/useApi';
import Logo from '../assets/demo_logo.png';
import { useAuthContext } from '../context/AuthContext';
import { useCompanyContext } from '../context/CompanyContext';

const styles = makeStyles(({ palette }) => ({
  avatarLogo: {
    width: '192px',
    height: '192px',
  },
  colorPicker: {},
  uploadLogo: { marginTop: '160px' },
  dialogTitle: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
  },
}));

export default function SettingsDialog(props) {
  const authContext = useAuthContext();
  const { dispatchLogoLink } = useCompanyContext();

  const { put, get } = useApi();
  const {
    userDetails,
    changePrimaryColor,
    changeSecondaryColor,
    handleClose,
    open,
    handleCloseDrawer,
  } = props;
  const [newlogo, setNewlogo] = useState(authContext.logo);
  const [uploadOpen, setUploadOpen] = useState(false);
  const classes = styles();

  async function putTheme() {
    console.log('-', userDetails);
    const result = await put({
      url: 'updatecompany',
      body: JSON.stringify(userDetails),
    });
    console.log(result);
  }

  const handleThemeColorChange = (themeType, color) => {
    if (themeType === 'primary') {
      console.log('-', userDetails);
      changePrimaryColor(color);
      userDetails.theme_primary = color;
      putTheme();
    } else if (themeType === 'secondary') {
      userDetails.theme_secondary = color;
      changeSecondaryColor(color);
      putTheme();
    }
  };

  const uploadLogo = async (file) => {
    const result = await get({ url: 'updatecompanylogo' });
    // TODO Handle logo upload errors
    if (result.message.uploadLink) {
      const myHeaders = new Headers({ 'Content-Type': 'image/*' });
      await fetch(result.message.uploadLink, {
        method: 'PUT',
        headers: myHeaders,
        body: file,
      });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      dispatchLogoLink({
        type: 'RELOAD',
        logoLink: result.message.logoLink,
      });
      reader.onload = function () {
        setNewlogo(reader.result);
      };
      reader.onerror = function (error) {
        console.error(error);
      };
    }
  };

  const handleUploadOpen = () => {
    setUploadOpen(!uploadOpen);
  };

  const closeDrawerAndDialog = () => {
    handleClose();
    handleCloseDrawer();
  };

  return (
    <Dialog
      open={open}
      onClose={() => closeDrawerAndDialog()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
        Company Settings
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tooltip title="Update Logo">
              <Button fullWidth onClick={handleUploadOpen}>
                <Avatar
                  className={classes.avatarLogo}
                  variant="square"
                  src={newlogo || Logo}
                />
                <PhotoCamera className={classes.uploadLogo} />
              </Button>
            </Tooltip>

            <DropzoneDialog
              open={uploadOpen}
              acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
              showPreviews
              onSave={(files) => {
                uploadLogo(files[0]);
                handleUploadOpen();
              }}
              dropzoneText="Drag and drop a logo here or click"
              maxFileSize={10000000}
              onClose={handleUploadOpen}
              filesLimit={1}
            />
          </Grid>
          <Grid item xs={8}>
            <div>
              <Tooltip title="Primary colour">
                <FormControlLabel
                  className={classes.colorPicker}
                  control={
                    <ColorPicker
                      hideTextfield
                      className={classes.colorPicker}
                      borderWidth={0}
                      value={userDetails.theme_primary}
                      onChange={(color) =>
                        handleThemeColorChange('primary', `#${color.hex}`)
                      }
                    />
                  }
                  label="Primary Colour"
                />
              </Tooltip>
              <Tooltip title="Secondary colour">
                <FormControlLabel
                  className={classes.colorPicker}
                  control={
                    <ColorPicker
                      hideTextfield
                      className={classes.colorPicker}
                      borderWidth={0}
                      value={userDetails.theme_secondary}
                      onChange={(color) =>
                        handleThemeColorChange('secondary', `#${color.hex}`)
                      }
                      defaultValue="red"
                    />
                  }
                  label="Secondary Colour"
                />
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeDrawerAndDialog()}
          color="primary"
          autoFocus
        >
          Close Settings
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useFormik } from 'formik';
import FormikForm from './FormikForm';
import TagCard from './TagCard';
import { newTagScheme } from '../assets/validator';
import TextInput from './FormInputText';
import FormButton from './FormButton';
import useApi from '../functions/useApi';

const style = makeStyles(({ palette }) => ({
  gridContainer: {
    paddingBottom: '1rem',
  },
  gridTag: {
    backgroundColor: palette.secondary.light,
    borderRadius: '0.2rem',
    marginTop: '1rem',
  },
  cardTag: {
    margin: '0.5rem',
    borderColor: palette.secondary.light,
    borderStyle: 'solid',
    '& .MuiCardHeader-root': {
      backgroundColor: palette.secondary.main,
      minHeight: '5rem',
    },
    '.MuiCardContent-root': {
      padding: '16px',
    },
    '& .MuiCard-root': {
      minHeight: '100rem',
    },
    '& .MuiCardHeader-title': {
      color: palette.secondary.contrastText,
    },
  },
  tagChip: {
    marginLeft: '.2rem',
  },
}));

export default function Tags({ collectionOfTags, reload }) {
  const classes = style();
  const { post } = useApi();

  const formik = useFormik({
    initialValues: { newTag: '' },
    validationSchema: newTagScheme,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const res = await post({
          url: 'addtaggroup',
          body: { name: values.newTag },
        });
        console.log(res);
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
      resetForm();
      reload();
    },
  });

  return (
    <Grid
      key="TagsGridContainer"
      className={classes.gridContainer}
      container
      spacing={1}
    >
      {/* // TODO enable once user edit support is there */}
      {/* <FormikForm formik={formik}>
        <Card className={classes.cardTag}>
          <CardHeader title="New Tag Group" />
          <CardContent>
            <Grid container>
              <Grid item xs={10}>
                <TextInput
                  key="newTag"
                  id="newTag"
                  name="newTag"
                  placeholder="Enter new tag Group"
                  width="80%"
                />
              </Grid>
              <Grid item xs={2}>
                <FormButton
                  text="submit"
                  buttonType="add"
                  loading={formik.isSubmitting}
                  disabled={!formik.values.newTag}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </FormikForm> */}
      {collectionOfTags.map((c, i) => (
        <TagCard key={i} tagGroup={c} reload={reload} />
      ))}
    </Grid>
  );
}

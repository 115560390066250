/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import nextMonday from 'date-fns/nextMonday';
import nextTuesday from 'date-fns/nextTuesday';
import nextWednesday from 'date-fns/nextWednesday';
import nextThursday from 'date-fns/nextThursday';
import nextFriday from 'date-fns/nextFriday';
import nextSaturday from 'date-fns/nextSaturday';
import nextSunday from 'date-fns/nextSunday';
import isEqual from 'date-fns/isEqual';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import addWeeks from 'date-fns/addWeeks';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import FormikForm from './FormikForm';
import FormButton from './FormButton';
import useApi from '../functions/useApi';
import FormInputCalendar from './FormInputCalendar';
import FormInputSelect from './FormInputSelect';
import { DayNames } from '../constants/Days';
import { useAlertContext } from '../context/AlertContext';
import { useAssessmentContext } from '../context/AssessmentContext';
import { useCompanyContext } from '../context/CompanyContext';

const style = makeStyles(({ palette }) => ({
  gridContainer: {
    paddingBottom: '1rem',
  },
  gridTag: {
    backgroundColor: palette.secondary.light,
    borderRadius: '0.2rem',
    marginTop: '1rem',
  },
  cardTag: {
    margin: '0.5rem',
    borderColor: palette.secondary.light,
    borderStyle: 'solid',
    '& .MuiCardHeader-root': {
      backgroundColor: palette.secondary.main,
      minHeight: '5rem',
    },
    '.MuiCardContent-root': {
      padding: '16px',
    },
    '& .MuiCard-root': {
      minHeight: '100rem',
    },
    '& .MuiCardHeader-title': {
      color: palette.secondary.contrastText,
    },
  },
  dialogTitle: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
  },
  tagChip: {
    marginLeft: '.2rem',
  },
}));

export default function Scheduler({ handleClose, open }) {
  const classes = style();
  const { put, post } = useApi();
  const [week, setWeek] = useState({ label: null, id: null });
  const [live, setLive] = useState(false);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const alertContext = useAlertContext();
  const assessmentContext = useAssessmentContext();
  const { details } = useCompanyContext();
  const [value, setValue] = React.useState(null);

  const staticInit = [
    {
      name: 'nextAssessmentDate',
      type: 'text',
      validator: Yup.string().required('date required'),
      defaultValue: today,
    },
    {
      name: 'repeat',
      type: 'number',
      validator: Yup.string().required('repeat amount required'),
      defaultValue: '1',
    },
    {
      name: 'repeatType',
      type: 'text',
      validator: Yup.string().required('Repeat type required'),
      defaultValue: 'Months',
    },
    {
      name: 'preferredDay',
      type: 'text',
      validator: Yup.string().required('Day required'),
      defaultValue: 'daySelect',
    },
    {
      name: 'dayOfTheWeek',
      type: 'text',
      validator: Yup.number(),
      defaultValue: 1,
    },
  ];

  // return each field object with id and validation
  function ValidationSchema() {
    const fieldValidation = staticInit
      .map((field) => ({
        [field.name]: field.validator,
      }))
      // combine array of objects
      .reduce((res, cur) => Object.assign(res, cur));

    return Yup.object().shape({ ...fieldValidation });
  }

  // set initial values for the form
  function setInitialValues() {
    return {
      ...staticInit
        .map((i) => ({
          [i.name]: i.defaultValue,
        }))
        .reduce((res, cur) => Object.assign(res, cur)),
    };
  }

  const formik = useFormik({
    initialValues: setInitialValues(),
    validationSchema: ValidationSchema(),

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const preferredDaySelect = () => {
        if (values.preferredDay === 'dateSelect') {
          return values.nextAssessmentDate.getDate();
        }
        return values.nextAssessmentDate.getDay();
      };

      const weekNumber = () => {
        if (values.preferredDay === 'daySelect') {
          return week.id;
        }
        return null;
      };

      const repeatType = () => {
        if (values.repeatType === 'Weeks') {
          return 'dayOfWeek';
        }
        if (weekNumber()) {
          return 'dayOfMonth';
        }
        return 'dateOfMonth';
      };
      // Expects:  {
      //   nextAssessmentDate: new Date(),
      //   repeat: int,
      //   repeatType: 'Weeks/Months'
      //   preferredDay: 0-7, Sunday-Saturday
      //   weekNumber: 1-5,  5 trigger last day of month
      // };
      const body = JSON.stringify({
        ...values,
        ...{ preferredDay: preferredDaySelect() },
        ...{ repeatType: repeatType() },
        ...{
          nextAssessmentDate: new Date(
            values.nextAssessmentDate.getTime() -
              values.nextAssessmentDate.getTimezoneOffset() * 60000
          ).toJSON(),
        },
        weekNumber: weekNumber(),
      });

      try {
        const res = await put({
          url: 'schedule',
          body: body,
        });
        console.log(res);
        alertContext.success('Schedule has been set');
        assessmentContext.reload();
      } catch (error) {
        console.error(error);
        alertContext.error('An error occurred failed to set schedule');
      }

      // setSubmitting(false);
      // resetForm();
      // reload();

      handleClose();
    },
  });

  const currentSetMonth = getMonth(formik.values.nextAssessmentDate);

  const formDay = {
    label: format(formik.values.nextAssessmentDate, 'EEEE'),
    dayNumber: format(formik.values.nextAssessmentDate, 'i'),
  };

  const formDateNumber = format(formik.values.nextAssessmentDate, 'd');
  const firstDayMonth = new Date(
    formik.values.nextAssessmentDate.getFullYear(),
    formik.values.nextAssessmentDate.getMonth(),
    1
  );

  const daysInMonth = () => {
    let getDay = (i) => nextWednesday(i);

    const dayFunctions = {
      Monday: nextMonday,
      Tuesday: nextTuesday,
      Wednesday: nextWednesday,
      Thursday: nextThursday,
      Friday: nextFriday,
      Saturday: nextSaturday,
      Sunday: nextSunday,
    };

    getDay = (i) => dayFunctions[formDay.label](i);

    const days = [];

    if (format(firstDayMonth, 'EEEE') === formDay.label) {
      days.push(firstDayMonth);
    }

    for (let i = 0; i < 6; i++) {
      if (getMonth(getDay(addWeeks(firstDayMonth, i))) === currentSetMonth) {
        days.push(getDay(addWeeks(firstDayMonth, i)));
      }
    }
    return days;
  };

  useEffect(() => {
    const nextAssessmentDate = formik.values.nextAssessmentDate;
    if (nextAssessmentDate) {
      const a = daysInMonth();

      a.forEach((day, index) => {
        if (isEqual(day, nextAssessmentDate)) {
          const timing = [
            { label: 'First', id: '1' },
            { label: 'Second', id: '2' },
            { label: 'Third', id: '3' },
            { label: 'Fourth', id: '4' },
            { label: 'Last', id: '5' },
          ];

          setWeek(timing[index]);
        }
      });
    }
  }, [formik.values.nextAssessmentDate]);

  // Whenever repeat type is changed or new date is chosen
  useEffect(() => {
    if (formik.values.repeatType === 'Weeks') {
      formik.values.dayOfTheWeek = formik.values.nextAssessmentDate.getDay();
    }
  }, [formik.values.repeatType, formik.values.nextAssessmentDate]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.dialogTitle}>Scheduler</DialogTitle>
      <DialogContent>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Basic example"
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider> */}

        <FormikForm formik={formik}>
          <Grid className={classes.gridContainer} container spacing={1}>
            <FormInputCalendar
              key="nextAssessmentDate"
              id="nextAssessmentDate"
              name="nextAssessmentDate"
              label="Start Date"
            />

            <FormInputSelect
              id="repeat"
              name="repeat"
              label="Repeat every"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
              xs={6}
            />
            <FormInputSelect
              id="repeatType"
              name="repeatType"
              label="Month/Week"
              options={['Months', 'Weeks']}
              xs={6}
            />
            {formik.values.repeatType === 'Months' ? (
              <FormInputSelect
                id="preferredDay"
                name="preferredDay"
                label="On"
                options={[
                  {
                    label: `${week.label} ${formDay.label} every ${
                      formik.values.repeat <= 1
                        ? 'month'
                        : `${formik.values.repeat} months`
                    } `,
                    value: 'daySelect',
                  },
                  {
                    label: `Day ${formDateNumber} of the month`,
                    value: 'dateSelect',
                  },
                ]}
                xs={12}
              />
            ) : (
              <FormInputSelect
                id="dayOfTheWeek"
                name="dayOfTheWeek"
                label="On"
                disabled
                defaultValue={formDateNumber}
                options={DayNames}
                xs={12}
              />
            )}
            <FormButton
              customStyle={{
                marginTop: '1rem',
                marginLeft: '1.5rem',
                textTransform: 'none',
              }}
              text="Set Schedule"
              xs={6}
              loading={formik.isSubmitting}
            />
            {details?.live === false && !live && (
              <Grid item xs={6}>
                <Button
                  sx={{
                    marginTop: '1rem',
                    marginLeft: '1rem',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await post({
                      url: 'users/invite',
                    });
                    setLive(true);
                  }}
                >
                  Send Invites Now
                </Button>
              </Grid>
            )}
          </Grid>
        </FormikForm>
      </DialogContent>
    </Dialog>
  );
}

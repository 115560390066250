export const NotificationsWithoutDayEdit = [
  'assessmentInvite',
  'welcomeEmployee',
];

export const SmsNotifications = ['intervalReminderTwo'];

export const BeforeAssessmentNotifications = ['preAssessment'];

export const NoneEditNotifications = ['intervalAdmin', 'intervalAdminFollowUp'];

export const OutOfScheduleNotifications = ['assessmentReminderTrigger'];

export const triggerAnytimeNotifications = ['intervalReminderOne'];

export const notificationsOrder = [
  'welcomeEmployee',
  'assessmentInvite',
  'intervalReminderOne',
  'intervalReminderTwo',
  'preAssessment',
  'intervalAdmin',
  'intervalAdminFollowUp',
];

export default NotificationsWithoutDayEdit;

const actions = {
  LOAD: 'LOAD',
};

// eslint-disable-next-line import/prefer-default-export
const TagsReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.tags;
    default:
      return state;
  }
};

export default TagsReducer;

export const firstNameColumn = {
  mainName: 'firstName',
  styledName: 'First Name',
  otherNames: ['firstname'],
};
export const lastNameColumn = {
  mainName: 'lastName',
  styledName: 'Last Name',
  otherNames: ['lastname', 'surname'],
};
export const startDateColumn = {
  mainName: 'Start Date',
  styledName: 'Start Date',
  otherNames: ['startdate', 'originalstartdate', 'hiredate'],
};
export const phoneNumberColumn = {
  mainName: 'Phone Number',
  otherNames: ['phonenumber', 'mobile', 'phone'],
};
export const emailColumn = {
  mainName: 'Email',
  otherNames: ['email', 'mail', 'emailaddress'],
};

export const nameColumn = {
  mainName: 'Name',
  otherNames: ['name', 'names', 'employeename'],
};

import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import { Editor } from '@tinymce/tinymce-react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import useTheme from '@mui/styles/useTheme';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Select from '@mui/material/Select';
import SvgIcon from '@mui/material/SvgIcon';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import Checkbox from '@mui/material/Checkbox';
import Clear from '@mui/icons-material/Clear';
import ArrowForward from '@mui/icons-material/ArrowForward';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Block from '@mui/icons-material/Block';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import Stop from '@mui/icons-material/Stop';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Input from '@mui/material/Input';
import SendIcon from '@mui/icons-material/Send';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import useApi from '../functions/useApi';
import { useCompanyContext } from '../context/CompanyContext';
import { useNotificationsContext } from '../context/NotificationsContext';
import {
  NotificationsWithoutDayEdit,
  BeforeAssessmentNotifications,
  NoneEditNotifications,
} from '../constants/NotificationTypes';
import { reduceArrayOfObjects, range } from '../functions/helpers';
import { useAssessmentContext } from '../context/AssessmentContext';

const style = makeStyles(({ palette }) => ({
  select: {},
  button: {
    float: 'right',
    marginRight: 5,
    marginBottom: 16,
    height: '2rem',
  },
  title: { float: 'left', paddingTop: '2rem' },
  days: { float: 'right' },
  checkBox: { float: 'left' },
  timelinePaper: { width: '14rem', height: '5.2rem', textAlign: 'left' },
  timelineConnector: { height: '5rem' },
  timelinePointTitle: { paddingRight: '1rem' },
  timeline: {
    '& .MuiTimelineContent-root': { flex: '0' },
  },
  accordionTitle: {
    borderTopWidth: '7px',
    borderTopStyle: 'solid',
    borderRadius: '0.2rem',
  },
  subjectInput: {
    width: '60%',
  },
  dialogTitle: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    marginBottom: '1rem',
  },
  spacer: {
    marginTop: '1rem',
  },
  timelineDotIcon: { height: '1rem', width: 'auto' },
}));
export default function NotificationEditor({
  notification,
  iterator,
  selected,
  setSelected,
  numberOfNotifications,
  singleNotifications = false,
  removeEditor = false,
  belowHidden = false,
  extraSummary = false,
  extraLeft = false,
  disableButtons = false,
}) {
  const classes = style();
  const editorRef = useRef(null);
  const theme = useTheme();

  const [innerLoading, setInnerLoading] = useState(false);
  const [emailEditedTemplate, setEmailEditedTemplate] = useState('');
  const [smsEditedTemplate, setSmsEditedTemplate] = useState('');
  const [smsEditedTemplatePlainText, setSmsEditedTemplatePlainText] =
    useState('');
  const [editedSubject, setEditedSubject] = useState('');
  const { timeZone } = useCompanyContext();
  const { nextAssessmentDate } = useAssessmentContext();
  const { dispatchNotifications } = useNotificationsContext();
  const [expanded, setExpanded] = React.useState(false);
  const [submitSubject, setSubmitSubject] = React.useState(false);
  const [openDialog, setDialogOpen] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    if (!NoneEditNotifications.includes(notification.type)) {
      setExpanded(!expanded);
    }
    setSelected(panel);
  };

  const { put, post } = useApi();

  useEffect(async () => {
    if (
      selected === iterator &&
      !NoneEditNotifications.includes(notification.type)
    )
      setExpanded(true);
  }, [selected]);

  useEffect(async () => {
    setSmsEditedTemplate(notification.smsTemplate);
    setEmailEditedTemplate(notification.emailTemplate);
    setEditedSubject(notification.subject);
  }, []);

  async function handleDialogClose(client) {
    setDialogOpen(!openDialog);
  }

  const sendNotification = async () => {
    try {
      const res = await post({
        url: 'company/notification/send',
        body: { type: notification.type, id: notification.id },
      });
    } catch (error) {
      console.error(error);
    }
    handleDialogClose();
  };

  const notificationArray = [
    {
      type: 'email',
      value: emailEditedTemplate,
      set: setEmailEditedTemplate,
      label: 'Email',
      enabled: notification.emailEnabled,
    },
    {
      type: 'sms',
      value: smsEditedTemplate,
      plainValue: smsEditedTemplatePlainText,
      setPlainValue: setSmsEditedTemplatePlainText,
      set: setSmsEditedTemplate,
      label: 'Sms',
      enabled: notification.smsEnabled,
    },
  ];

  // pass reset to true if you want to reset the template
  const submit = async (templateObject, i, reset) => {
    setInnerLoading(true);

    setSubmitSubject(!submitSubject);

    try {
      const result = await put({
        url: `company/notification/templates`,
        body: JSON.stringify({
          templateObjects: [templateObject],
          ...(reset && { reset: true }),
        }),
      });
      if (!reset) {
        dispatchNotifications({
          type: 'EDIT_NOTIFICATION',
          notification: { ...templateObject },
        });
      } else {
        setEmailEditedTemplate(result.message[0].emailTemplate);
        setSmsEditedTemplate(result.message[0].smsTemplate);
        dispatchNotifications({
          type: 'EDIT_NOTIFICATION',
          notification: { ...result.message[0] },
        });
      }
    } catch (err) {
      console.log(err);
    }
    setInnerLoading(false);
  };

  const isSelected = iterator === selected;

  const colorSwap = { color: isSelected ? 'secondary' : 'primary' };
  const colorSwapContrast = {
    style: {
      color: isSelected
        ? theme.palette.secondary.contrastText
        : theme.palette.primary.contrastText,
    },
  };

  const DisabledCheck = () => (
    <SvgIcon>
      <IndeterminateCheckBox {...colorSwap} />
      <Stop {...colorSwap} />
      <Clear {...colorSwapContrast} />
    </SvgIcon>
  );

  return (
    <>
      <TimelineItem>
        <TimelineOppositeContent>
          <>
            <Paper className={classes.timelinePaper}>
              <Grid container>
                <Dialog
                  open={openDialog}
                  onClose={handleDialogClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    className={classes.dialogTitle}
                    id="alert-dialog-title"
                  >
                    Are you sure you wish to send this notification
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      This will trigger the assessment to all who haven’t
                      complete in this assessment cycle, use sparingly
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={() => handleDialogClose()} color="primary">
                      Disagree
                    </Button>
                    <Button
                      onClick={() => sendNotification()}
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>

                {!removeEditor &&
                  notificationArray.map((n) => (
                    <Grid key={n.id} item xs={6}>
                      <Tooltip
                        placement="bottom-end"
                        title={`${
                          n.enabled ? 'Disable' : 'Enable'
                        } Notification`}
                      >
                        <FormControl className={classes.button}>
                          <Button
                            style={{ textTransform: 'none' }}
                            onClick={() =>
                              submit(
                                {
                                  ...notification,
                                  ...{
                                    ...reduceArrayOfObjects(
                                      notificationArray.map((a) => ({
                                        [`${a.type}Enabled`]:
                                          a === n ? !a.enabled : a.enabled,
                                      }))
                                    ),
                                  },
                                },
                                iterator
                              )
                            }
                            endIcon={
                              <Checkbox
                                className={classes.checkBox}
                                checked={n.enabled}
                                icon={<DisabledCheck />}
                                name="checkedB"
                                {...colorSwap}
                              />
                            }
                          >
                            {n.label}
                          </Button>
                        </FormControl>
                      </Tooltip>
                    </Grid>
                  ))}
                {extraLeft && extraLeft}

                {singleNotifications ? (
                  <Grid item xs={12}>
                    <Tooltip
                      placement="bottom-end"
                      title="This will trigger the assessment to all who haven’t complete in this assessment cycle, use sparingly"
                    >
                      <Button
                        fullWidth
                        style={{ textTransform: 'none' }}
                        onClick={() => setDialogOpen(true)}
                        color="primary"
                        variant="outlined"
                        endIcon={<SendIcon color="primary" />}
                      >
                        Send
                      </Button>
                    </Tooltip>
                  </Grid>
                ) : (
                  !removeEditor && (
                    <>
                      <Grid item xs={6} style={{}}>
                        <Tooltip
                          title={
                            NotificationsWithoutDayEdit.includes(
                              notification.type
                            )
                              ? 'Day of assessment'
                              : 'Days until event'
                          }
                          placement="top"
                        >
                          <FormControl>
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Select
                                  className={classes.select}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  variant="standard"
                                  disableUnderline
                                  disabled={NotificationsWithoutDayEdit.includes(
                                    notification.type
                                  )}
                                  // Remove arrow when disabled
                                  {...(NotificationsWithoutDayEdit.includes(
                                    notification.type
                                  ) && { IconComponent: () => <div /> })}
                                  value={notification.dayInterval}
                                  onChange={(e) => {
                                    submit(
                                      {
                                        ...notification,
                                        ...{ dayInterval: e.target.value },
                                      },
                                      iterator
                                    );
                                  }}
                                >
                                  {[...Array(16)].map((n, i) => (
                                    <MenuItem
                                      value={`0 years 0 mons ${
                                        notification.type === 'preAssessment'
                                          ? '-'
                                          : ''
                                      }${i + 1} days 0 hours 0 mins 0.0 secs`}
                                      key={`${n}-time`}
                                    >
                                      {!BeforeAssessmentNotifications.includes(
                                        notification.type
                                      ) && 'In '}
                                      {i + 1} day{i + 1 <= 1 ? '' : 's'}{' '}
                                      {notification.type === 'preAssessment'
                                        ? 'before'
                                        : ''}
                                    </MenuItem>
                                  ))}
                                  {NotificationsWithoutDayEdit.includes(
                                    notification.type
                                  ) && (
                                    <MenuItem
                                      value="0 years 0 mons 0 days 0 hours 0 mins 0.0 secs"
                                      key="assessment-day"
                                    >
                                      {nextAssessmentDate
                                        ? format(
                                            utcToZonedTime(
                                              nextAssessmentDate,
                                              timeZone
                                            ),
                                            'dd/MM/yyyy'
                                          )
                                        : ''}
                                    </MenuItem>
                                  )}
                                </Select>
                              }
                            />
                          </FormControl>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6}>
                        <Tooltip title="Time of event" placement="top">
                          <FormControl style={{ paddingRight: '0.2rem' }}>
                            <FormControlLabel
                              labelPlacement="start"
                              control={
                                <Select
                                  style={{ marginLeft: '0.8rem' }}
                                  className={classes.select}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  variant="standard"
                                  disableUnderline
                                  value={notification.timeOfDayInterval}
                                  onChange={(e) => {
                                    submit(
                                      {
                                        ...notification,
                                        ...{
                                          timeOfDayInterval: e.target.value,
                                        },
                                      },
                                      iterator
                                    );
                                  }}
                                >
                                  {range(7, 17).map((n, i) => (
                                    <MenuItem
                                      key={`${n}-time`}
                                      value={`0 years 0 mons 0 days ${n} hours 0 mins 0.0 secs`}
                                    >
                                      {n <= 11
                                        ? `${n} am`
                                        : `${n === 12 ? n : n - 12}pm`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              }
                            />
                          </FormControl>
                        </Tooltip>
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </Paper>
          </>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            variant="default"
            {...colorSwap}
            onClick={handleChange(iterator)}
          >
            {!isSelected && !singleNotifications ? (
              <ArrowDownward className={classes.timelineDotIcon} />
            ) : (
              <ArrowForward className={classes.timelineDotIcon} />
            )}
          </TimelineDot>
          {iterator < numberOfNotifications - 1 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Accordion
            expanded={expanded && isSelected}
            style={{ minHeight: '5.3rem', width: '54rem', marginRight: '5rem' }}
          >
            <Tooltip
              title={
                NoneEditNotifications.includes(notification.type)
                  ? 'Template can not be edited'
                  : ''
              }
            >
              <AccordionSummary
                className={classes.accordionTitle}
                style={{
                  borderTopColor:
                    theme.palette[`${isSelected ? 'secondary' : 'primary'}`]
                      .main,
                }}
                expandIcon={
                  <>
                    {!NoneEditNotifications.includes(notification.type) ? (
                      <Tooltip
                        placement="left"
                        title={expanded ? 'Collapse' : 'Expand'}
                      >
                        <IconButton onClick={handleChange(iterator)}>
                          <ExpandMoreIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="left"
                        title="Template can not be edited"
                      >
                        <IconButton>
                          <Block />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                }
                id={`panel-${iterator}`}
              >
                {!removeEditor && (
                  <Tooltip
                    title={<h2>{notification.label}</h2>}
                    placement="right"
                  >
                    <Input
                      key={`${iterator}-subject-input`}
                      className={classes.subjectInput}
                      disabled={NoneEditNotifications.includes(
                        notification.type
                      )}
                      value={editedSubject}
                      onInput={(e) => setEditedSubject(e.target.value)}
                      onClick={handleChange(iterator)}
                    />
                  </Tooltip>
                )}
                {extraSummary && extraSummary}
              </AccordionSummary>
            </Tooltip>
            {!removeEditor &&
              notificationArray.map((t, i) => (
                <div className={classes.spacer}>
                  <Editor
                    apiKey="54ifpmewsvrzetocn2vfrh8hkc2oxft51zn6fb5sdwviiv81"
                    // eslint-disable-next-line no-return-assign
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={(e, editor) => {
                      t.set(editor.getContent());
                      if (t.type === 'sms') {
                        // formats to plain text removing html tags
                        // for sms message
                        t.setPlainValue(editor.getContent({ format: 'text' }));
                      }
                    }}
                    value={t.value}
                    readOnly
                    init={{
                      selector: 'textarea',
                      inline_boundaries: false,
                      autoresize_bottom_margin: 50,
                      menubar: false,
                      branding: false,
                      plugins: ['link', 'autoresize'],
                      skin: 'borderless',
                      setup: function (editor, ed) {
                        editor.ui.registry.addButton('resetButton', {
                          text: 'Reset to default',
                          tooltip: 'Reset text to default',
                          onAction: () =>
                            submit(
                              {
                                ...notification,
                                ...{ emailTemplate: emailEditedTemplate },
                                ...{ smsTemplate: smsEditedTemplate },
                              },
                              iterator,
                              true
                            ),
                        });

                        editor.ui.registry.addIcon('donut', `<EmailIcon />`);
                        editor.ui.registry.addButton('labelButton', {
                          text: t.label,
                          disabled: true,
                          icon: 'donut',
                          style: {
                            color: 'red',
                            textColor: 'red',
                            'text-color': 'red',
                          },
                          tooltip: 'Reset text to default',
                          onAction: () =>
                            console.info(`This the the ${t.label} editor`),
                        });
                      },

                      toolbar:
                        t.type === 'sms'
                          ? 'labelButton undo redo | resetButton'
                          : 'labelButton undo redo | formatselect | ' +
                            'bold italic backcolor alignleft aligncenter' +
                            'alignright alignjustify bullist numlist outdent indent ' +
                            'removeformat' +
                            'forecolor backcolor link resetButton',
                      content_style:
                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; min-height: 225px;}',
                    }}
                  />
                </div>
              ))}
            {belowHidden && belowHidden}

            {[
              {
                disabled: selected > numberOfNotifications - 2 || innerLoading,
                onClick: () => {
                  setSelected(selected + 1);
                },
                label: 'Next',
              },
              {
                disabled: !(selected > 0) || innerLoading,
                onClick: () => {
                  setSelected(selected - 1);
                },
                label: 'Back',
              },

              {
                disabled: innerLoading,
                onClick: () => {
                  submit(
                    {
                      ...notification,
                      ...{
                        emailTemplate: emailEditedTemplate,
                        smsTemplate: smsEditedTemplatePlainText,
                        subject: editedSubject,
                      },
                    },
                    iterator
                  );
                },
                label: 'Save',
              },
            ].map(
              (item) =>
                !disableButtons && (
                  <Button
                    {...item}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                  >
                    {item.label}
                  </Button>
                )
            )}
          </Accordion>
        </TimelineContent>
      </TimelineItem>
    </>
  );
}

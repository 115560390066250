import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 0,
    color: '#fffffF',
  },
}));

function LoadingBackground(props) {
  const classes = useStyles();
  const { visible } = props;

  return (
    <Backdrop className={classes.backdrop} open={visible || true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingBackground;

import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const style = makeStyles(() => ({
  actionIcon: {
    marginLeft: 25,
  },
  root: {
    height: '100%',
    marginTop: 15,
  },
  linkButton: { float: 'right', marginRight: 5, marginLeft: 15, marginTop: 10 },
  button: { float: 'right', marginRight: 5, marginLeft: 15 },
  select: { float: 'right', minWidth: 150 },
  formControl: { float: 'right', marginLeft: 15 },
}));

export default function QuestionBuilderList(props) {
  const classes = style();
  const { questions, handleSubmit } = props;
  const [showEnabled, setShowEnabled] = useState(true);
  const categories = [];

  questions.forEach((element) => {
    if (!categories.includes(element.name)) {
      categories.push(element.name);
    }
  });

  const [category, setCategory] = useState(categories[0]);
  const displayQuestions = questions.filter(
    (q) => q.name === category && q.enabled === showEnabled
  );

  const handleChangeEnabled = (event) => {
    setShowEnabled(event.target.value === 'enabled');
  };

  const submitChangeEnabled = (event) => {
    const updateQuestion = event;
    updateQuestion.enabled = !event.enabled;
    if (updateQuestion.enabled === false) {
      updateQuestion.default_question = false;
    }
    handleSubmit(updateQuestion);
  };

  const defaultCount = displayQuestions.filter(
    (q) => q.default_question === true
  ).length;

  const submitChangeDefault = (event) => {
    const updateQuestion = event;
    updateQuestion.default_question = !event.default_question;
    if (defaultCount < 5 || updateQuestion.default_question === false) {
      handleSubmit(updateQuestion);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className={classes.linkButton}
        component={Link}
        to="/question"
      >
        Add Question
      </Button>
      <FormControl className={classes.formControl}>
        <InputLabel key="questions-toggle">Questions Enabled:</InputLabel>
        <NativeSelect
          value={showEnabled ? 'enabled' : 'disabled'}
          onChange={handleChangeEnabled}
          className={classes.select}
        >
          <option value="enabled">Enabled</option>
          <option value="disabled">Disabled</option>
        </NativeSelect>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel key="question-categories">Question Category:</InputLabel>
        <NativeSelect
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          value={category}
          className={classes.select}
        >
          {categories.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      <Typography variant="h5" style={{ marginLeft: 15 }}>
        {category}
      </Typography>
      <List className={classes.root}>
        {displayQuestions.map((question, index) => (
          <React.Fragment key={question.id}>
            <ListItem
              alignItems="flex-start"
              // eslint-disable-next-line react/no-array-index-key
              key={index + 200}
            >
              <ListItemText primary={`${question.question}`} />
              <ListItemSecondaryAction>
                <Button
                  onClick={() => submitChangeEnabled(question)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {question.enabled ? 'Disable' : 'Enable'}
                </Button>
                {question.enabled ? (
                  <Button
                    onClick={() => submitChangeDefault(question)}
                    variant="contained"
                    color={
                      // eslint-disable-next-line no-nested-ternary
                      question.default_question
                        ? 'primary'
                        : defaultCount < 5
                        ? 'secondary'
                        : 'inherit'
                    }
                    className={classes.button}
                  >
                    Default
                  </Button>
                ) : null}
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

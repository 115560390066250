/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';

export default function FormButton(props) {
  const {
    callback,
    loading,
    text,
    dialog,
    width,
    buttonType,
    disabled,
    noScroll,
    fullWidth,
    customStyle,
    xs,
  } = props;
  const formik = useFormikContext();
  const buttonProps = callback
    ? { onClick: callback }
    : {
        type: 'submit',
      };
  const buttonUIProps =
    dialog === true
      ? {
          color: 'primary',
        }
      : {
          variant: 'contained',
          color: 'primary',
          size: 'medium',
        };

  const submitCountRef = useRef();
  useEffect(() => {
    if (submitCountRef.current !== formik.submitCount && !formik.isSubmitting) {
      submitCountRef.current = formik.submitCount;
      if (formik.errors && !buttonType && !noScroll) {
        window.scrollTo(0, 0);
      }
    }
  }, [formik.submitCount, formik.isSubmitting, formik.errors]);
  if (!buttonType) {
    return (
      <Grid item xs={xs || 12} sm={width}>
        <Button
          fullWidth={fullWidth}
          {...buttonProps}
          {...buttonUIProps}
          disabled={formik.isSubmitting || loading || disabled}
          style={customStyle}
        >
          {formik.isSubmitting || loading ? (
            <CircularProgress size={24} />
          ) : (
            text
          )}
        </Button>
      </Grid>
    );
  }
  switch (buttonType) {
    case 'add':
      return (
        <IconButton
          {...buttonProps}
          {...buttonUIProps}
          style={customStyle}
          disabled={formik.isSubmitting || loading || disabled}
          size="large"
        >
          {formik.isSubmitting || loading ? (
            <CircularProgress size={24} />
          ) : (
            <AddCircleIcon />
          )}
        </IconButton>
      );
    default:
  }
}

import React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Delete from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import GroupsIcon from '@mui/icons-material/Groups';
import AssignmentIcon from '@mui/icons-material/Assignment';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import { useFormik } from 'formik';
import useApi from '../functions/useApi';
import { useAuthContext } from '../context/AuthContext';
import FormikForm from './FormikForm';
import TextInput from './FormInputText';

const style = makeStyles((theme) => ({
  actionIcon: {
    marginRight: 25,
  },
  root: {
    height: '100%',
  },
  cellError: {
    backgroundColor: theme.palette.error.main,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  DialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: '1rem',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ClientTable(props) {
  const theme = useTheme();
  const classes = style();
  const authContext = useAuthContext();
  const { clients, handleClientFlagChange, reload } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('4');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const [open, setOpen] = React.useState(false);
  const [clientForDeletion, setClientForDeletion] = React.useState([]);
  const { deleteAPI, put } = useApi();

  const formik = useFormik({
    initialValues: { companyName: '' },
    validationSchema: Yup.object().shape({
      companyName: Yup.string()
        // has to match string of company exactly
        .matches(clientForDeletion.companyname)
        .required(),
    }),

    onSubmit: async (values, { resetForm }) => {
      try {
        const res = await deleteAPI({
          url: 'company',
          id: clientForDeletion.id,
        });
        console.log(res);
        resetForm();
        reload();
        setOpen(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const columns = [
    { id: 'companyname', label: 'Client', minWidth: 200 },
    { id: 'employees', label: <GroupsIcon />, minWidth: 50, align: 'center' },
    {
      id: 'testscompleted',
      label: <AssignmentIcon />,
      minWidth: 50,
      align: 'center',
    },
    {
      id: 'next_assessment_date',
      label: 'Next Assessment',
      minWidth: 50,
      align: 'center',
    },
    { id: 'resilience', label: 'Resilience', minWidth: 40, align: 'center' },
    { id: 'safety', label: 'Safety', minWidth: 40, align: 'center' },
    { id: 'tip_sheet', label: 'Tip sheet', minWidth: 40, align: 'center' },
    {
      id: 'identified_data:',
      label: 'Identified data',
      minWidth: 40,
      align: 'center',
    },
    { id: 'delete', label: '', minWidth: 40, align: 'center' },
    { id: 'supervise', label: '', minWidth: 40, align: 'center' },
  ];

  const handleClickOpen = (user) => {
    setOpen(true);
    setClientForDeletion(user);
  };

  async function handleClose(client) {
    setClientForDeletion([]);
    formik.resetForm();

    if (client) {
      console.log('DELETE', client);
      setOpen(false);
    } else {
      setOpen(false);
    }
  }

  const handleSuperviseClick = async (client) => {
    console.log(client.id);
    try {
      const result = await put({
        url: `imitatecompany`,
        body: JSON.stringify({ id: client.id }),
      });
      console.log(result);
      window.location.reload();
      // setRefresh((r) => r + 1);
    } catch (err) {
      console.log(err);
    }
  };

  const CustomCell = ({ value, companyName, align }) => (
    <TableCell
      align={align}
      style={{
        ...(authContext.companyName === companyName && {
          color: theme.palette.primary.contrastText,
        }),
      }}
      component="th"
      scope="row"
    >
      {value}
    </TableCell>
  );

  console.log(clients);

  return (
    <div>
      {clients && clients.length > 0 ? (
        <TableContainer className={classes.root}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(clients, getComparator(order, orderBy)).map(
                (client, index) => (
                  <TableRow
                    key={client.id + 1000}
                    style={{
                      ...(authContext.companyName === client.companyname && {
                        backgroundColor: theme.palette.secondary.main,
                      }),
                    }}
                  >
                    <CustomCell
                      companyName={client.companyname}
                      value={client.companyname}
                    />
                    <CustomCell
                      align="center"
                      companyName={client.companyname}
                      value={client.employees}
                    />
                    <CustomCell
                      align="center"
                      companyName={client.companyname}
                      value={client.testscompleted}
                    />
                    <CustomCell
                      companyName={client.companyname}
                      value={
                        client.next_assessment_date
                          ? format(
                              utcToZonedTime(
                                client.next_assessment_date,
                                'Australia/Brisbane'
                              ),
                              'dd/MM/yyyy'
                            )
                          : ''
                      }
                    />
                    <TableCell component="th" scope="row" align="center">
                      <Checkbox
                        edge="start"
                        checked={client.resilience}
                        tabIndex={-1}
                        disableRipple
                        onChange={(e) =>
                          handleClientFlagChange(e, client.id, 'resilience')
                        }
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#1c4a98',
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Checkbox
                        edge="start"
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#ff8b00',
                          },
                        }}
                        checked={client.safety}
                        tabIndex={-1}
                        disableRipple
                        onChange={(e) =>
                          handleClientFlagChange(e, client.id, 'safety')
                        }
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Checkbox
                        edge="start"
                        checked={client.tip_sheet}
                        tabIndex={-1}
                        disableRipple
                        onChange={(e) =>
                          handleClientFlagChange(e, client.id, 'tip_sheet')
                        }
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#B22222',
                          },
                        }}
                      />
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      <Checkbox
                        edge="start"
                        checked={client.identified_data}
                        tabIndex={-1}
                        disableRipple
                        onChange={(e) =>
                          handleClientFlagChange(
                            e,
                            client.id,
                            'identified_data'
                          )
                        }
                        sx={{
                          [`&, &.${checkboxClasses.checked}`]: {
                            color: '#6b3b9f',
                          },
                        }}
                      />
                    </TableCell>

                    <TableCell component="th" scope="row" align="left">
                      {authContext.companyName !== client.companyname && (
                        <Tooltip title="Delete Company">
                          <IconButton
                            aria-label="View"
                            color="primary"
                            onClick={() => handleClickOpen(client)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell component="th" scope="row" align="center">
                      {client.allow_cernova ? (
                        <Tooltip title="Supervise company">
                          <IconButton
                            edge="start"
                            aria-label="View"
                            color="primary"
                            onClick={() => handleSuperviseClick(client)}
                            size="large"
                          >
                            <SupervisorAccount />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No Available Records</Typography>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FormikForm formik={formik}>
          <DialogTitle className={classes.DialogTitle} id="alert-dialog-title">
            Are you sure you wish to delete {clientForDeletion.companyname}?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please type the name of the company to confirm
            </DialogContentText>

            <TextInput
              key="companyName"
              fullWidth
              id="companyName"
              name="companyName"
              label={clientForDeletion.companyname}
            />
          </DialogContent>

          <DialogActions>
            {/* <FormButton text="Agree" xs={6} loading={formik.isSubmitting} /> */}

            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button
              // onClick={() => handleClose(clientForDeletion)}
              color="primary"
              autoFocus
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
            >
              Agree
            </Button>
          </DialogActions>
        </FormikForm>
      </Dialog>
    </div>
  );
}

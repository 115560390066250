/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export default function FormText(props) {
  const {
    name,
    label,
    disabled,
    helpText,
    prefix,
    inputType,
    password,
    type,
    placeholder,
    width,
    includePrefix,
    fullWidth,
    basic,
    ...rest
  } = props;

  const style = makeStyles(() => ({
    formControl: {
      marginTop: 10,
      marginBottom: 10,
    },
    label: {
      textAlign: 'left',
      color: '#282828',
    },
    disabledLabel: {
      textAlign: 'left',
      color: '#828282',
    },
  }));

  const classes = style();
  const [field, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);
  const inputValue =
    includePrefix && field.value?.startsWith(prefix)
      ? field.value.substr(prefix.length)
      : field.value;

  const onChangeWithPrefix = (e) => {
    helpers.setValue(prefix + e.target.value);
  };

  if (basic) {
    return (
      <FormControl error={hasError}>
        <Input
          placeholder={placeholder}
          id={`input-${name}`}
          disabled={disabled}
          type={!type ? 'text' : type}
          {...rest}
          {...field}
          value={inputValue}
          onChange={includePrefix ? onChangeWithPrefix : field.onChange}
          error={hasError}
          fullWidth
        />
        <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
      </FormControl>
    );
  }

  return (
    <Grid key={label} item xs={12} style={{ marginTop: 10 }}>
      <Container>
        <FormControl
          fullWidth={fullWidth}
          className={classes.formControl}
          error={hasError}
        >
          <FormLabel
            className={disabled ? classes.disabledLabel : classes.label}
          >
            {label}
          </FormLabel>
          <Input
            placeholder={placeholder}
            id={`input-${name}`}
            disabled={disabled}
            type={!type ? 'text' : type}
            startAdornment={
              prefix ? (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ) : null
            }
            {...rest}
            {...field}
            // label={label}
            value={inputValue}
            onChange={includePrefix ? onChangeWithPrefix : field.onChange}
            error={hasError}
            fullWidth
          />

          <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
      </Container>
    </Grid>
  );
}

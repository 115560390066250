import React from 'react';
import { Chart, Bubble, Pie, Bar, Line } from 'react-chartjs-2';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import AllQuestionsChart from './AllQuestionsChart';

const style = makeStyles(({ spacing, palette }) => ({
  card: {
    margin: 10,
    borderRadius: spacing(0.5),
    width: '100%',
    '& .MuiCardHeader-root': {
      backgroundColor: palette.secondary.main,
      paddingTop: '0.4rem',
      paddingBottom: '0.3rem',
      color: palette.secondary.contrastText,
      textAlign: 'center',
      flexGrow: 1,
    },
  },
  CardContent: { justifyContent: 'center', display: 'flex' },

  grid: {
    display: 'flex',
  },
  questionCardHeader: {
    textAlign: 'center',
    height: '2.5rem',
  },
  cardTag: {
    minHeight: '1rem',
    textColor: palette.secondary.contrastText,

    '& .MuiButtonBase-root': {
      backgroundColor: palette.secondary.main,
      borderRadius: '0.2rem',
    },

    '& .MuiCardHeader-title': { color: palette.secondary.contrastText },
    '& .MuiIconButton-root': { color: palette.secondary.contrastText },
    '& .MuiTypography-root': { color: palette.secondary.contrastText },
  },
  AccordionSummary: {
    '& .MuiAccordionSummary-content': { flexGrow: 0 },
  },
}));

/**
 * @param {object}
 */
const QuestionCharts = ({ questions, questionData }) => {
  const classes = style();

  const barOptions = (xLabel, yLabel) => ({
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // empty function so title does not display
          title: function () {},
        },
      },
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
        },
      },
      y: {
        title: {
          display: true,
          text: yLabel || 'Number of results',
        },
      },
    },
  });

  const backgroundColorArray = [
    'rgba(255, 0, 0, 1)',
    'rgba(255, 154, 0, 1)',
    'rgba(255, 255, 0, 1)',
    'rgba(144, 255, 0, 1)',
    'rgba(0, 255, 0, 1)',
  ];

  const resBarData = (data) => ({
    labels: ['Rarely', 'Occasionally', 'Often', 'Usually', 'Most of the Time'],
    datasets: [
      {
        label: 'Results',
        data: data,
        // minBarLength only apply to values that are not null
        minBarLength: 12,
        backgroundColor: backgroundColorArray,
      },
    ],
  });

  // used to filter out questions which are already in the array
  const currentQuestionIds =
    questions.length > 0 ? questions.map((q) => q.question_id) : [];

  return (
    <Paper style={{ width: '100%', marginTop: 10 }}>
      {Object.keys(questionData).length > 0 && (
        <AllQuestionsChart companyData={questionData} disableSelector />
      )}
      <Accordion
        style={{ width: '100%' }}
        key="Question-chart"
        className={classes.cardTag}
      >
        <AccordionSummary
          className={classes.AccordionSummary}
          key="question-chart-summary"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ flexGrow: '0' }}>
            <Typography align="right" variant="h6">
              Individual Question Charts
              {!Object.keys(questionData).length > 0 && <LinearProgress />}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails key="question-chart-AccordionDetails">
          <Grid container>
            {[
              // combine current question template with questions from previous assessments
              // this is the reason more than 15 charts are displayed
              ...questions,
              Object.values(questionData).filter(
                (t) => !currentQuestionIds.includes(t.question_id)
              ),
            ].map(
              (q) =>
                questionData[q.question_id] && (
                  <Grid item xs={6} className={classes.grid}>
                    <Card className={classes.card}>
                      <CardHeader
                        title={
                          <Typography variant="subtitle2" align="center">
                            {q.question}{' '}
                          </Typography>
                        }
                        className={classes.questionCardHeader}
                      />
                      <Tooltip title="Results from assessment question">
                        <CardContent>
                          <Bar
                            data={resBarData(
                              questionData[q.question_id]
                                ? [
                                    // 1-5 is typically
                                    questionData[q.question_id][1],
                                    questionData[q.question_id][2],
                                    questionData[q.question_id][3],
                                    questionData[q.question_id][4],
                                    questionData[q.question_id][5],
                                  ]
                                : []
                            )}
                            options={barOptions()}
                          />
                        </CardContent>
                      </Tooltip>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default QuestionCharts;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import Card from '@mui/material/Card';
// eslint-disable-next-line import/no-unresolved
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import Container from '@mui/material/Container';
import FormikForm from './FormikForm';
import { validator, reformatPhoneNumber } from '../assets/validator';
import TextInput from './FormInputText';
import FormButton from './FormButton';
import useApi from '../functions/useApi';
import Select from './FormInputSelect';
import FormInputCheckbox from './FormInputCheckbox';
import { useAlertContext } from '../context/AlertContext';
import { useUsersContext } from '../context/UsersContext';
import { camelToSnakeCase } from '../functions/helpers';

const style = makeStyles(({ palette }) => ({
  gridContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    marginBottom: '2rem',
  },
  gridTag: {
    backgroundColor: palette.secondary.light,
    borderRadius: '0.2rem',
    marginTop: '1rem',
  },
  cardTag: {
    margin: '0.5rem',
    borderColor: palette.primary.light,
    borderStyle: 'solid',
    '& .MuiCardHeader-root': {
      backgroundColor: palette.secondary.main,
      minHeight: '5rem',
    },
    '.MuiCardContent-root': {
      padding: '16px',
    },
    '& .MuiCard-root': {
      minHeight: '100rem',
    },
    '& .MuiCardHeader-title': {
      color: palette.secondary.contrastText,
    },
  },
  title: {
    paddingTop: '1rem',
    paddingBottom: '0.5rem',
  },
  tagSelections: {
    paddingBottom: '3rem',
  },
  grid: {
    paddingLeft: 0,
    paddingRight: 0,
    '& MuiContainer-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

export default function UserForm({
  collectionOfTags,
  reload,
  defaultValue,
  updateUser,
  userId,
}) {
  const classes = style();
  const { post, put, get } = useApi();
  const alertContext = useAlertContext();
  const { dispatchUsers, users } = useUsersContext();

  // const defaultValue = users.filter((u) => u.user_id === userId)[0] || null;

  const staticInit = [
    {
      name: 'name',
      label: 'Name*',
      type: 'text',
      validator: Yup.string().min(1, 'Too Short!').required('Enter name'),
      defaultValue: defaultValue ? defaultValue.name || '' : '',
    },
    {
      name: 'email',
      label: 'Email*',
      type: 'text',
      validator: Yup.string()
        .email('Email must be valid')
        .required('Enter email'),
      defaultValue: defaultValue ? defaultValue.email || '' : '',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number*',
      type: 'text',
      validator: Yup.string().matches(
        validator.phone,
        'Please enter a valid Phone Number'
      ),
      prefix: '+61',
      includePrefix: true,
      format: reformatPhoneNumber,
      f: true,
      defaultValue: defaultValue ? defaultValue.phone_number || '' : '',
    },
    {
      name: 'startDate',
      label: 'Start Date',
      type: 'text',
      validator: Yup.string('Enter start date'),
      defaultValue: defaultValue ? defaultValue.start_date || '' : '',
    },
    {
      name: 'admin',
      label: 'Grant Administrator Access',
      type: 'checkbox',
      defaultValue: defaultValue ? defaultValue.is_admin || false : false,
      validator: Yup.boolean(),
    },
  ];

  // create useable object from default tags passed as prop
  const defaultTag =
    updateUser && defaultValue.tags
      ? defaultValue.tags
          // tag_group is name of tagGroup
          // id is tagId inside of group
          .map((u) => ({ [u.tag_group]: u.id }))
          // combine array of objects
          .reduce((res, cur) => Object.assign(res, cur)) || {}
      : {};

  // function to get tag ids in object and pass through desired key value
  const TagIds = (keyValue) => {
    if (collectionOfTags.length)
      return (
        collectionOfTags
          // make sure tag has id
          .filter((tagGroup) => tagGroup.tag_group_id)
          // return each tag object with id and passed value
          .map((tagGroup) => ({
            [tagGroup.question]:
              keyValue || defaultTag[tagGroup.question] || '',
          }))
          .reduce((res, cur) => Object.assign(res, cur))
      );
    return [];
  };

  function setInitialValues() {
    return {
      ...TagIds(),
      ...staticInit
        .map((i) => {
          // if no default value empty string
          if (i.defaultValue === undefined) {
            return {
              [i.name]: '',
            };
          }
          return {
            [i.name]: i.defaultValue,
          };
        })
        .reduce((res, cur) => Object.assign(res, cur)),
    };
  }

  function ValidationSchema() {
    const tagValidation = TagIds(Yup.string().min(1, 'Too Short!'));

    const fieldValidation = staticInit
      // return each field object with id and validation
      .map((field) => ({
        [field.name]: field.validator,
      }))
      // combine array of objects
      .reduce((res, cur) => Object.assign(res, cur));

    return Yup.object().shape({ ...tagValidation, ...fieldValidation });
  }

  const formik = useFormik({
    initialValues: setInitialValues(),
    validationSchema: ValidationSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (!updateUser) {
        const tg = [];

        Object.keys(values).forEach((key) => {
          if (!staticInit.map((i) => i.name).includes(key)) {
            const group = {};
            group.name = key;
            collectionOfTags.some((tag) => {
              if (group.name === tag.question) {
                tag.options.some((tagName) => {
                  if (values[key] === tagName.value) {
                    group.tag = tagName.label;
                    return true;
                  }
                  return false;
                });
                return true;
              }
              return false;
            });
            tg.push(group);
          }
        });

        try {
          const res = await post({
            url: 'users',
            body: [
              {
                ...staticInit
                  .map((field) => ({
                    [field.name]: field.format
                      ? field.format(values[field.name])
                      : values[field.name],
                  }))
                  .reduce((r, c) => Object.assign(r, c)),
                ...{ tg: tg },
              },
            ],
          });
          if (res.errors.length > 0) {
            alertContext.error(
              res,
              `${JSON.stringify(res.errors[0].email)} already exists`
            );
            alertContext.error(res, `${res.errors[0].email} already exists`);
          } else {
            alertContext.success('User has been created');
            resetForm();
          }
        } catch (error) {
          console.error(error.errors);
          alertContext.error(error, JSON.stringify(error.errors));
        }
      } else {
        const p = { ...values };

        // remove statics so only tags remain
        staticInit.forEach((r) => {
          delete p[r.name];
        });

        // filter to remove empty strings
        const newTagIds = Object.values(p)
          .filter((e) => e)
          .sort();

        // tags from user currently
        const currentTags = Object.values(defaultTag).sort();

        // compare new tags to current tags
        // if the array is > 0 some tags have been changed
        const tagsEdited =
          newTagIds.filter((t, i) => t !== currentTags[i]).length > 0;
        if (tagsEdited) {
          console.info('Updating Tags');
          try {
            const res = await put({
              url: 'user/tags',
              // body is tag ids in stringified array
              body: JSON.stringify(Object.values(p).filter((e) => e)),
              id: userId,
            });

            if (res.errors ? res.errors.length > 0 : false) {
              console.log(res.errors);
            } else if (res.newTags) {
              dispatchUsers({
                type: 'EDIT_USER',
                user: {
                  ...defaultValue,
                  tags: res.newTags,
                },
              });
            }
          } catch (error) {
            console.info('%cTags Updated', 'background: #222; color: #7CFC00');
            console.error(error.errors);
          }
        } else {
          console.info('Tags already up to date');
        }

        const userFieldsEdited =
          staticInit
            .map((n) => n.name)
            .filter(
              (v) =>
                values[v] !==
                defaultValue[v === 'admin' ? 'is_admin' : camelToSnakeCase(v)]
            ).length > 0;

        if (userFieldsEdited) {
          console.info('Updating user details');

          console.log(values);
          try {
            const res = await put({
              url: 'user',
              body: JSON.stringify({
                name: values.name,
                email: values.email,
                phoneNumber: values.phoneNumber,
                startDate: values.startDate,
                admin: values.admin,
              }),
              id: userId,
            });

            if (res.errors ? res.errors.length > 0 : false) {
              console.log(res.errors);
            } else {
              console.log(res);

              dispatchUsers({
                type: 'EDIT_USER',
                user: {
                  ...defaultValue,
                  name: values.name,
                  email: values.email,
                  is_admin: values.admin,
                  phone_number: values.phoneNumber,
                  start_date: values.startDate,
                },
              });

              console.info(
                '%cUser details updated',
                'background: #222; color: #7CFC00'
              );
            }
          } catch (error) {
            console.error(error.errors);
          }
        } else console.info('User details already up to date');
      }

      setSubmitting(false);
    },
  });

  const TagQuestions = collectionOfTags.map((data) => {
    // eslint-disable-next-line camelcase
    const { options, question, label, question_id, tag_group_id } = data;
    // eslint-disable-next-line camelcase
    const questionId = question_id;

    if (options) {
      return (
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          // eslint-disable-next-line camelcase
          key={tag_group_id}
          className={classes.grid}
        >
          <Select
            options={[...[{ value: '', label: 'Nothing' }], ...options]}
            // eslint-disable-next-line camelcase
            key={tag_group_id}
            label={question} // label
            name={question} // formik name
          />
        </Grid>
      );
    }
    return null;
  });

  const StaticQuestions = staticInit.map((data) => {
    const { label, name, type, prefix, includePrefix } = data;
    switch (type) {
      case 'text':
        return (
          <Grid item xs={12} sm={6} md={6} lg={6} key={name}>
            <TextInput
              key={name}
              fullWidth
              id={name}
              name={name}
              label={label || name}
              prefix={prefix || ''}
              includePrefix={includePrefix || false}
            />
          </Grid>
        );
      case 'checkbox':
        return (
          <Grid item xs={12} sm={12} md={12} lg={12} key={name}>
            <FormInputCheckbox
              key={name}
              fullWidth
              id={name}
              name={name}
              label={label || name}
            />
          </Grid>
        );
      default:
        return null;
    }
  });

  return (
    <FormikForm formik={formik}>
      <Card className={classes.cardTag}>
        <Grid container className={classes.gridContainer} spacing={1}>
          {StaticQuestions}
          <Grid item xs={12}>
            <Container>
              <Typography className={classes.title} variant="h6">
                Tag Selection{' '}
              </Typography>
            </Container>
          </Grid>
          {TagQuestions}
          <FormButton
            customStyle={{ marginTop: '2rem' }}
            fullWidth
            text={updateUser ? 'Update User' : 'Create User'}
            loading={formik.isSubmitting}
            noScroll
          />
        </Grid>
      </Card>
    </FormikForm>
  );
}

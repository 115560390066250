/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import Alert from '../components/Alert';

export const AlertContext = React.createContext({});

export function useAlertContext() {
  return useContext(AlertContext);
}

const AlertContextProvider = (props) => {
  const [alertValue, setAlertValue] = useState(null);

  const value = useMemo(() => {
    // eslint-disable-next-line no-shadow
    function alert(success, message) {
      setAlertValue({ success, message, open: true });
    }

    function success(message) {
      alert(true, message);
    }

    function error(e, message) {
      console.error(message, e);
      alert(false, message);
    }

    return { alert, success, error };
  }, []);

  return (
    <AlertContext.Provider value={value}>
      {props.children}
      {alertValue && (
        <Alert alert={alertValue} handleClose={() => setAlertValue(null)} />
      )}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;

const actions = {
  LOAD: 'LOAD',
};

// eslint-disable-next-line import/prefer-default-export
const AssessmentFilterReducer = (state, action) => {
  console.log(state, action);
  switch (action.type) {
    case actions.LOAD:
      return action.filter;
    default:
      return state;
  }
};

export default AssessmentFilterReducer;

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

export default function TagTable(props) {
  const classes = style();
  const { tagData } = props;

  return (
    <div>
      <Typography>The following unique tags have been found</Typography>
      <TableContainer className={classes.root}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tagData.tagGroups.map((groupName, index) => (
                <TableCell key={index + 10}>{groupName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tagData.rows.map((rowIndex) => (
              <TableRow key={rowIndex + 100}>
                {tagData.tagGroups.map((groupName, index) => (
                  <TableCell component="th" scope="row" key={index + 100}>
                    {tagData.tags[index][rowIndex]
                      ? tagData.tags[index][rowIndex]
                      : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const style = makeStyles(() => ({
  actionIcon: {
    marginRight: 25,
  },
  root: {
    height: '100%',
  },
}));

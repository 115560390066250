import React from 'react';
import { Form, FormikProvider } from 'formik';

/**
 * @param {object} formik config object
 */
const FormikForm = ({ formik, children }) => (
  <FormikProvider value={formik}>
    <Form autoComplete="off">{children}</Form>
  </FormikProvider>
);

export default FormikForm;

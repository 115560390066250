import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Bar } from 'react-chartjs-2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import format from 'date-fns/format';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useCompanyContext } from '../context/CompanyContext';
import { useUsersContext } from '../context/UsersContext';

const style = makeStyles(({ spacing, palette }) => ({
  card: {
    margin: 10,
    borderRadius: spacing(0.5),
    width: '100%',
  },
  cardC: {
    width: '100%',
  },
  CardHeader: {
    flexGrow: 1,
    backgroundColor: palette.secondary.main,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    '& .MuiCardHeader-root': {
      padding: 0,
    },
  },

  grid: {
    display: 'flex',
    paddingBottom: '3rem',
  },
  gridC: {
    display: 'flex',
  },
  select: {
    float: 'right',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    minWidth: '6rem',
    borderRadius: spacing(0.5),

    '& .MuiOutlinedInput-input': {
      paddingRight: '2rem',
      paddingTop: '1rem',
      paddingBottom: '0.3rem',
    },
  },
  formControl: {
    float: 'right',
  },
  inputLabel: {
    marginTop: '0.6rem',
    marginRight: '1rem',
  },
  cardTitle: {
    textAlign: 'center',
    '& .MuiCardHeader-title': { color: palette.secondary.contrastText },
  },
}));

const AllQuestionsChart = ({ user, disableSelector, companyData }) => {
  const classes = style();
  const theme = useTheme();
  const [selected, setSelected] = React.useState(1);
  const { details } = useCompanyContext();
  const { historicalUserData, usersHistoricalDataLoading } = useUsersContext();

  /**
   * @param {Number} i is index
   */
  const questionBarData = (i) => ({
    ...(!companyData
      ? { labels: user[i] ? user[i].results.map((q) => q.question) : [] }
      : {
          labels: Object.keys(companyData).map((q) => companyData[q].question),
        }),

    datasets: [
      ...(!companyData
        ? [
            {
              label: user[i] ? user[i].name : 'user',
              data: user[i] ? user[i].results.map((q) => q.answer) : [],
              // minBarLength only apply to values that are not null

              backgroundColor: [theme.palette.primary.dark],
            },
            {
              label: `${details.name || 'Company'}`,
              data: user[i]
                ? user[i].results.map((q) =>
                    historicalUserData[i + 1].questionData[q.question_id]
                      ? historicalUserData[i + 1].questionData[q.question_id]
                          .averageAnswer
                      : 1
                  )
                : [],

              backgroundColor: [theme.palette.primary.light],
            },
          ]
        : [
            {
              label: `${details.name || 'Company'}`,
              data: [
                ...Object.keys(companyData).map(
                  (q) => companyData[q].averageAnswer
                ),
                5, // add a dummy value to display last xLabels
              ],
              backgroundColor: [theme.palette.primary.light],
            },
          ]),
    ],
  });

  useEffect(() => {
    if (Array.isArray(user)) {
      setSelected(user.length - 1);
    }
  }, [user]);

  const xLabels = {
    0: '',
    1: 'Rarely',
    2: 'Occasionally',
    3: 'Often',
    4: 'Usually',
    5: 'Most of the Time',
  };

  const questionBarOptions = (xLabel, yLabel) => ({
    indexAxis: 'y',
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // empty function so title does not display
          title: function () {},
        },
      },
      legend: { display: true },
      title: { display: false },
    },

    scales: {
      x: {
        ticks: {
          min: 0,
          max: 8,
          stepSize: 1,
          callback: function (value, index, values) {
            return xLabels[value];
          },
        },
      },
    },
  });

  return (
    <Grid item xs={12} className={!companyData ? classes.grid : classes.gridC}>
      <Card className={!companyData ? classes.card : classes.cardC}>
        <div className={classes.CardHeader}>
          <CardHeader
            titleTypographyProps={{ variant: 'h6' }}
            title={
              <>
                Assessment Questions
                {!disableSelector && (
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      className={classes.inputLabel}
                      htmlFor="assessment-date-select"
                    >
                      Assessment
                    </InputLabel>

                    <FormControlLabel
                      control={
                        <Select
                          id="assessment-date-select"
                          variant="outlined"
                          className={classes.select}
                          value={selected}
                          onChange={(e) => {
                            setSelected(e.target.value);
                          }}
                        >
                          {user[0] &&
                            user.map((q, i) => (
                              <MenuItem value={i}>
                                {format(new Date(q.results_date), 'dd/MM/yy')}
                              </MenuItem>
                            ))}
                        </Select>
                      }
                    />
                  </FormControl>
                )}
              </>
            }
            className={classes.cardTitle}
          />
        </div>
        <CardContent>
          <Bar
            data={questionBarData(selected)}
            options={questionBarOptions()}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AllQuestionsChart;

import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import PageHeader from '../components/PageHeader';
import TermsPage from '../components/TermsPage';
import PrivacyPage from '../components/PrivacyPage';

const style = makeStyles(() => ({
  button: { float: 'right', marginRight: 5, marginTop: 15 },
}));

export default function TestTerms() {
  const classes = style();
  const [eulaAccepted, setEulaAccepted] = useState(false);

  const terms = {
    tAndC: [
      'This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.',
      'We use the data filled in by you only to perform some research on it and provide you with the results. Our mission at Cernova and the Mind Peak Performance Institute is to help organisations and individuals achieve Peak Performance, safely.',
      'Using the latest in applied Neuroscience research, we apply cutting edge Neurological technology to assist people to thrive under pressure to deliver exceptional results that drive the bottom line, all without the negative health impacts associated with high pressure environments.',
      'Information Collection and Use',
      'Personal Data: At various points, we might ask you to fill in your name, date of birth, email or company name. This data is collected confidentially and we assure you that no other unauthorised parties have access to it. The data is collected to identify each individual taking the assessments and to be able to locate and match the results.',
      'Behavioral Data: At various instances, you will be asked in to fill out various choices to indicate your behavioral preferences. This is done to be able to evaluate the user choices to provide feedback that can help improve better self-awareness around the onset of stress.',
      'Consent',
      'By using and visiting cernova.com and mindpeakperformance.com, you consent to our online Privacy Policy',
    ],
  };
  const privacy = {
    pPoli: [
      'This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.',
      'We use the data filled in by you only to perform some research on it and provide you with the results. Our mission at Cernova and the Mind Peak Performance Institute is to help organisations and individuals achieve Peak Performance, safely.',
      'Using the latest in applied Neuroscience research, we apply cutting edge Neurological technology to assist people to thrive under pressure to deliver exceptional results that drive the bottom line, all without the negative health impacts associated with high pressure environments.',
      'Information Collection and Use',
      'Personal Data: At various points, we might ask you to fill in your name, date of birth, email or company name. This data is collected confidentially and we assure you that no other unauthorised parties have access to it. The data is collected to identify each individual taking the assessments and to be able to locate and match the results.',
      'Behavioral Data: At various instances, you will be asked in to fill out various choices to indicate your behavioral preferences. This is done to be able to evaluate the user choices to provide feedback that can help improve better self-awareness around the onset of stress.',
      'Consent',
      'By using and visiting cernova.com and mindpeakperformance.com, you consent to our online Privacy Policy',
    ],
  };

  return (
    <Grid>
      <PageHeader
        title="Please View the Terms and Conditions:"
        backPath="back"
      />
      <br />
      {eulaAccepted ? (
        <Typography variant="h4" align="center">
          Thanks for Accepting.
        </Typography>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <TermsPage terms={terms.tAndC} />
          <PrivacyPage privacy={privacy.pPoli} />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setEulaAccepted(true)}
          >
            I Have Read and Accept the Terms and Conditions
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

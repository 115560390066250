/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useApi from '../functions/useApi';
import PageHeader from '../components/PageHeader';
import LoadingBackground from '../components/LoadingBackground';
import UserForm from '../components/UserForm';
import { useTagsContext } from '../context/TagsContext';
import { useUsersContext } from '../context/UsersContext';
import { useAlertContext } from '../context/AlertContext';
import { useCompanyContext } from '../context/CompanyContext';
// import AllQuestionsChart from '../components/AllQuestionsChart';
import AllQuestionsChart from '../components/AllQuestionsChart';

const style = makeStyles(({ spacing, palette }) => ({
  card: {
    margin: 10,
    marginTop: '1rem',
    borderRadius: spacing(0.5),
    width: '100%',
  },
  CardHeader: {
    flexGrow: 1,
    backgroundColor: palette.secondary.main,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    '& .MuiCardHeader-root': {
      padding: 0,
    },
  },
  root: {
    height: '100%',
  },
  grid: {
    display: 'flex',
  },
  select: {
    float: 'right',
    marginLeft: 'auto',
    marginRight: 'auto',
    minWidth: 240,
    borderRadius: spacing(0.5),
  },
  formControl: { margin: 15, marginTop: '2rem' },
  buttonGroup: {
    marginBottom: '1rem',
  },
  toolbar: {
    backgroundColor: palette.primary.main,
    borderRadius: '0.2rem',
    marginBottom: '1rem',
  },
  cardTitle: {
    textAlign: 'center',
    '& .MuiCardHeader-title': { color: palette.secondary.contrastText },
  },
  userLabel: {
    paddingLeft: '1rem',
  },
  editButton: {
    textTransform: 'none',
  },
  noAnalyticsText: {
    paddingTop: '1rem',
  },
}));

export default function UserAnalytics(props) {
  const classes = style();
  const { get, post } = useApi();
  const { match } = props;
  const id = match.params.id;
  const state = match.params.state;
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [safetyEnabled, setSafetyEnabled] = useState(false);
  const [resilienceEnabled, setResilienceEnabled] = useState(false);
  const [defaultValue, setDefaultValue] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const { tags } = useTagsContext();
  const { users } = useUsersContext();
  const alertContext = useAlertContext();
  const theme = useTheme();
  const { identifiedData } = useCompanyContext();

  const userData =
    users.filter((u) => u.user_id === parseInt(id, 10))[0] || null;

  useEffect(async () => {
    const result = await get({ url: `company/user/${id}` });
    setUser(result.message);

    setDefaultValue({
      ...result.message[0],
      phoneNumber: result.message[0].phone_number,
      startDate: result.message[0].start_date,
      admin: result.message[0].is_admin,
    });
    setSafetyEnabled(!!result.message[0].safety);
    setResilienceEnabled(!!result.message[0].resilience);
    setLoading(false);
    if (state === 'edit') {
      setEditUser(true);
    }
  }, []);

  const resAvg =
    user
      .filter((u) => u.resilience)
      .reduce((acc, value) => acc + value.resilience, 0) / user.length;

  const safAvg =
    user.filter((u) => u.safety).reduce((acc, value) => acc + value.safety, 0) /
    user.length;

  const barOptions = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (e) => `${Math.round((e.parsed.x || 0) * 100) / 100}`,
        },
      },
      legend: {
        display: false,
      },
    },
  };

  // const resilience = user.filter((u) => u.safety);

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // e.dataset.shortLabel is custom key in datasetObject
          label: (e) => `${e.dataset.shortLabel}: ${e.parsed.y}`,
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  // TODO refactor code remove duplicated code, make more dynamic
  const barData = (e) => {
    if (e === 'safety') {
      return {
        labels: ['Safety'],
        datasets: [
          {
            label: 'Scores out of 100%',
            data: [safAvg],
            backgroundColor: ['rgba(2, 157, 0, 1)'],
            borderColor: ['rgba(2, 157, 0, 1)'],
            borderWidth: 1,
          },
        ],
      };
    }
    return {
      labels: ['Resilience'],
      datasets: [
        {
          label: 'Scores out of 100%',
          data: [resAvg],
          backgroundColor: ['rgba(0, 29, 255, 1)', 'rgba(2, 157, 0, 1)'],
          borderColor: ['rgba(0, 29, 255, 1)', 'rgba(2, 157, 0, 1)'],
          borderWidth: 1,
        },
      ],
    };
  };

  const assessmentDates = user.map((u) =>
    new Date(u.results_date).toLocaleDateString()
  );

  const lineResData = {
    labels: assessmentDates,
    datasets: [
      {
        shortLabel: 'Resilience',
        label: 'Users Resilience Test Scores',
        data: user.map((u) => u.resilience),
        fill: false,
        backgroundColor: 'rgb(17, 0, 255)',
        borderColor: 'rgba(17, 0, 255, 0.5)',
      },
    ],
  };

  const lineSafetyData = {
    labels: assessmentDates,
    datasets: [
      {
        label: 'Users Safety Test Scores',
        data: user.map((u) => u.safety),
        fill: false,
        backgroundColor: 'rgba(2, 157, 0, 1)',
        borderColor: 'rgba(2, 157, 0, 0.5)',
      },
    ],
  };

  const resendInviteAndSignup = async () => {
    try {
      const res = await post({
        url: `user/invite/${id}`,
      });
      console.log(res);
      alertContext.success(`invite has been sent`);
    } catch (error) {
      console.error(error);
      alertContext.error('An error occurred failed to send');
    }
  };

  const xLabels = {
    0: '',
    1: 'Rarely',
    2: 'Occasionally',
    3: 'Often',
    4: 'Usually',
    5: 'Most of the Time',
  };

  const xLabelsColors = {
    0: '',
    1: 'rgba(255, 0, 0, 1)',
    2: 'rgba(255, 154, 0, 1)',
    3: 'rgba(255, 255, 0, 1)',
    4: 'rgba(144, 255, 0, 1)',
    5: 'rgba(0, 255, 0, 1)',
  };

  const questionBarOptions = (xLabel, yLabel) => ({
    indexAxis: 'y',
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          // empty function so title does not display
          title: function () {},
        },
      },
      legend: { display: true },
      title: { display: false },
    },

    scales: {
      x: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return xLabels[value];
          },
        },
      },
    },
  });

  const backgroundColorArray = [
    'rgba(255, 154, 0, 1)',
    'rgba(255, 255, 0, 1)',
    'rgba(144, 255, 0, 1)',
    'rgba(0, 255, 0, 1)',
    'rgba(255, 0, 0, 1)',
  ];

  console.log(user);
  const questionBarData = (data) => ({
    labels: user[0] ? user[0].results.map((q) => q.question) : [],
    datasets: [
      {
        label: 'Standard',
        data: user[0] ? user[0].results.map((q) => q.answer) : [],
        // minBarLength only apply to values that are not null

        backgroundColor: [theme.palette.primary.dark],
      },
      {
        label: user[0] ? user[0].name : 'user',
        data: user[0] ? user[0].results.map((q) => q.answer + 1) : [],
        // minBarLength only apply to values that are not null

        backgroundColor: [theme.palette.primary.light],
      },
    ],
  });

  // props for header card header title
  const titleTypographyProps = { titleTypographyProps: { variant: 'h6' } };

  return (
    <>
      {loading ? (
        <LoadingBackground />
      ) : (
        <>
          <PageHeader
            backPath="back"
            title={
              <Grid item xs={12}>
                <Tooltip title="Edit User">
                  <Button
                    className={classes.editButton}
                    id="editButton"
                    key="editButton"
                    onClick={() => {
                      window.history.replaceState(
                        null,
                        'edit',
                        `/user/analytics/${id}/${!editUser ? 'edit' : 'view'}`
                      );
                      setEditUser(!editUser);
                    }}
                  >
                    <Typography variant="h5" className={classes.userLabel}>
                      {`${userData.name || 'User '}  `}
                    </Typography>
                  </Button>
                </Tooltip>
                <Tooltip title="Send invite to user to sign up">
                  <Button
                    style={{ float: 'right' }}
                    className={classes.editButton}
                    id="editButton"
                    key="editButton"
                    onClick={() => {
                      resendInviteAndSignup();
                    }}
                  >
                    <NotificationsIcon />
                  </Button>
                </Tooltip>
              </Grid>
            }
          />
          <Grid container>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {editUser && (
              <Grid item xs={12}>
                <UserForm
                  collectionOfTags={tags}
                  defaultValue={userData}
                  updateUser
                  userId={parseInt(id, 10)}
                />
              </Grid>
            )}
            {user[0].results !== null && identifiedData && (
              <>
                <Grid item xs={4} className={classes.grid}>
                  <Card className={classes.card}>
                    <div className={classes.CardHeader}>
                      <CardHeader
                        {...titleTypographyProps}
                        title="Average Score"
                        className={classes.cardTitle}
                      />
                    </div>
                    <CardContent>
                      <Bar data={barData} options={barOptions} />
                    </CardContent>
                  </Card>
                </Grid>
                {resilienceEnabled && (
                  <Grid item xs={4} className={classes.grid}>
                    <Card className={classes.card}>
                      <div className={classes.CardHeader}>
                        <CardHeader
                          {...titleTypographyProps}
                          title="Resilience Score Timeline"
                          className={classes.cardTitle}
                        />
                      </div>
                      <CardContent>
                        <Line data={lineResData} options={options} />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {safetyEnabled && (
                  <Grid item xs={4} className={classes.grid}>
                    <Card className={classes.card}>
                      <div className={classes.CardHeader}>
                        <CardHeader
                          {...titleTypographyProps}
                          title="Safety Score"
                          className={classes.cardTitle}
                        />
                      </div>
                      <CardContent>
                        <Bar data={barData('safety')} options={barOptions} />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                <AllQuestionsChart user={user} />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
            {!user[0].results && identifiedData && (
              <Grid item xs={12}>
                <Typography
                  className={classes.noAnalyticsText}
                  variant="h5"
                  align="center"
                >
                  No Analytics are available for - {user[0].name}
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
}

// const exampleParams = {
//   currentAssessmentDate: new Date(),
//   repeat: 2,
//   repeatType: 'Month',
//   preferredDay: 1,
//   weekNumber: 5,
// };

const calcNextAssessmentDate = (params) => {
  const d = new Date(params.currentAssessmentDate);
  // console.log('Params: ', params);
  if (params.repeatType === 'dayOfWeek') {
    return new Date(d.setDate(d.getDate() + 7 * parseInt(params.repeat, 10)));
  }
  if (params.repeatType === 'dateOfMonth') {
    let testDate = new Date(
      d.setMonth(d.getMonth() + parseInt(params.repeat, 10))
    );
    while (testDate.getDate() !== parseInt(params.preferredDay, 10)) {
      testDate = new Date(d.setDate(parseInt(params.preferredDay, 10)));
    }
    return testDate;
  }
  // dayOfMonth
  if (parseInt(params.weekNumber, 10) <= 4) {
    // Every month has 4 weeks
    const dateOfWeek = new Date(
      d.getFullYear(),
      d.getMonth() + parseInt(params.repeat, 10),
      (parseInt(params.weekNumber, 10) - 1) * 7 + 1
    ); // a day of 0 of the next month = last day of this month
    // console.log('Date of week start:', dateOfWeek);
    const weekStartDay = dateOfWeek.getDay();
    // ;
    if (weekStartDay > parseInt(params.preferredDay, 10)) {
      return new Date(
        dateOfWeek.setDate(
          dateOfWeek.getDate() +
            (7 - weekStartDay + parseInt(params.preferredDay, 10))
        )
      );
    }
    if (weekStartDay < parseInt(params.preferredDay, 10)) {
      return new Date(
        dateOfWeek.setDate(
          dateOfWeek.getDate() +
            (parseInt(params.preferredDay, 10) - weekStartDay)
        )
      );
    }
    return dateOfWeek;
  }
  // Last x Day of month
  const endOfMonthDate = new Date(
    d.getFullYear(),
    d.getMonth() + parseInt(params.repeat, 10) + 1,
    0
  );
  const monthEndDay = endOfMonthDate.getDay();
  if (monthEndDay > parseInt(params.preferredDay, 10)) {
    return new Date(
      endOfMonthDate.setDate(
        endOfMonthDate.getDate() -
          (monthEndDay - parseInt(params.preferredDay, 10))
      )
    );
  }
  if (monthEndDay < parseInt(params.preferredDay, 10)) {
    return new Date(
      endOfMonthDate.setDate(
        endOfMonthDate.getDate() -
          (7 - parseInt(params.preferredDay, 10) + monthEndDay)
      )
    );
  }
  return new Date(endOfMonthDate);
};

export const previousScheduleDates = (params) => {
  const logObj = {};
  // eslint-disable-next-line array-callback-return
  params.iterationLogs.map((l) => {
    logObj[l.message] = new Date(l.date);
  });

  const foo = [...Array(params.currentAssessmentIteration).keys()];
  const test = foo.map((v, i) => ({
    value: i + 1,
    label: logObj[i + 1] || new Date('April 5, 2000'),
  }));
  return test;
};

export const scheduleDates = (params) => {
  const dateArray = [];
  dateArray.push(calcNextAssessmentDate(params));

  for (let i = 0; i < 4; i++) {
    const newParams = { ...params, ...{ currentAssessmentDate: dateArray[i] } };

    dateArray.push(calcNextAssessmentDate(newParams));
  }
  return dateArray;
};

import React, { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import FormikForm from './FormikForm';
import FormInputTextField from './FormInputTextField';
import { HelpFormSchema } from '../assets/validator';
import useApi from '../functions/useApi';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  variant: 'contained',
}));

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  width: '6rem',
  borderRadius: '2rem',
  backgroundColor: 'violet',
  ':hover': {
    backgroundColor: 'purple',
    color: 'white',
  },
  fontSize: '1rem',
}));

const HelpButton = ({ aKey }) => {
  const { post, get } = useApi();
  const [openForm, setOpenForm] = useState(false);
  const [initialPress, setInitialPress] = useState(false);
  const [sent, setSent] = useState(false);
  const [helpPressed, setHelpPressed] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },

    validationSchema: HelpFormSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log('values', values);

      try {
        const res = await post({
          url: `form/help/submission${aKey ? `/${aKey}` : ''}`,
          body: values,
          noAuth: !!aKey,
        });
        setSent(true);

        console.log(res);
      } catch (error) {
        console.error(error);
      }

      setSubmitting(false);
    },
  });

  useEffect(async () => {
    if (helpPressed) {
      if (aKey) {
        try {
          const res = await get({
            url: `form-p/${aKey}`,
            noAuth: true,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }, [helpPressed]);

  const darkColor = '#D58BDD';
  const lightColor = '#FF99D7';

  return (
    <Container
      maxWidth="sm"
      sx={{
        marginBottom: '3rem',
        marginTop: '3rem',
        borderWidth: '0.1rem',
        borderRadius: '2rem',
        borderColor: 'violet',
        background: `linear-gradient(to right, ${darkColor}, ${lightColor});`,
        boxShadow: 1,
      }}
    >
      <FormikForm formik={formik}>
        <Grid
          spacing={1}
          container
          sx={{
            padding: '1rem',
            paddingRight: '2rem',
            paddingLeft: '1rem',
            minHeight: '13rem',
          }}
        >
          {!openForm ? (
            <Grid item xs={12}>
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', color: 'purple' }}
              >
                {`Do you need personal assistance? `}
              </Typography>
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', color: 'purple' }}
              >
                {`It's ok to ask for help `}
              </Typography>
              <Typography
                variant="h6"
                sx={{ textAlign: 'center', color: 'purple' }}
              >
                {`By clicking this button you will be reaching out to your organisation and
          identifying yourself to them so they can make contact with you.`}
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={6} sx={{ paddingRight: '1rem' }}>
                <FormInputTextField
                  placeholder="Type your message here"
                  key="name"
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormInputTextField
                  placeholder="Type your message here"
                  key="email"
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <FormInputTextField
                  placeholder="Type your message here"
                  key="message"
                  id="message"
                  name="message"
                  fullWidth
                  multiline
                  label="Your message"
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid container spacing={3}>
          <Grid xs>
            <Item />
          </Grid>
          <Grid xs={6}>
            <Item>
              {openForm ? (
                <StyledButton
                  loading={formik.isSubmitting}
                  variant="contained"
                  endIcon={
                    sent ? <CheckCircleOutlineIcon /> : <EmojiPeopleIcon />
                  }
                  onClick={() => {
                    setInitialPress(true);
                    if (sent) {
                      formik.resetForm();
                      setSent(false);
                    }
                  }}
                  type={initialPress ? 'submit' : 'button'}
                  disabled={formik.isSubmitting}
                >
                  {openForm ? `Sen${sent ? 't' : 'd'}` : 'Help'}
                </StyledButton>
              ) : (
                <StyledButton
                  loading={formik.isSubmitting}
                  variant="contained"
                  endIcon={<EmojiPeopleIcon />}
                  onClick={() => {
                    setHelpPressed(true);
                    if (sent) {
                      formik.resetForm();
                      setSent(false);
                    }
                    setOpenForm(true);
                  }}
                  disabled={formik.isSubmitting}
                >
                  Help
                </StyledButton>
              )}
            </Item>
          </Grid>
          <Grid xs>
            <Item>
              {openForm && (
                <IconButton
                  sx={{ marginLeft: '5rem' }}
                  onClick={() => setOpenForm(false)}
                  disabled={formik.isSubmitting}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Item>
          </Grid>
        </Grid>
      </FormikForm>
    </Container>
  );
};

export default HelpButton;

import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';

export default ({ render: C, props: childProps, userTypes, ...rest }) => {
  const authContext = useAuthContext();
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      {...rest}
      render={(rProps) =>
        isAuthenticated &&
        (!userTypes || authContext.hasAnyUserType(userTypes)) ? (
          <C {...rProps} {...childProps} />
        ) : (
          <Redirect to={{ pathname: `/login`, replace: true }} />
        )
      }
    />
  );
};

import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import ClientTable from '../components/ClientTable';
import useApi from '../functions/useApi';
import LoadingBackground from '../components/LoadingBackground';

export default function Dashboard() {
  const [clients, setClients] = useState([]);
  const { get, put } = useApi();

  const reload = async () => {
    const result = await get({ url: 'company/all' });
    console.log(result.message);
    setClients(result.message);
  };

  useEffect(async () => {
    reload();
  }, []);

  /**
   * @param {Number} companyId The date
   * @param {string} type type of change 'resilience' 'safety' or 'tip_sheet'
   */
  async function handleClientFlagChange(event, id, type) {
    console.log(id, event.target.checked);

    if (
      ['resilience', 'safety', 'tip_sheet', 'identified_data'].includes(type)
    ) {
      const data = {
        id: id,
        [type]: event.target.checked,
      };
      let result = await put({
        url: 'modifycompany',
        body: JSON.stringify(data),
      });
      console.log(result.message);
      if (result.message.numberOfRecordsUpdated === 1) {
        result = await get({ url: 'company/all' });
        setClients(result.message);
      }
    }
  }

  return (
    <div>
      {clients ? (
        <div>
          <PageHeader addPath="/customer/" />
          <br />
          <div>
            {clients && (
              <ClientTable
                reload={reload}
                clients={clients}
                handleClientFlagChange={handleClientFlagChange}
              />
            )}
          </div>
        </div>
      ) : (
        <LoadingBackground />
      )}
    </div>
  );
}

/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import makeStyles from '@mui/styles/makeStyles';
import FormikForm from '../components/FormikForm';
import FormInputText from '../components/FormInputText';
import FormInputSelect from '../components/FormInputSelect';
import FormButton from '../components/FormButton';
import PageHeader from '../components/PageHeader';
import { QuestionFormSchema } from '../assets/validator';
import useApi from '../functions/useApi';

const style = makeStyles(() => ({
  buttonGroup: { marginTop: '1rem' },
}));

export default function ManageQuestion(props) {
  // TODO: Add Validation + POST Question
  const id = props.match.params.id;
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = style();
  const { post, get } = useApi();
  const [successfullySubmitted, setSuccessfullySubmitted] = useState({
    submitted: false,
    error: false,
  });

  const categories = [];
  useEffect(async () => {
    setLoading(true);
    const result = await get({ url: 'questions' });
    setQuestions(result.message);
    setLoading(false);
  }, []);

  questions.forEach((element) => {
    if (!categories.includes(element.name)) {
      categories.push(element.name);
    }
  });

  const change = true;

  const formik = useFormik({
    initialValues: { questionName: '', questionCategory: '' },
    validationSchema: QuestionFormSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      try {
        const submitData = JSON.stringify(values);
        console.log(submitData);
        formik.setSubmitting(false);
      } catch (err) {
        // TODO do something with error
        console.log(err);
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <PageHeader
        title="Add Question:"
        subheader="Use the below form to add a Question"
        backPath="back"
      />

      <div>
        {successfullySubmitted.submitted && (
          <div>
            <Alert severity="success">Customer successfully created</Alert>
            <ButtonGroup className={classes.buttonGroup}>
              <Button
                onClick={() => window.location.reload()}
                color="primary"
                variant="outlined"
              >
                Create Another Question
              </Button>
              <Button
                component={Link}
                to="/"
                color="primary"
                variant="outlined"
              >
                Back
              </Button>
            </ButtonGroup>
          </div>
        )}
        {!successfullySubmitted.submitted && change && (
          <FormikForm formik={formik}>
            <FormInputText
              fullWidth
              name="questionName"
              label="Question Name:"
              type="text"
            />
            <FormInputSelect
              key="questionCategory"
              label="Question Category:"
              name="questionCategory"
              options={categories.map((c) => ({ value: c, label: c }))}
            />
            <FormButton
              customStyle={{ marginTop: '1rem', marginBottom: '1rem' }}
              fullWidth
              text={id ? 'Update' : 'Create'}
              loading={formik.isSubmitting}
            />
          </FormikForm>
        )}
      </div>
    </>
  );
}

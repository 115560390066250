import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import { useField } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import FormHelperText from '@mui/material/FormHelperText';
import HelpIcon from '@mui/icons-material/Help';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

const ColorPicker = (props) => {
  const [field, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);
  const [helpBox, setHelpBox] = useState(false);

  const { show, key, windowSmall, resetButton } = props;

  const colorPointer = {
    0: { label: 'white', colHex: '#ebebeb' },
    1: { label: 'black', colHex: '#000000' },
    2: { label: 'darkGreen', colHex: '#808000' },
    3: { label: 'gray', colHex: '#808080' },
    4: { label: 'green', colHex: '#008000' },
    5: { label: 'darkBlue', colHex: '#1b089c' },
    6: { label: 'lightGreen', colHex: '#14e014' },
    7: { label: 'lightBlue', colHex: '#42c0fb' },
    8: { label: 'yellow', colHex: '#ffff00' },
    9: { label: 'pink', colHex: '#ffc0cb' },
    10: { label: 'brown', colHex: '#964b00' },
    11: { label: 'purple', colHex: '#b330ff' },
    12: { label: 'orange', colHex: '#ff9e1f' },
    13: { label: 'red', colHex: '#e81b05' },
    14: { label: 'strongRed', colHex: '#c70039' },
    15: { label: 'lightCyan', colHex: '#b1f9ff' },
  };

  const [colorData, setColorData] = React.useState({ ...colorPointer });
  const [resetPressed, setResetPressed] = React.useState(false);

  const colorHandleOnChange = (event) => {
    helpers.setTouched(true);
    helpers.setError(true);
    const colorName = parseInt(event.target.name, 10);

    setColorData(() => {
      delete colorData[colorName];
      return colorData;
    });
    helpers.setValue(
      Array.isArray(field.value) ? field.value.concat([colorName]) : [colorName]
    );
  };

  const ColorPick = Object.keys(colorData).map((data) => {
    if (show) {
      return (
        <Fab
          name={data}
          key={data}
          value={data}
          color="inherit"
          style={{
            backgroundColor: colorData[data].colHex,
            margin: 5,
          }}
          onClick={colorHandleOnChange}
        >
          {' '}
        </Fab>
      );
    }
    return null;
  });

  return (
    <Grid
      key={key}
      item
      xs={12}
      style={{
        marginTop: 30,
        marginBottom: 30,
        textAlign: 'left',
        minHeight: `${Object.keys(colorData).length === 0 ? `1` : '14'}rem`,
      }}
    >
      {windowSmall && Object.keys(colorData).length && (
        <div>
          <Typography color={hasError ? 'error' : 'inherit'}>
            Please <b>Rotate</b> <ScreenRotationIcon /> your screen to landscape
            to begin the colour selection test
          </Typography>
        </div>
      )}
      {!windowSmall && Object.keys(colorData).length > 0 && (
        <div>
          <Typography
            color="inherit"
            style={{
              padding: '10,0,100,1000',
              textAlign: 'left',
            }}
            variant="h6"
          >
            Select the colour you are most drawn to until no colors remain{' '}
            <IconButton
              aria-label="help"
              onClick={() => {
                if (helpBox) {
                  setHelpBox(false);
                } else {
                  setHelpBox(true);
                }
              }}
              size="large"
            >
              {!helpBox ? (
                <HelpOutlineOutlinedIcon fontSize="small" />
              ) : (
                <HelpIcon fontSize="medium" />
              )}
            </IconButton>
          </Typography>

          {helpBox && (
            <Paper variant="outlined" elevation={3}>
              {' '}
              The colour you choose will disappear and you will need to choose
              the next colour you are most drawn to from the remaining colours.
              Continue to do this until the colours are ALL GONE. Do not try to
              associate the colour with something else such as clothing,
              furnishings, cars etc. When there are no more colours simply click
              on the SUBMIT button.
            </Paper>
          )}
          <div style={{ width: 540 }}>{ColorPick}</div>
          {hasError && (
            <FormHelperText error>
              Please select all the colours until none remain
            </FormHelperText>
          )}
        </div>
      )}
      {resetButton && !resetPressed && (
        <>
          <Card>
            <Typography
              variant="h6"
              align={windowSmall ? 'center' : 'left'}
              style={{
                paddingLeft: `${windowSmall ? '0' : '1'}rem`,
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              Please make sure that you select colours based on what you are
              attracted to
            </Typography>
            <Button
              fullWidth
              style={{ color: 'white', background: 'orange' }}
              variant="contained"
              onClick={() => {
                setColorData({ ...colorPointer });
                helpers.setValue([]);
                setResetPressed(true);
              }}
            >
              Retry color selection
            </Button>
          </Card>
        </>
      )}
    </Grid>
  );
};

export default ColorPicker;

import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';

const FormInputTextField = (props) => {
  const {
    id,
    name,
    label,
    disabled,
    type = 'text',
    placeholder,
    width,
    fullWidth,
    multiline,
    autoFocus = false,
    variant = 'outlined',
    ...rest
  } = props;

  const [field, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <TextField
      id={id}
      label={label}
      multiline={multiline}
      maxRows={4}
      {...rest}
      {...field}
      error={hasError}
      fullWidth={fullWidth}
      variant={variant}
      helperText={hasError ? meta.error : null}
      autoFocus={autoFocus}
      placeholder={placeholder}
      type={type}
    />
  );
};

export default FormInputTextField;

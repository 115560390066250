/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch } from 'react-router-dom';
import Form from './pages/Form';
import ManageCustomer from './pages/ManageCustomer';
import ManageQuestion from './pages/ManageQuestion';
import LoginPage from './pages/Login';
import Dashboard from './pages/Dashboard';
import CSV from './pages/UploadCSV';
import QuestionSelection from './pages/QuestionSelection';
import QuestionBuilder from './pages/QuestionBuilder';
import Analytics from './pages/Analytics';
import UserAnalytics from './pages/UserAnalytics';
import TestTerms from './pages/TestTerms';
import Testboard from './pages/Testboard';
import ProtectedRoute from './components/ProtectedRoute';
import UnAuthRoute from './pages/UnAuthRoute';
import { UserTypeObject } from './constants/UserTypes';
import AssessmentOverview from './pages/AssessmentOverview';
import TestingBoard from './pages/TestingBoard';
import NoUser from './pages/NoUser';
// import NoRoute from './components/NoRoute';
import Settings from './pages/Settings';
import EmailEditor from './pages/EmailEditor';
import NoAuthRoute from './components/NoAuthRoute';

const Routes = ({ childProps }) => (
  <Switch>
    <ProtectedRoute
      exact
      path="/testing-board"
      userTypes={UserTypeObject.CERNOVA}
      render={TestingBoard}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/notifications"
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      render={EmailEditor}
      props={childProps}
    />
    <NoAuthRoute
      exact
      path="/assessment/:key"
      render={Form}
      props={childProps}
    />
    <NoAuthRoute exact path="/a/:key" render={Form} props={childProps} />
    <NoAuthRoute exact path="/assessment" render={Form} props={childProps} />
    <ProtectedRoute
      exact
      path="/customer/:id"
      render={ManageCustomer}
      userTypes={UserTypeObject.CERNOVA}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/customer/"
      render={ManageCustomer}
      userTypes={UserTypeObject.CERNOVA}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/termstest/"
      render={TestTerms}
      childProps={childProps}
    />
    <ProtectedRoute
      exact
      path="/question/"
      render={ManageQuestion}
      userTypes={UserTypeObject.CERNOVA}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/analytics/"
      render={Analytics}
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/overview"
      render={AssessmentOverview}
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/user/analytics/:id/:state"
      render={UserAnalytics}
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/question/:id"
      render={ManageQuestion}
      userTypes={UserTypeObject.CERNOVA}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/question-selection"
      render={QuestionSelection}
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/question-builder"
      render={QuestionBuilder}
      userTypes={UserTypeObject.CERNOVA}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/upload/users"
      render={CSV}
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/dashboard"
      render={Dashboard}
      props={childProps}
      userTypes={UserTypeObject.CERNOVA}
    />
    <ProtectedRoute
      exact
      path="/testboard"
      render={Testboard}
      userTypes={UserTypeObject.CERNOVA}
      props={childProps}
    />
    <ProtectedRoute
      exact
      path="/settings"
      render={Settings}
      userTypes={UserTypeObject.COMPANY_ADMINISTRATOR}
      props={childProps}
    />
    <ProtectedRoute exact path="/no-user" render={NoUser} props={childProps} />
    <UnAuthRoute exact path="/login" render={LoginPage} props={childProps} />
    <UnAuthRoute exact path="/" render={LoginPage} props={childProps} />
    {/* <NoRoute props={childProps} /> */}
  </Switch>
);

export default Routes;

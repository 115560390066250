/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Chip from '@mui/material/Chip';
import Delete from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import FormikForm from './FormikForm';
import { newTagScheme } from '../assets/validator';
import DeleteDialog from './DeleteDialog';
import useApi from '../functions/useApi';

const style = makeStyles(({ palette }) => ({
  cardTag: {
    marginTop: '0.5rem',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    minHeight: '1rem',
    textColor: palette.secondary.contrastText,

    '& .MuiButtonBase-root': {
      backgroundColor: palette.secondary.main,
      borderRadius: '0.2rem',
    },

    '& .MuiCardHeader-title': { color: palette.secondary.contrastText },
    '& .MuiIconButton-root': { color: palette.secondary.contrastText },
    '& .MuiTypography-root': { color: palette.secondary.contrastText },
  },
  tagChip: {
    marginLeft: '.2rem',
  },
}));

export default function TagCard({ tagGroup, reload }) {
  const classes = style();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { post, deleteAPI } = useApi();
  const tagGroupId = tagGroup.tag_group_id;
  const [deleteTag, setDeleteTag] = useState(false);
  const handleCloseDialog = () => {
    setDialogVisible(false);
    setDeleteTag(false);
  };

  const formik = useFormik({
    initialValues: { newTag: '' },
    validationSchema: newTagScheme,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const res = await post({
          url: 'addtag',
          body: { name: values.newTag, tagGroupId: tagGroupId },
        });
        console.log(res);
      } catch (error) {
        console.error(error);
      }
      reload();
      setSubmitting(false);
      resetForm();
    },
  });

  const handleDeleteTagGroup = async () => {
    setDialogVisible(false);
    let params = {};
    if (deleteTag === false) {
      params = {
        url: `taggroup`,
        id: tagGroupId,
      };
    } else if (deleteTag) {
      params = {
        url: `tag`,
        id: deleteTag.value,
      };
    }
    try {
      setLoading(true);
      const res = await deleteAPI(params);
      console.log(res);
      reload();
      setDeleteTag(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleChip = (item) => {
    setDeleteTag(item);
    setDialogVisible(true);
  };

  return (
    <Accordion
      style={{ width: '100%' }}
      key={tagGroupId}
      className={classes.cardTag}
    >
      <AccordionSummary
        key={`${tagGroupId}-summary`}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6">
          <div
            style={{ minWidth: '12.5rem', marginTop: '12px', float: 'left' }}
          >
            {tagGroup.question}
          </div>

          <Tooltip title="Delete Tag Group">
            <IconButton
              value={tagGroupId}
              onClick={() => setDialogVisible(true)}
              aria-label="delete"
              size="large"
            >
              {loading ? (
                <CircularProgress fontSize="small" />
              ) : (
                <Delete fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Typography>
      </AccordionSummary>

      <AccordionDetails key={`${tagGroupId}-details`}>
        <FormikForm formik={formik}>
          {tagGroup.options &&
            tagGroup.options.map((t, i) => (
              <Chip
                key={`${t.value}${i}`}
                className={classes.cardTag}
                color="secondary"
                label={t.label}
                value={t.value}
                onDelete={() => handleChip(tagGroup.options[i])}
              />
            ))}
          {/* <Grid container> */}
          {/* // TODO enable once user edit support is there */}
          {/* <Grid item xs={10}>
              <TextInput
                key="newTag"
                id="newTag"
                name="newTag"
                placeholder="Enter new tag"
              />
            </Grid>
            <Grid item xs={2}>
              <FormButton
                text="submit"
                buttonType="add"
                loading={formik.isSubmitting}
                disabled={!formik.values.newTag}
              />
            </Grid> */}
          {/* </Grid> */}
        </FormikForm>
        <DeleteDialog
          handleClose={handleCloseDialog}
          context={`Are you sure you want to remove 
        ${
          deleteTag
            ? `tag ${deleteTag.label}`
            : `tag group ${tagGroup.question}`
        }`}
          title="Delete Tag Group?"
          open={dialogVisible}
          confirmDelete={handleDeleteTagGroup}
        />
      </AccordionDetails>
    </Accordion>
  );
}

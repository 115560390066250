import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import logo from '../assets/cernova.png';

const LoginPage = ({ screenWidth }) => {
  const useStyles = makeStyles((theme) => ({
    LoginButton: {
      width: '100%',
      marginTop: screenWidth < 820 ? '1rem' : '3rem',
      flex: 5,
    },
    SigUpButton: {
      width: '100%',
      marginTop: screenWidth < 820 ? '20rem' : '12rem',
      flex: 5,
    },
    LoginButtonText: {
      fontWeight: 'bold',
    },
    Logo: {
      width: screenWidth < 800 ? '100%' : '60%',
      paddingTop: '2rem',
      paddingBottom: '4rem',
    },
    container: { justifyContent: 'center', display: 'flex' },
  }));
  const classes = useStyles();
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  const LoginButton = () => (
    <Button
      variant="outlined"
      className={classes.LoginButton}
      onClick={() => {
        loginWithRedirect();
      }}
      color="primary"
    >
      <div className={classes.LoginButtonText}> Have an account? Sign in </div>
    </Button>
  );
  const SignUpButton = () => (
    <Button
      variant="contained"
      className={classes.SigUpButton}
      onClick={() => {
        loginWithRedirect({ screen_hint: 'signup' });
      }}
      color="primary"
    >
      <div className={classes.LoginButtonText}> Register </div>
    </Button>
  );

  return (
    <>
      <div className={classes.container}>
        <img
          id="menu_logo"
          className={classes.Logo}
          src={logo}
          alt="img_logo"
        />
      </div>
      {!isAuthenticated && (
        <>
          <SignUpButton />
          <LoginButton />
        </>
      )}
    </>
  );
};

export default LoginPage;

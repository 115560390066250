/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import history from './utils/history';
import App from './App';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_DOMAIN,
} = process.env;

const providerConfig = {
  domain: REACT_APP_AUTH0_DOMAIN,
  clientId: REACT_APP_AUTH0_CLIENT_ID,
  ...(REACT_APP_AUTH0_AUDIENCE &&
  REACT_APP_AUTH0_AUDIENCE !== 'YOUR_API_IDENTIFIER'
    ? { audience: REACT_APP_AUTH0_AUDIENCE }
    : null),
  redirectUri: REACT_APP_DOMAIN,
  onRedirectCallback,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <Router>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById('root')
);

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import makeStyles from '@mui/styles/makeStyles';
import { useField } from 'formik';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';

export default function FormInputCheckbox(props) {
  const { name, label, disabled, helpText, fullWidth, ...rest } = props;

  const style = makeStyles(() => ({
    formControl: {
      marginTop: 10,
      marginBottom: 10,
    },
  }));

  const classes = style();
  const [field, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);

  return (
    <Grid key={label} item xs={12} style={{ marginTop: 10 }}>
      <Container>
        <FormControl
          fullWidth={fullWidth}
          className={classes.formControl}
          error={hasError}
        >
          <FormControlLabel
            label={label}
            labelPlacement="end"
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'primary checkbox' }}
                {...field}
                {...rest}
                disabled={disabled}
                checked={field.value}
              />
            }
          />

          <FormHelperText>{hasError ? meta.error : helpText}</FormHelperText>
        </FormControl>
      </Container>
    </Grid>
  );
}

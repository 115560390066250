import React from 'react';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles(({ palette }) => ({
  notificationSuccess: {
    backgroundColor: 'rgba(240, 226, 29, 0.7)',
  },
  notificationError: {
    backgroundColor: 'rgba(11, 0, 43, 0.7))',
    '& .MuiSnackbarContent-root': {
      backgroundColor: palette.action,
    },
    color: 'pink',
  },
  message: {
    color: 'secondary',
  },
}));

export default function Alert(props) {
  const classes = styles();
  const { alert, handleClose } = props;
  const { open, success, message } = alert;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      style={{ width: '100px' }}
      open={open}
      autoHideDuration={success ? 2000 : null}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      className={
        success ? classes.notificationSuccess : classes.notificationError
      }
      onClose={handleClose}
      message={
        <span id="message-id" className={classes.message}>
          <Typography color="secondary">
            <b id="alert-msg">{message}</b>
          </Typography>
        </span>
      }
      action={[
        <IconButton
          id="alert-close"
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>,
      ]}
    />
  );
}

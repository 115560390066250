/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState, useReducer } from 'react';
import useApi from '../functions/useApi';
import {
  previousScheduleDates,
  scheduleDates,
} from '../functions/dateCalculations';

import AssessmentFilterReducer from './AssessmentReducer';

export const AssessmentContext = React.createContext({});

export function useAssessmentContext() {
  return useContext(AssessmentContext);
}

const AssessmentContextProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState([]);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const [schedule, setSchedule] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [futureAssessmentDates, setFutureAssessmentDates] = useState(false);
  const [previousAssessmentDates, setPreviousAssessmentDates] = useState([]);
  const [previousAssessmentDatesObject, setPreviousAssessmentDatesObject] =
    useState({});
  const [assessmentFilter, dispatchAssessmentFilter] = useReducer(
    AssessmentFilterReducer,
    []
  );

  const { get } = useApi();

  function reload() {
    setRefresh(!refresh);
  }

  const nextAssessmentDate = schedule?.nextAssessmentDate || null;

  useEffect(async () => {
    console.info('Loading Assessment');
    setLoading(true);

    try {
      const res = await get({ url: 'form' });
      setForm(res.data || res.message);
      setAssessmentCompleted(typeof res.message === 'string');
    } catch (error) {
      console.error(error);
    }

    try {
      const res = await get({ url: 'schedule' });
      setSchedule(res.message);
      setFutureAssessmentDates(
        // eslint-disable-next-line no-nested-ternary
        res.message
          ? Date.parse(res.message.nextAssessmentDate)
            ? scheduleDates({
                currentAssessmentDate: Date.parse(
                  res.message.nextAssessmentDate
                ),
                repeat: res.message.scheduleRepeat,
                repeatType: res.message.scheduleRepeatType,
                preferredDay: res.message.schedulePreferredDay,
                weekNumber: res.message.scheduleWeekNumber,
              })
            : []
          : []
      );

      setPreviousAssessmentDates(
        previousScheduleDates({
          currentAssessmentIteration: res.message.currentAssessmentIteration,
          iterationLogs: res.iterations,
        })
      );

      setPreviousAssessmentDatesObject(
        previousScheduleDates({
          currentAssessmentIteration: res.message.currentAssessmentIteration,
          iterationLogs: res.iterations,
        })
          .map((d) => ({ [d.value]: d.label }))
          .reduce((p, c) => ({ ...p, ...c }))
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [refresh]);

  return (
    <AssessmentContext.Provider
      value={{
        form,
        loading,
        nextAssessmentDate,
        futureAssessmentDates,
        previousAssessmentDates,
        assessmentCompleted,
        reload,
        schedule,
        assessmentFilter,
        dispatchAssessmentFilter,
        previousAssessmentDatesObject,
      }}
    >
      {props.children}
    </AssessmentContext.Provider>
  );
};

export default AssessmentContextProvider;

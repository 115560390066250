import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { makeStyles } from '@mui/styles';

export default function DeleteDialog(props) {
  const { open, title, context, loading, handleClose, confirmDelete } = props;

  const styles = makeStyles(({ palette }) => ({
    dialogTitle: {
      backgroundColor: palette.primary.main,
      color: palette.primary.contrastText,
    },
  }));
  const classes = styles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogTitle className={classes.dialogTitle} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{context}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={confirmDelete}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Timeline from '@mui/lab/Timeline';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import Container from '@mui/material/Container';
import Stop from '@mui/icons-material/Stop';
import IndeterminateCheckBox from '@mui/icons-material/IndeterminateCheckBox';
import Clear from '@mui/icons-material/Clear';
import useTheme from '@mui/styles/useTheme';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormInputText from '../components/FormInputText';
import { reformatEmail, validator } from '../assets/validator';

import HelpButton from '../components/HelpButton';
import FormikForm from '../components/FormikForm';
import FormInputTextField from '../components/FormInputTextField';

import useApi from '../functions/useApi';
import LoadingBackground from '../components/LoadingBackground';
import { useCompanyContext } from '../context/CompanyContext';
import NotificationEditor from '../components/NotificationEditor';
import { useNotificationsContext } from '../context/NotificationsContext';
import {
  notificationsOrder,
  OutOfScheduleNotifications,
} from '../constants/NotificationTypes';
import EmailForm from './EmailForm';

const style = makeStyles(({ palette }) => ({
  select: { float: 'right', marginRight: 12, marginBottom: 10, height: 36.5 },
  button: { float: 'right', height: '1.1876em' },
  leftButton: {
    float: 'right',
    marginRight: 5,
    marginBottom: 16,
    height: '2rem',
  },
  title: { float: 'left', paddingTop: '2rem' },
  days: { float: 'right' },
  checkBox: { float: 'left' },
  timelinePaper: { width: '8rem', height: '6rem' },
  timelineConnector: { height: '5rem' },
  timelinePointTitle: { paddingRight: '1rem' },
  timeline: {
    '& .MuiTimelineContent-root': { flex: '0' },
  },
  accordionTitle: {
    borderTopWidth: '7px',
    borderTopStyle: 'solid',
    borderRadius: '0.2rem',
    // color: palette.primary.contrastText,
  },
  card: {
    height: '4rem',
    width: '100%',
    marginLeft: '2rem',
    marginTop: '0.5rem',
    borderTopWidth: '7px',
    borderTopStyle: 'solid',
    borderRadius: '0.2rem',
    borderColor: palette.primary.main,
  },

  descriptionInput: {
    marginLeft: '1rem',
    width: '30rem',
    marginBottom: '1rem',
  },
}));

export default function EmailEditor(props) {
  const classes = style();
  const { notifications, notificationsLoading } = useNotificationsContext();
  const cardRef = useRef();
  const testRef = useRef();

  const [selected, setSelected] = useState(0);
  const [tipSheetLoading, setTipSheetLoading] = useState(false);
  const [helpRequestLoading, setHelpRequestLoading] = useState(false);

  const theme = useTheme();
  const [manualSelected, setManualSelected] = useState(null);
  const {
    details,
    dispatchTipSheetEnabled,
    tipSheetEnabled,
    dispatchHelpRequestEnabled,
    helpRequestEnabled,
    helpRequestRecipients,
    dispatchHelpRequestRecipients,
  } = useCompanyContext();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'help-request-popover' : undefined;

  const { get, put } = useApi();

  const [descriptionInput, setDescription] = React.useState(
    'The Program Name for your employees'
  );

  const handleDescriptionChange = async (event) => {
    setDescription(event.target.value);
    try {
      const res = await put({
        url: 'updatecompany',
        body: JSON.stringify({ description: event.target.value }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailChange = async (event) => {
    const email = event.target.value.replace(/\s/g, '').toLowerCase();

    console.log(event.target.value);
    console.log();
  };

  const handleTipSheet = async () => {
    setTipSheetLoading(true);
    try {
      const result = await put({
        url: 'tip-sheet',
        body: !tipSheetEnabled,
      });
      dispatchTipSheetEnabled({
        type: 'LOAD',
        tipSheetEnabled: !tipSheetEnabled,
      });
    } catch (error) {
      console.error(error);
    }
    setTipSheetLoading(false);
  };

  /**
   * @param {Number} changeEnabledState if enabled / disable state should be toggled
   * @param {Array} recipients array of recipients
   */
  const handleHelpRequest = async ({
    changeEnabledState = false,
    recipients = false,
  }) => {
    if (changeEnabledState) setHelpRequestLoading(true);
    try {
      const result = await put({
        url: 'help-request',
        body: JSON.stringify({
          data: recipients || helpRequestRecipients,
          ...(changeEnabledState && { state: !helpRequestEnabled }),
        }),
      });
      if (changeEnabledState) {
        dispatchHelpRequestEnabled({
          type: 'LOAD',
          helpRequestEnabled: !helpRequestEnabled,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setHelpRequestLoading(false);
  };

  const [templates, setTemplates] = useState([
    {
      id: 1,
      template: '<p>Hello World</p>',
      label: 'Hello World',
      type: 'helloWorld',
      modified: '2022-04-19 12:50:36.211538',
      enabled: true,
    },
  ]);

  useEffect(async () => {
    try {
      const res = await get({ url: 'company/notification/templates' });
      setTemplates(res.message);
    } catch (error) {
      console.error(error);
    }

    setDescription(details.description);
  }, []);

  const DisabledCheck = ({ enabled }) => {
    const colorSwap = {
      color: enabled ? 'secondary' : 'primary',
    };

    const colorSwapContrast = {
      style: {
        color: theme.palette[colorSwap.color].contrastText,
      },
    };

    return (
      <SvgIcon>
        {enabled ? (
          <CheckBoxIcon {...colorSwap} />
        ) : (
          <>
            <IndeterminateCheckBox {...colorSwap} />
            <Stop {...colorSwap} />
            <Clear {...colorSwapContrast} />
          </>
        )}
      </SvgIcon>
    );
  };

  return !notificationsLoading ? (
    <>
      <Grid container alignItems="baseline" spacing={0}>
        <Grid item xs={12} lg={12}>
          <Card className={classes.card} ref={cardRef}>
            <Grid
              container
              alignItems="baseline"
              style={{ marginTop: '1rem' }}
              spacing={0}
            >
              <Grid item xs={8} lg={8}>
                <Tooltip
                  placement="right"
                  title={
                    <h2>
                      The program name that you choose will be used in all
                      employee communication eg Company ABC Wellbeing Program
                    </h2>
                  }
                >
                  <Input
                    className={classes.descriptionInput}
                    value={descriptionInput}
                    onChange={(e) => handleDescriptionChange(e)}
                  />
                </Tooltip>
              </Grid>

              {details.tipSheet && (
                <Grid item xs={2} lg={2}>
                  <Tooltip
                    placement="bottom-end"
                    title={`${
                      tipSheetEnabled ? 'Disable' : 'Enable'
                    } tip sheet`}
                  >
                    <FormControlLabel
                      className={classes.button}
                      disabled={tipSheetLoading}
                      control={
                        <Button
                          style={{ textTransform: 'none' }}
                          onClick={() => handleTipSheet()}
                          endIcon={<DisabledCheck enabled={tipSheetEnabled} />}
                          disabled={tipSheetLoading}
                        >
                          Tip Sheet
                        </Button>
                      }
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>{' '}
        <Popover
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Card>
            <CardHeader
              color="primary"
              sx={{
                color: 'primary.contrastText',
                backgroundColor: 'primary.main',
                '& .MuiCardHeader-subheader': {
                  color: 'primary.contrastText',
                },
              }}
              title="Help Request example"
              subheader="This will be displayed upon completion of assessment"
            />
            <HelpButton />
          </Card>
        </Popover>
        <Grid item xs={12} lg={12}>
          <Timeline className={classes.timeline} align="right" ref={testRef}>
            {
              <NotificationEditor
                // key={t.id}
                removeEditor
                notification={{
                  id: 669,
                  emailTemplate:
                    '<p>Hi, please remember to complete your assessment as a priority to receive your own personal Wellbeing score!</p>\n<p><a style="background-color: #142537; border: none; color: white; padding: 15px 32px; text-align: center; text-decoration: none; display: inline-block; font-size: 16px; margin: 4px 2px; cursor: pointer;" href="https://portal.apps.cernova.com">Login</a></p>\n<p>Thank you</p>',
                  label:
                    'This is a friendly sms reminder for those who haven’t yet completed in this assessment cycle',
                  subject: 'Yes',
                  type: 'intervalReminderTwo',
                  emailEnabled: true,
                  dayInterval: '0 years 0 mons 2 days 0 hours 0 mins 0.0 secs',
                  timeOfDayInterval:
                    '0 years 0 mons 0 days 7 hours 0 mins 0.0 secs',
                  smsTemplate:
                    "Hi, don't forget to complete your next assessment at portal.apps.cernova.com to receive your own personal Wellbeing results!",
                  smsEnabled: false,
                }}
                disableButtons
                selected={manualSelected}
                setSelected={setManualSelected}
                numberOfNotifications={1}
                extraSummary={
                  <>
                    <Button
                      variant="text"
                      sx={{ textTransform: 'none', color: 'text.primary' }}
                      onClick={(event) => {
                        setAnchorEl(testRef.current);
                      }}
                    >
                      <Typography> Help Request</Typography>

                      <HelpIcon
                        sx={{
                          marginLeft: '0.5rem',
                          width: '1rem',
                          height: '1rem',
                        }}
                        aria-label="delete"
                      />
                    </Button>
                  </>
                }
                belowHidden={
                  <Container sx={{ marginBottom: '3rem' }}>
                    <EmailForm
                      handleSubmit={(email) => {
                        console.log('email', email);
                        const recipients = [
                          ...helpRequestRecipients.filter((e) => e !== email),
                          email,
                        ];
                        handleHelpRequest({ recipients: recipients });
                        dispatchHelpRequestRecipients({
                          type: 'LOAD',
                          helpRequestRecipients: recipients,
                        });
                      }}
                    />

                    {helpRequestRecipients.map((r, i) => (
                      <Chip
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${r}${i}`}
                        sx={{
                          marginTop: '0.5rem',
                          marginLeft: '0.5rem',
                          marginRight: '0.5rem',
                          minHeight: '1rem',
                        }}
                        color="secondary"
                        label={r}
                        value={r}
                        onDelete={() => {
                          const recipients = helpRequestRecipients.filter(
                            (h) => h !== r
                          );
                          dispatchHelpRequestRecipients({
                            type: 'LOAD',
                            helpRequestRecipients: recipients,
                          });
                          handleHelpRequest({ recipients: recipients });
                        }}
                      />
                    ))}
                  </Container>
                }
                extraLeft={
                  <Grid item xs={6}>
                    <Tooltip
                      placement="bottom-end"
                      title={`${
                        helpRequestEnabled ? 'Disable' : 'Enable'
                      } Help Request`}
                    >
                      <FormControl className={classes.leftButton}>
                        <Button
                          onClick={() =>
                            handleHelpRequest({ changeEnabledState: true })
                          }
                          style={{ textTransform: 'none' }}
                          endIcon={
                            <Checkbox
                              className={classes.checkBox}
                              icon={
                                <DisabledCheck enabled={helpRequestEnabled} />
                              }
                              name="checkedB"
                            />
                          }
                        >
                          Email
                        </Button>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                }
              />
            }
          </Timeline>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Timeline className={classes.timeline} align="right">
            {notifications
              .filter((f) => !OutOfScheduleNotifications.includes(f.type))
              .sort(
                (a, b) =>
                  notificationsOrder.indexOf(a.type) -
                  notificationsOrder.indexOf(b.type)
              )
              .map((t, i) => (
                <NotificationEditor
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  notification={t}
                  iterator={i}
                  selected={selected}
                  setSelected={setSelected}
                  numberOfNotifications={
                    notifications.filter(
                      (o) => !OutOfScheduleNotifications.includes(o.type)
                    ).length
                  }
                />
              ))}
          </Timeline>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Card className={classes.card}>
            <Tooltip
              placement="right"
              title={
                <h2>
                  Manual Trigger reminders are used for addition unscheduled
                  reminders
                </h2>
              }
            >
              <Typography variant="h6" className={classes.descriptionInput}>
                Manual Trigger Reminders
              </Typography>
            </Tooltip>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Timeline className={classes.timeline} align="right">
            {notifications
              .filter((f) => OutOfScheduleNotifications.includes(f.type))
              .map((t, i) => (
                <NotificationEditor
                  key={t.id}
                  notification={t}
                  iterator={i}
                  selected={manualSelected}
                  setSelected={setManualSelected}
                  numberOfNotifications={
                    notifications.filter((o) =>
                      OutOfScheduleNotifications.includes(o.type)
                    ).length
                  }
                  singleNotifications
                />
              ))}
          </Timeline>
        </Grid>
      </Grid>
    </>
  ) : (
    <LoadingBackground />
  );
}

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import FormInputTextField from '../components/FormInputTextField';
import FormikForm from '../components/FormikForm';

const EmailForm = (props) => {
  const { handleSubmit } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      // handleSubmit(values.email);
      console.log(values);
      handleSubmit(values.email);
      setSubmitting(false);
      resetForm();
    },
  });

  return (
    <div style={{ marginBottom: '1rem' }}>
      <FormikForm formik={formik}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormInputTextField
              placeholder="name@example.com"
              key="email"
              id="email"
              name="email"
              fullWidth
              variant="standard"
            />
          </Grid>
          {/* submit button */}
          <Grid item xs={6}>
            <Button
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
              // lowercase text
              sx={{ textTransform: 'none' }}
              fullWidth
            >
              Add email to receive help requests
            </Button>
          </Grid>
        </Grid>
      </FormikForm>
    </div>
  );
};

export default EmailForm;

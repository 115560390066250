/* eslint-disable react/destructuring-assignment */
import React from 'react';
import CompanyContextProvider from './CompanyContext';
import AssessmentContextProvider from './AssessmentContext';
import { useAuthContext } from './AuthContext';
import { SessionUserTypes } from '../constants/UserTypes';
import TagsContextProvider from './TagsContext';

const SessionContexts = (props) => {
  const authContext = useAuthContext();

  const session = authContext.hasAnyUserType(SessionUserTypes);

  if (session) {
    return (
      <CompanyContextProvider>
        <AssessmentContextProvider>
          <TagsContextProvider>{props.children}</TagsContextProvider>
        </AssessmentContextProvider>
      </CompanyContextProvider>
    );
  }
  return props.children;
};

export default SessionContexts;

const actions = {
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  EDIT_NOTIFICATION: 'EDIT_NOTIFICATION',
};

// eslint-disable-next-line import/prefer-default-export
const NotificationsReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD_NOTIFICATIONS:
      return action.data;

    case actions.EDIT_NOTIFICATION:
      return state.map((notification) =>
        notification.id === action.notification.id
          ? { ...action.notification }
          : notification
      );

    default:
      console.log('default');
      return state;
  }
};

export default NotificationsReducer;

export const groupedWellness = (users) => {
  const wellnessArray = [
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
    { x: 10, y: 0 },
  ];

  users.map((user) => {
    const ur = user.resilience;
    if (ur) {
      if (ur <= 10 && ur < 30) {
        wellnessArray[1] += 1;
        return null;
      }
      if (ur >= 30 && ur < 45) {
        wellnessArray[3] += 1;
        return null;
      }
      if (ur >= 45 && ur < 50) {
        wellnessArray[4] += 1;
        return null;
      }
      if (ur >= 50 && ur < 55) {
        wellnessArray[5] += 1;
        return null;
      }
      if (ur >= 55 && ur < 70) {
        wellnessArray[6] += 1;
        return null;
      }
      if (ur >= 70 && ur < 90) {
        wellnessArray[7] += 1;
        return null;
      }
      if (ur >= 90 && ur <= 100) {
        wellnessArray[7] += 1;
        return null;
      }
    }
    return null;
  });
  return wellnessArray;
};

export const groupWellness = (users) => {
  if (users) {
    const resilienceResults = users.map((c) => c.resilience);
    const maxRes = Math.max(...resilienceResults);
    const minRes = Math.min(...resilienceResults);

    const wellArray = [];
    for (let i = minRes; i <= maxRes; i++) {
      const resGroup = users.filter((u) => u.resilience === i);
      if (resGroup.length > 0) {
        // extra data could be added here from users for labels
        wellArray.push({
          x: i,
          y: resGroup.length,
          r: resGroup.length,
        });
      }
    }
    return wellArray;
  }
  return null;
};

export default groupedWellness;

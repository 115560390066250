import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link, useHistory } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Search from '@mui/icons-material/Search';
import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const style = makeStyles(() => ({
  header: {
    margin: 10,
  },
  headSearch: {
    width: '100%',
    backgroundColor: alpha('rgba(228, 233, 237, 50)', 0.15),
    '&:hover': {
      backgroundColor: alpha('rgba(228, 233, 237, 1)', 0.25),
    },
    flexDirection: 'row',
  },
  input: {
    width: '50%',
  },
  divCustomer: {
    marginBottom: '0.1rem',
  },
}));

// TODO: FIX addPath!
export default function PageHeader(props) {
  const classes = style();
  const history = useHistory();
  const {
    title,
    subheader,
    setQuery,
    backPath,
    addPath,
    showSearch,
    placeholder,
  } = props;

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleBack = () => {
    if (backPath === 'back') history.goBack();
    else history.push(backPath);
  };

  return (
    <Box displayPrint="none1">
      <CardHeader
        classes={classes.CardHeader}
        title={title}
        subheader={subheader}
        avatar={
          <div>
            {backPath && (
              <Tooltip title="Back" aria-label="list">
                <IconButton
                  className={classes.IconButton}
                  aria-label="arrow_back"
                  onClick={handleBack}
                  size="large"
                >
                  <ArrowBack style={{ height: '2rem', width: '2rem' }} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      />
      {showSearch && (
        <div className={classes.headSearch}>
          <IconButton
            className={classes.iconButton}
            aria-label="Search"
            size="large"
          >
            <Search />
          </IconButton>
          <InputBase
            className={classes.input}
            onChange={handleQuery}
            placeholder={placeholder || 'Search...'}
          />
        </div>
      )}
      {addPath && (
        <div className={classes.divCustomer}>
          <Tooltip title="Create New Customer">
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<Add />}
              component={Link}
              to={addPath}
            >
              New Customer
            </Button>
          </Tooltip>
        </div>
      )}
    </Box>
  );
}

const actions = {
  LOAD_USERS: 'LOAD_USERS',
  ADD_USER: 'ADD_USER',
  REMOVE_USER: 'REMOVE_USER',
  EDIT_USER: 'EDIT_USER',
};

// eslint-disable-next-line import/prefer-default-export
const UsersReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD_USERS:
      return action.users;
    case actions.ADD_USER:
      return [...state, { ...action.user }];
    case actions.REMOVE_USER:
      return state.filter((user) => user.user_id !== action.user_id);
    case actions.EDIT_USER:
      return state.map((user) =>
        user.user_id === action.user.user_id ? { ...action.user } : user
      );
    default:
      return state;
  }
};

export default UsersReducer;

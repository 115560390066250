/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

const style = makeStyles(() => ({
  root: { justifyContent: 'center', alignItems: 'center' },
  button: { alignItems: 'center', marginRight: 5, marginTop: 15 },
}));

export default function TermsPage(props) {
  const classes = style();
  const { terms } = props;

  const [termsView, setTermsView] = useState(false);

  function handleTermsClose(event) {
    if (event) {
      setTermsView(false);
    }
  }
  function handleTermsOpen(event) {
    if (event) {
      setTermsView(true);
    }
  }

  const formattedTerms = terms.map((v, i) => (
    <Typography
      key={`T&C part ${i}`}
      style={{
        margineTop: 10,
        marginBottom: 10,
        marginLeft: 16,
        marginRight: 16,
        textAlign: 'left',
      }}
    >
      {v}
    </Typography>
  ));

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      style={{ width: '100%' }}
    >
      <br />
      {termsView === false ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleTermsOpen}
        >
          View Terms and Conditions
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleTermsClose}
          >
            Hide Terms and Conditions
          </Button>
          <Paper
            style={{ marginTop: 30, marginBottom: 30, textAlign: 'left' }}
            elevation={3}
          >
            {formattedTerms}
          </Paper>
        </>
      )}
      <br />
    </Grid>
  );
}

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default ({ render: C, props: childProps, ...rest }) => {
  const { isAuthenticated } = useAuth0();
  return (
    <Route
      {...rest}
      render={(rProps) =>
        !isAuthenticated ? (
          <C {...rProps} {...childProps} />
        ) : (
          <Redirect to={{ pathname: `/`, replace: true }} />
        )
      }
    />
  );
};

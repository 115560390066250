/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PageHeader from '../components/PageHeader';
import QuestionList from '../components/QuestionList';
import EulaPage from '../components/EulaPage';
import useApi from '../functions/useApi';
import Scheduler from '../components/Scheduler';
import QuestionCreator from './QuestionCreator';

export default function QuestionSelection(props) {
  const { get } = useApi();
  const [questions, setQuestions] = useState(null);
  const [defaultQuestions, setDefaultQuestions] = useState([]);
  const [eulaAccepted, setEulaAccepted] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { updateQuestionDate } = props;
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState('default');
  const acceptEula = ({ type }) => {
    setEulaAccepted(true);
    setSelectedType(type);
  };

  useEffect(async () => {
    setLoading(true);
    const result = await get({ url: 'questions' });
    setQuestions(result.message.filter((q) => q.enabled === true));
    setDefaultQuestions(
      result.message
        .filter((q) => q.default_question === true)
        .map((question) => question.id)
    );
    setLoading(false);
  }, []);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  return (
    <div>
      <PageHeader
        title="Wellness Question Selection:"
        subheader="Please select five questions."
        backPath="back"
      />
      <br />
      {eulaAccepted ? (
        questions &&
        (selectedType === 'c25' ? (
          <QuestionList
            questions={questions.filter(
              (q) => q.question_options_category_id === 2
            )}
            selectionAmount={25}
            defaultQuestionIds={questions
              .filter((q) => q.question_options_category_id === 2)
              .map((r) => r.id)}
            displayType="questionSelection"
            updateQuestionDate={updateQuestionDate}
          />
        ) : selectedType === 'c10' ? (
          <QuestionList
            questions={questions.filter(
              (q) =>
                q.question_options_category_id === 2 && q.sub_category_id === 9
            )}
            selectionAmount={10}
            defaultQuestionIds={questions
              .filter(
                (q) =>
                  q.question_options_category_id === 2 &&
                  q.sub_category_id === 9
              )
              .map((r) => r.id)}
            displayType="questionSelection"
            updateQuestionDate={updateQuestionDate}
          />
        ) : selectedType === 'custom' ? (
          <QuestionCreator
            defaultQuestionIds={defaultQuestions}
            defaultQuestions={questions.filter((q) =>
              defaultQuestions.includes(q.id)
            )}
            questions={questions}
          />
        ) : (
          <QuestionList
            questions={questions.filter(
              (q) => q.question_options_category_id !== 2
            )}
            defaultQuestionIds={defaultQuestions}
            displayType="questionSelection"
            updateQuestionDate={updateQuestionDate}
          />
        ))
      ) : (
        <EulaPage setLoading={loading} acceptEula={acceptEula} />
      )}
    </div>
  );
}

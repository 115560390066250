/* eslint-disable import/prefer-default-export */
import { useAuth0 } from '@auth0/auth0-react';

export default function useApi() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { REACT_APP_API_URL, REACT_APP_AUTH0_AUDIENCE } = process.env;

  async function apiGet({ url, method = 'GET', headers = {}, noAuth = false }) {
    if (isAuthenticated || noAuth) {
      try {
        const result = await fetch(`${REACT_APP_API_URL}${url}`, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            ...(!noAuth && {
              Authorization: `Bearer ${await getAccessTokenSilently({
                audience: REACT_APP_AUTH0_AUDIENCE,
              })}`,
            }),
            ...headers,
          },
        });
        return result.json();
      } catch (err) {
        return err;
      }
    }
    return { event: 'Not Authenticated' };
  }

  async function apiPost({
    url,
    method = 'POST',
    body = {},
    headers = {},
    noAuth = false,
  }) {
    if (isAuthenticated || noAuth) {
      try {
        const result = await fetch(`${REACT_APP_API_URL}${url}`, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            ...(!noAuth && {
              Authorization: `Bearer ${await getAccessTokenSilently({
                audience: REACT_APP_AUTH0_AUDIENCE,
              })}`,
            }),
            ...headers,
          },
          body: JSON.stringify(body),
        });
        return result.json();
      } catch (err) {
        return err;
      }
    }
    return { event: 'Not Authenticated' };
  }

  async function apiPut({
    url,
    method = 'PUT',
    body = {},
    headers = {},
    id = '',
    noAuth = false,
  }) {
    if (isAuthenticated || noAuth) {
      try {
        const result = await fetch(`${REACT_APP_API_URL}${url}/${id}`, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
            ...(!noAuth && {
              Authorization: `Bearer ${await getAccessTokenSilently({
                audience: REACT_APP_AUTH0_AUDIENCE,
              })}`,
            }),
            ...headers,
          },
          body: body,
        });
        return result.json();
      } catch (err) {
        return err;
      }
    }
    return { event: 'Not Authenticated' };
  }

  async function apiDelete({
    url,
    method = 'DELETE',
    headers = {},
    id,
    noAuth = false,
  }) {
    if (isAuthenticated || noAuth) {
      try {
        const result = await fetch(`${REACT_APP_API_URL}${url}/${id}`, {
          method: method,
          headers: {
            ...(!noAuth && {
              Authorization: `Bearer ${await getAccessTokenSilently({
                audience: REACT_APP_AUTH0_AUDIENCE,
              })}`,
            }),
            ...headers,
          },
        });
        return result.json();
      } catch (err) {
        return err;
      }
    }
    return { event: 'Not Authenticated' };
  }

  return { get: apiGet, post: apiPost, put: apiPut, deleteAPI: apiDelete };
}

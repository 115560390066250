const actions = {
  LOAD: 'LOAD',
  RELOAD: 'RELOAD',
};

// eslint-disable-next-line import/prefer-default-export
export const TermsReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.terms;
    default:
      return state;
  }
};
export const SmsReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.sms;
    default:
      return state;
  }
};
export const TipSheetReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.tipSheetEnabled;
    default:
      return state;
  }
};
export const HelpRequestReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.helpRequestEnabled;
    default:
      return state;
  }
};

export const helpRequestRecipientsReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.helpRequestRecipients;
    default:
      return state;
  }
};
export const TimeZoneReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.value;
    default:
      return state;
  }
};
export const DefaultTagFiltersReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.tagFilters;
    default:
      return state;
  }
};

export const LogoReducer = (state, action) => {
  switch (action.type) {
    case actions.LOAD:
      return action.logoLink;
    case actions.RELOAD:
      return `${action.logoLink}?t=${new Date().getTime()}`;
    default:
      return state;
  }
};

export default TermsReducer;

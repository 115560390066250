/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { UserTypeObject } from '../constants/UserTypes';

export const AuthContext = React.createContext({});

export function useAuthContext() {
  return useContext(AuthContext);
}

const includesAny = (list1, list2) => list1.some((x) => list2.includes(x));

const AuthContextProvider = (props) => {
  const userTypes = props.authContext?.userTypes || [];
  const hasAnyUserType = (types) => includesAny(userTypes, types);
  const logo = props.authContext?.logoLink || null;

  const hasUserType = (userType) => userTypes.includes(userType);
  const companyName = props.authContext?.companyName || null;

  const isAdmin = hasUserType(UserTypeObject.COMPANY_ADMINISTRATOR);

  return (
    <AuthContext.Provider
      value={{
        ...props.authContext,
        userTypes,
        hasUserType,
        hasAnyUserType,
        isAdmin,
        companyName,
        logo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

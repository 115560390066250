export const UserTypeObject = {
  CERNOVA: 'cernova',
  COMPANY_USER: 'company-user',
  COMPANY_ADMINISTRATOR: 'company-administrator',
};

export const SessionUserTypes = [
  UserTypeObject.CERNOVA,
  UserTypeObject.COMPANY_USER,
  UserTypeObject.COMPANY_ADMINISTRATOR,
];

export const UserTypeObjectOptions = [
  {
    name: 'Cernova',
    value: UserTypeObject.CERNOVA,
  },
  {
    name: 'Company User',
    value: UserTypeObject.COMPANY_USER,
  },
  {
    name: 'Company Administrator',
    value: UserTypeObject.COMPANY_ADMINISTRATOR,
  },
];

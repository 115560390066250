/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function Testboard(childProps) {
  const { getAccessTokenSilently, isLoading, isAuthenticated, user } =
    useAuth0();
  const [testBlob, setTestBlob] = React.useState('Hi');

  const endpoint =
    'https://gk4hu488q8.execute-api.ap-southeast-2.amazonaws.com/dev/api/';

  // GET login, users, getquestions, tags
  async function getAPI(specificEndpoint) {
    if (isAuthenticated) {
      // console.log(user);
      const token = await getAccessTokenSilently({
        audience: 'https://cernova-api-gateway.com',
      });
      // console.log(token);

      const result = await fetch(`${endpoint}${specificEndpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          setTestBlob('Boom');
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('please Log in');
      setTestBlob('please Log in');
    }
  }

  // POST addtaggroup
  // { name: 'newTagGroup' }
  // POST createtags
  // {
  //     tagGroups: ['TagA', 'TagB', 'TagC'],
  //     tags: [
  //       ['TA1', 'TA2', 'TA3'],
  //       ['TB1', 'TB2'],
  //       ['TC1', 'TC2', 'TC3', 'TC4'],
  //     ],
  //   }
  // POST addtag
  // {
  //   name: 'Tag22',
  //   tagGroupId: 2
  // }
  // POST createcompany
  // {
  //   companyName: 'test1',
  //   description: 'beebob',
  //   address: 'bobobob',
  //   industry: 'pow',
  //   adminName: 'MEMEMEMEME',
  //   email: 'me@mine.pro',
  // }
  // POST users
  // [
  //   {
  //     "name": "jim bob",
  //     "email": "jb@gmail.com",
  //     "phoneNumber": "61438961747",
  //     "startDate": "19/09/2012",
  //     "role": "role1",
  //     "businessUnit": "teamA",
  //     "department": "depC",
  //     "geography": "geoB",
  //     "project": "server",
  //     "tg": [
  //         {
  //             "name": "role",
  //             "tag": "role1"
  //         },
  //         {
  //             "name": "businessUnit",
  //             "tag": "teamA"
  //         },
  //         {
  //             "name": "department",
  //             "tag": "depC"
  //         },
  //         {
  //             "name": "geography",
  //             "tag": "geoB"
  //         },
  //         {
  //             "name": "project",
  //             "tag": "server"
  //         }
  //     ]
  // },{...}]
  async function postAPI(specificEndpoint, bodyData = {}) {
    if (isAuthenticated) {
      console.log(user);
      const token = await getAccessTokenSilently({
        audience: 'https://cernova-api-gateway.com',
      });
      // console.log(token);

      const result = await fetch(`${endpoint}${specificEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('please Log in');
    }
  }

  // PUT updateCompany
  // {
  //   name: 'Cloud Pro',
  //   address: 'Up, way Up',
  //   description: 'Floating above the rest',
  //   industry: 'rain maker',
  //   logo_link: null,
  //   theme_primary: '#328da8',
  //   theme_secondary: '#ffff00',
  // }
  // PUT taggroup/{id}
  // { name: 'newName' }
  // PUT tag/{id}
  // { name: 'newName' }
  async function putAPI(specificEndpoint, bodyData = {}) {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        audience: 'https://cernova-api-gateway.com',
      });
      const result = await fetch(`${endpoint}${specificEndpoint}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log('E', error);
        });
    } else {
      console.log('please Log in');
    }
  }

  // DELETE tag/{id}
  // DELETE taggroup/{id}
  // DELETE user/{id}
  async function deleteAPI(specificEndpoint, id) {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently({
        audience: 'https://cernova-api-gateway.com',
      });
      console.log(`${endpoint}${specificEndpoint}/${id}`);
      const result = await fetch(`${endpoint}${specificEndpoint}/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const newdata = data.message;
          console.log(newdata);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log('please Log in');
    }
  }

  const records = [
    {
      is_admin: false,
      user_id: 60,
      company_id: 35,
      name: 'Eva Kiss',
      email: 'eva@gmail.com',
      phone_number: '0452406767',
      start_date: '23/5/2010',
      last_seen: null,
      resilience: null,
      safety: null,
      avg_resilience: null,
      avg_safety: null,
      tests_completed: 0,
      last_test_date: null,
      tags: '[{"tag_group":"Project","tag":"Project One"},{"tag_group":"Geography","tag":"Geo One"},{"tag_group":"Business Unit","tag":"Business Unit Two"},{"tag_group":"Role","tag":"Role One"},{"tag_group":"Department","tag":"Dept Two"}]',
    },
    {
      is_admin: false,
      user_id: 57,
      company_id: 35,
      name: 'Joe Blogs1',
      email: 'joeblogs1@example.com',
      phone_number: '61404040411',
      start_date: '24/05/18',
      last_seen: null,
      resilience: null,
      safety: null,
      avg_resilience: null,
      avg_safety: null,
      tests_completed: 0,
      last_test_date: null,
      tags: '[{"tag_group":"Project","tag":"Project One"},{"tag_group":"Geography","tag":"Geo One"},{"tag_group":"Business Unit","tag":"Business Unit Two"},{"tag_group":"Role","tag":"Role One"},{"tag_group":"Department","tag":"Dept One"}]',
    },
    {
      is_admin: true,
      user_id: 35,
      company_id: 35,
      name: 'MEMEMEMEME',
      email: 'me@mine.pro',
      phone_number: null,
      start_date: null,
      last_seen: null,
      resilience: null,
      safety: null,
      avg_resilience: null,
      avg_safety: null,
      tests_completed: 0,
      last_test_date: null,
      tags: null,
    },
    {
      is_admin: false,
      user_id: 56,
      company_id: 35,
      name: 'Joe Blogs3',
      email: 'joeblogs3@example.com',
      phone_number: '61404040433',
      start_date: '24/05/18',
      last_seen: null,
      resilience: null,
      safety: null,
      avg_resilience: null,
      avg_safety: null,
      tests_completed: 0,
      last_test_date: null,
      tags: '[{"tag_group":"Project","tag":"Project One"},{"tag_group":"Geography","tag":"Geo Two"},{"tag_group":"Business Unit","tag":"Business Unit Six"},{"tag_group":"Role","tag":"Role One"},{"tag_group":"Department","tag":"Dept One"}]',
    },
    {
      is_admin: false,
      user_id: 58,
      company_id: 35,
      name: 'Joe Blogs2',
      email: 'joeblogs2@example.com',
      phone_number: '61404040422',
      start_date: '24/05/18',
      last_seen: null,
      resilience: null,
      safety: null,
      avg_resilience: null,
      avg_safety: null,
      tests_completed: 0,
      last_test_date: null,
      tags: '[{"tag_group":"Project","tag":"Project Two"},{"tag_group":"Geography","tag":"Geo One"},{"tag_group":"Business Unit","tag":"Business Unit One"},{"tag_group":"Role","tag":"Role Two"},{"tag_group":"Department","tag":"Dept Two"}]',
    },
    {
      is_admin: true,
      user_id: 34,
      company_id: 35,
      name: 'Skip Xtian',
      email: 'skip.xtian@gmail.com',
      phone_number: null,
      start_date: null,
      last_seen: '2021-08-09 01:16:33.341365',
      resilience: 76,
      safety: 37,
      avg_resilience: '66.00',
      avg_safety: '34.50',
      tests_completed: 2,
      last_test_date: '2021-08-04 21:26:05.547248',
      tags: '[{"tag_group":"TagB","tag":"TB1"},{"tag_group":"TagC","tag":"TC3"}]',
    },
  ];

  const convertArrayToObject = (array, key, value) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      console.log();
      return {
        ...obj,
        [item[key]]: item[value],
      };
    }, initialValue);
  };

  function doShit() {
    console.log(records);
    const trimmed = records.map((record) => {
      const newRec = record;
      if (true) {
        delete newRec.resilience;
        delete newRec.avg_resilience;
      }
      return newRec;
    });
    console.log(trimmed);
  }

  return (
    <div>
      <Button
        onClick={() => {
          console.log(childProps);
        }}
      >
        omfg
      </Button>
      <Button
        onClick={() => {
          doShit();
        }}
      >
        do shit
      </Button>
      <Button
        onClick={() => {
          getAPI('employee/34');
        }}
      >
        Get Employee
      </Button>
      <Button
        onClick={() => {
          getAPI('tags');
        }}
      >
        Get Tags
      </Button>
      <Button
        onClick={() => {
          postAPI('addtag', { name: 'Tag22', tagGroupId: 2 });
        }}
      >
        Add Tag
      </Button>
      <Button
        onClick={() => {
          postAPI('addtaggroup', { name: 'Taggroup22' });
        }}
      >
        Add Tag Group
      </Button>
      <Button
        onClick={() => {
          putAPI('tag/2', { name: 'Tag23' });
        }}
      >
        update Tag
      </Button>
      <Button
        onClick={() => {
          putAPI('taggroup/4', { name: 'Taggroup22' });
        }}
      >
        update Tag Group
      </Button>

      <Button
        onClick={() => {
          putAPI('question/1', { enable: true });
        }}
      >
        modify Question
      </Button>
      <Button
        onClick={() => {
          deleteAPI('tag', 9);
        }}
      >
        delete Tag
      </Button>
      <Button
        onClick={() => {
          deleteAPI('taggroup', 4);
        }}
      >
        delete Tag Group
      </Button>
      <Button
        onClick={() => {
          postAPI('createtags', {
            tagGroups: ['TagA', 'TagB', 'TagC'],
            tags: [
              ['TA1', 'TA2', 'TA3'],
              ['TB1', 'TB2'],
              ['TC1', 'TC2', 'TC3', 'TC4'],
            ],
          });
        }}
      >
        Create TAGS
      </Button>
      <Button
        onClick={() => {
          getAPI('users');
        }}
      >
        Get Users
      </Button>
      <Button
        onClick={() => {
          getAPI('company/5');
        }}
      >
        Get Company
      </Button>
      <Button
        onClick={() => {
          getAPI('form');
        }}
      >
        Get Form
      </Button>

      <Button
        onClick={() => {
          postAPI('createcompany', {
            companyName: 'test1',
            description: 'beebob',
            address: 'bobobob',
            industry: 'pow',
            adminName: 'MEMEMEMEME',
            email: 'me@mine.pro',
          });
        }}
      >
        Create Company
      </Button>
      <Button
        onClick={() => {
          putAPI('updatecompany', {
            name: 'Cloud Pro',
            address: 'Up, way Up',
            description: 'Floating above the rest',
            industry: 'rain maker',
            logo_link: null,
            theme_primary: '#328da8',
            theme_secondary: '#ffff00',
          });
        }}
      >
        Update Company
      </Button>
      <Button
        onClick={() => {
          deleteAPI('user', 4);
        }}
      >
        deleteUser
      </Button>
      <Button
        onClick={() => {
          getAPI('questions');
        }}
      >
        get Questions
      </Button>
      <Button
        onClick={() => {
          getAPI('login');
        }}
      >
        Login
      </Button>
      <Button
        onClick={() => {
          getAPI('company/all');
        }}
      >
        Get All Companies
      </Button>
      <Typography>{testBlob}</Typography>
    </div>
  );
}

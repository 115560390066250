import React, {
  useState,
  useEffect,
  createContext,
  useReducer,
  useContext,
} from 'react';
import useApi from '../functions/useApi';
import TagsReducer from './TagsReducer';
import { useAuthContext } from './AuthContext';

export const TagsContext = createContext();

export function useTagsContext() {
  return useContext(TagsContext);
}
const TagsContextProvider = (props) => {
  const [loading, setLoading] = useState(false);
  const [tags, dispatchTags] = useReducer(TagsReducer, []);
  const [refresh, setRefresh] = useState(false);
  const { isAdmin } = useAuthContext();

  const { get } = useApi();

  function reload() {
    setRefresh(!refresh);
  }

  useEffect(async () => {
    if (isAdmin) {
      console.info('Loading Tags');
      setLoading(true);

      try {
        const res = await get({ url: 'tags' });
        dispatchTags({
          type: 'LOAD',
          tags: res.message
            .filter((t) => t.tag_group_id !== null)
            .map((i) => ({ ...i, ...{ options: JSON.parse(i.options) } })),
        });
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  }, [refresh]);

  return (
    <TagsContext.Provider value={{ tags, dispatchTags, reload }}>
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </TagsContext.Provider>
  );
};

export default TagsContextProvider;

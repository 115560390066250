import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';
import useApi from '../functions/useApi';
import { analyticsPage } from '../constants/routeWithParams';

const style = makeStyles(() => ({
  actionIcon: {
    marginLeft: 25,
  },
  root: {
    height: '100%',
  },
  button: { float: 'right', marginRight: 5, marginBottom: '3rem' },
  listSubheader: { color: red },
}));

export default function QuestionList(props) {
  const classes = style();
  const {
    questions,
    defaultQuestionIds,
    displayType,
    updateQuestionDate,
    selectionAmount = 5,
  } = props;
  const [checked, setChecked] = useState(new Set(defaultQuestionIds));
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(false);
  const [formSet, setFormSet] = useState(false);
  const categories = [];
  const [page, setPage] = useState(0);
  const { put } = useApi();
  const history = useHistory();

  questions.forEach((element) => {
    if (!categories.includes(element.name)) {
      categories.push(element.name);
    }
  });

  const category = categories[page];
  const displayQuestions = questions.filter((q) => q.name === category);
  const selectedQuestions = questions.filter((q) => checked.has(q.id));
  const numberSelectedForThisCategory = [...checked].reduce(
    (acc, id) => (displayQuestions.find((q) => q.id === id) ? acc + 1 : acc),
    0
  );

  const handleChange = (id) => {
    const checkedUuids = new Set(checked);
    if (checked.has(id)) {
      checkedUuids.delete(id);
    } else {
      checkedUuids.add(id);
    }
    setChecked(checkedUuids);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleReviewClick = () => {
    setReview(true);
  };

  const handleClose = (data) => {
    // if (data) {
    //   const submitData = data;
    //   console.log(submitData);
    //   setPage(0);
    //   setChecked(new Set());
    //   setOpen(false);
    // } else {
    //   setOpen(false);
    // }
    if (formSet) {
      history.push(analyticsPage);
    } else if (!isSubmitting) {
      setOpen(false);
    }
  };

  const setForm = async (data) => {
    setIsSubmitting(true);
    try {
      const body = { questionIds: [...data] };

      const result = await put({ url: 'form', body: JSON.stringify(body) });
      if (result) {
        setFormSet(true);
        setSubmissionError(false);
        updateQuestionDate();
      }
    } catch (error) {
      setSubmissionError(true);
    }
    setIsSubmitting(false);
  };

  if (displayType === 'questionSelection') {
    return (
      <div>
        {review === true ? (
          <div>
            <Typography variant="h5" style={{ marginLeft: 15 }}>
              Selected Questions
            </Typography>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                '& ul': { padding: 0 },
              }}
              subheader={<li />}
            >
              {categories.map((categoryName) => (
                <li key={`section-${categoryName}`}>
                  <ul>
                    <ListItem style={{ fontWeight: 'bold' }}>
                      {`${categoryName}`}{' '}
                    </ListItem>
                    {selectedQuestions.map((q) =>
                      q.name === categoryName ? (
                        <ListItem
                          key={`category-${categoryName}-${q.question}`}
                        >
                          <ListItemText primary={`${q.question}`} />
                        </ListItem>
                      ) : null
                    )}
                  </ul>
                </li>
              ))}
            </List>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirm question selections
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Please confirm that you are happy with the question
                  selections, as they will be locked in for 6 months.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {submissionError && (
                  <Alert severity="error">
                    There was a problem setting questions please try again
                  </Alert>
                )}
                {!isSubmitting && !formSet && (
                  <>
                    <Button
                      onClick={handleClose}
                      color="primary"
                      variant="contained"
                    >
                      Disagree
                    </Button>
                    <Button
                      onClick={() => setForm(checked)}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      Agree
                    </Button>
                  </>
                )}

                {isSubmitting && <CircularProgress />}
                {formSet && (
                  <>
                    <Alert severity="success">
                      Question selection successful
                    </Alert>
                    <Button
                      onClick={() => history.push(analyticsPage)}
                      color="primary"
                      variant="contained"
                      autoFocus
                    >
                      Continue
                    </Button>
                  </>
                )}
              </DialogActions>
            </Dialog>
            <Tooltip title="Submit Questions">
              <Button
                onClick={() => handleClickOpen(checked)}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Submit Questions
              </Button>
            </Tooltip>
            <Tooltip title="Previous Page">
              <Button
                onClick={() =>
                  setReview(false) && setPage(categories.length - 1)
                }
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Back
              </Button>
            </Tooltip>
          </div>
        ) : (
          <div>
            <div>
              <Typography variant="h5" style={{ marginLeft: 15 }}>
                {category}
              </Typography>
              <List className={classes.root}>
                {displayQuestions.map((question, index) => (
                  <React.Fragment key={question.id}>
                    <ListItem
                      button
                      alignItems="flex-start"
                      onClick={() => handleChange(question.id)}
                      key={question}
                      disabled={
                        numberSelectedForThisCategory === selectionAmount &&
                        !checked.has(question.id)
                      }
                    >
                      <ListItemText primary={`${question.question}`} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          checked={checked.has(question.id)}
                          onChange={() => handleChange(question.id)}
                          disabled={
                            numberSelectedForThisCategory === selectionAmount &&
                            !checked.has(question.id)
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </div>
            {page === categories.length - 1 && (
              <Tooltip title="Review Selected Questions">
                <Button
                  onClick={() => handleReviewClick(checked)}
                  disabled={numberSelectedForThisCategory !== selectionAmount}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {numberSelectedForThisCategory !== selectionAmount
                    ? `${numberSelectedForThisCategory} / ${selectionAmount}`
                    : 'Review Selection'}
                </Button>
              </Tooltip>
            )}
            {page < categories.length - 1 && (
              <Tooltip title="Next Page">
                <Button
                  onClick={() => setPage(page + 1)}
                  disabled={numberSelectedForThisCategory !== selectionAmount}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {numberSelectedForThisCategory !== selectionAmount
                    ? `${numberSelectedForThisCategory} / ${selectionAmount}`
                    : 'next'}
                </Button>
              </Tooltip>
            )}

            {page > 0 && (
              <Tooltip title="Previous Page">
                <Button
                  onClick={() => setPage(page - 1)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Back
                </Button>
              </Tooltip>
            )}

            <br />
            <br />
            <p> </p>
          </div>
        )}
      </div>
    );
  }
  if (displayType === 'questionBuilder') {
    return (
      <div>
        <Typography variant="h5" style={{ marginLeft: 15 }}>
          {category}
        </Typography>
        <List className={classes.root}>
          {displayQuestions.map((question, index) => (
            <React.Fragment key={() => question.concat(index, 'builder')}>
              <ListItem
                button
                alignItems="flex-start"
                onClick={() => handleChange(question.id)}
                key={question}
                disabled={
                  numberSelectedForThisCategory === selectionAmount &&
                  !checked.has(question.id)
                }
              >
                <ListItemText primary={`${question.question}`} />
                <ListItemSecondaryAction>
                  <Checkbox
                    checked={checked.has(question.id)}
                    onChange={() => handleChange(question.id)}
                    disabled={
                      numberSelectedForThisCategory === selectionAmount &&
                      !checked.has(question.id)
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        {categories.map((c, index) => (
          <Tooltip title="Next Page">
            <Button
              onClick={() => setPage([index])}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {c}
            </Button>
          </Tooltip>
        ))}
      </div>
    );
  }
}

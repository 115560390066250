export const twoArraysEqual = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export const sliceIntoChunks = function (arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const reduceArrayOfObjects = (array) =>
  // combine objects in array
  // watch out for conflicting object key ids

  // input
  // const array = [
  //   { fruit: ['cherry', 'grape'] },
  //   { vegetable: ['carrot', 'potato'] },
  // ];

  // output
  // const object = {
  //   fruit: ['cherry', 'grape'],
  //   vegetable: ['carrot', 'potato'],
  // };

  array.reduce((res, cur) => Object.assign(res, cur));

export default twoArraysEqual;

export const truncate = (str, n) =>
  str.length > n ? `${str.substr(0, n - 1)}...` : str;

/**
 * @description - sequence/range generator
 * @param {Object} start number array should start at
 * @param {Array} stop - number array should stop when it reaches this number
 * @param {string} step - number to increment by (default is 1)
 */
export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useApi from '../functions/useApi';
import { analyticsPage } from '../constants/routeWithParams';
import FormikForm from '../components/FormikForm';
import TextInput from '../components/FormInputText';
import { useAssessmentContext } from '../context/AssessmentContext';
import { useAlertContext } from '../context/AlertContext';

const style = makeStyles(() => ({
  actionIcon: {
    marginLeft: 25,
  },
  root: {
    height: '100%',
  },
  button: { float: 'right', marginRight: 5, marginBottom: '3rem' },
  listSubheader: { color: red },
}));

const displayQuestions = [...Array(16)];

export default function QuestionCreator({
  defaultQuestionIds,
  questions,
  defaultQuestions,
}) {
  const classes = style();
  const { get, post, put } = useApi();
  const { reload } = useAssessmentContext();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const alertContext = useAlertContext();

  const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const formikSetupObject = (value, arrayOfValues) =>
    keys.reduce((res, cur, index) => {
      // eslint-disable-next-line no-param-reassign
      if (arrayOfValues) {
        res[cur] = arrayOfValues[index] || '';
      } else res[cur] = value || '';
      return res;
    }, {});

  const formik = useFormik({
    initialValues: formikSetupObject(
      '',
      defaultQuestions.map((q) => q.question)
    ),
    validationSchema: Yup.object().shape(
      formikSetupObject(Yup.string().required())
    ),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      console.log(values);

      try {
        const res = await post({
          url: 'questions',
          body: values,
        });

        console.log(res);

        if (res.ids) {
          try {
            const body = { questionIds: [...res.ids] };

            const result = await put({
              url: 'form',
              body: JSON.stringify(body),
            });
            console.log(result);
            reload();
            history.push(analyticsPage);
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleClose = (data) => {
    setOpen(false);
  };

  return (
    <div>
      <div>
        <div>
          <Typography variant="h5" style={{ marginLeft: 15 }}>
            Custom Questions
          </Typography>

          <FormikForm formik={formik}>
            <Grid container>
              {keys.map((question, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={12}>
                  <TextInput
                    key={question}
                    id={question}
                    name={question.toString()}
                    placeholder="Enter new question"
                    fullWidth
                    prefix={`${question}:`}
                  />
                </Grid>
              ))}

              <Grid
                item
                xs={12}
                style={{ paddingTop: '1rem', paddingBottom: '2rem' }}
              >
                <Button
                  color="primary"
                  autoFocus
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    formik.validateForm().then((e) => {
                      if (Object.keys(e).length === 0) {
                        setOpen(true);
                      } else {
                        console.log(e);
                        formik.setTouched(formikSetupObject(true));
                        alertContext.error(
                          e,
                          `Questions ${Object.keys(e)} are invalid`
                        );
                      }
                    });
                  }}
                  variant="contained"
                  fullWidth
                >
                  Submit Questions
                </Button>
              </Grid>
            </Grid>
          </FormikForm>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Confirm question selections
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please confirm that you are happy with the question selections,
                as they will be locked in for 6 months.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <>
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  Disagree
                </Button>
                <Button
                  onClick={() => {
                    formik.submitForm();
                  }}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Agree
                </Button>
              </>

              {formik.isSubmitting && <CircularProgress />}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

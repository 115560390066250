/* eslint-disable react/no-array-index-key */
import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';
import { Edit, Delete } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import useApi from '../functions/useApi';

const style = makeStyles((theme) => ({
  actionIcon: {
    marginRight: 25,
  },
  root: {
    height: '100%',
  },
  cellError: {
    backgroundColor: theme.palette.error.main,
  },
}));

export default function UploadTable(props) {
  const classes = style();
  const { users, disableActions, dataErrors, tags } = props;
  // console.log('TAGS', tags);
  const [open, setOpen] = React.useState(false);
  const [userForDeletion, setUserForDeletion] = React.useState([]);
  const { deleteAPI } = useApi();

  /* eslint-disable no-nested-ternary */
  const columns = users.length
    ? disableActions
      ? Object.keys(users[0]).map((key) => ({ id: key, label: key }))
      : [
          ...Object.keys(users[0]).map((key) => ({ id: key, label: key })),
          // { id: 'edit', label: 'edit', align: 'center' },
          // { id: 'disable', label: 'disable', align: 'center' },
        ]
    : [];
  /* eslint-enable no-nested-ternary */

  // const columns = [
  //   { id: 'name', label: 'Name' },
  //   { id: 'email', label: 'Email' },
  //   { id: 'phoneNumber', label: 'Phone Number' },
  //   { id: 'role', label: 'Role' },
  //   { id: 'businessUnit', label: 'Business Unit' },
  //   { id: 'department', label: 'Department' },
  //   { id: 'geography', label: 'Geography' },
  //   { id: 'project', label: 'Project' },
  //   { id: 'edit', label: '' },
  // ].filter((c) => Object.keys(users[0]).includes(c.id));

  const handleClickOpen = (user) => {
    setOpen(true);
    setUserForDeletion(user);
  };

  async function handleClose(user) {
    if (user) {
      const submitData = {
        id: user.user_id,
        name: user.name,
      };
      console.log('DELETE', user.user_id);
      // deleteAPI('user', 4);
      const gotTags = await deleteAPI({ url: 'user', id: user.user_id });
      console.log(gotTags);
      setOpen(false);
    } else {
      setOpen(false);
    }
  }

  return (
    <div>
      {disableActions ? (
        <Typography> Do you wish to enroll the following users?</Typography>
      ) : null}
      {users.length ? (
        <TableContainer className={classes.root}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[...columns].map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {!disableActions && <TableCell key="edit" align="center" />}
                {!disableActions && <TableCell key="delete" align="center" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <>
                  <TableRow key={index}>
                    {columns.map((column, colIndex) => {
                      const cellErrors = dataErrors
                        ? dataErrors[`[${index}].${column.id}`]
                        : null;
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          className={cellErrors ? classes.cellError : null}
                          key={index + colIndex}
                        >
                          {user[column.id]} {cellErrors}
                        </TableCell>
                      );
                    })}
                    {!disableActions ? (
                      <>
                        <TableCell component="th" scope="row" padding="normal">
                          <Tooltip title="Delete User">
                            <IconButton
                              edge="end"
                              aria-label="View"
                              color="primary"
                              onClick={() => handleClickOpen(user)}
                              size="large"
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </>
                    ) : null}
                  </TableRow>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Are you sure you wish to delete {userForDeletion.name}?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Please confirm that you wish to delete{' '}
                        {userForDeletion.name}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Disagree
                      </Button>
                      <Button
                        onClick={() => handleClose(userForDeletion)}
                        color="primary"
                        autoFocus
                      >
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No Available Records</Typography>
      )}
    </div>
  );
}

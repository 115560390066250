/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import PageHeader from '../components/PageHeader';
import TagTable from '../components/csvtables/TagTable';
import UploadTable from '../components/UploadTable';
import DateFormatDialog from '../components/DateFormatDialog';
import LoadingBackground from '../components/LoadingBackground';
import {
  CSVUploadSchema,
  reformatPhoneNumber,
  reformatEmail,
  reformatFirstLastNameTogether,
  reformatName,
  reformatStartDate,
} from '../assets/validator';
import { analyticsPageWithReload } from '../constants/routeWithParams';
import useApi from '../functions/useApi';
import { useAlertContext } from '../context/AlertContext';
import { useUsersContext } from '../context/UsersContext';
import { useTagsContext } from '../context/TagsContext';
import {
  firstNameColumn,
  lastNameColumn,
  startDateColumn,
  phoneNumberColumn,
  nameColumn,
  emailColumn,
} from '../constants/columnNames';
import { sliceIntoChunks } from '../functions/helpers';

function CSV() {
  const { post } = useApi();
  const [data, setData] = useState([]);
  const [csvColumnHeaders, setCsvColumnHeaders] = useState([]);
  const [happyWithData, setHappyWithData] = useState(false);
  const [dataValid, setDataValid] = useState(true);
  const [validationError, setValidationError] = useState([]);
  const [tagData, setTagData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDateFormatDialog, setOpenDateFormatDialog] = useState(false);
  const history = useHistory();
  const alertContext = useAlertContext();
  const [loadingFile, setLoadingFile] = useState(false);
  const [dateFormat, setDateFormat] = useState({
    dateString: '',
    separator: '',
  });

  const validateData = (data) => {
    const schema = Yup.array().of(CSVUploadSchema);

    schema
      .validate(data, { abortEarly: false })
      .then((value) => {
        // console.log(value);
      })
      .catch((err) => {
        let errors = {};
        err.inner.forEach((e) => {
          if (errors[e.path]) {
            errors[e.path].push(e.message);
          } else {
            errors[e.path] = [e.message];
          }
        });
        setValidationError(errors);
        // console.log(errors);
        setDataValid(false);
      });
  };

  // handle file download
  const downloadCSV = () => {
    const data = [
      {
        Name: 'Joe Blogs',
        Email: 'joeblogs@example.com',
        'Phone Number': '+61404040404',
        'Start Date': '21/2/2021',
        Role: 'Role One',
        'Business Unit': 'Business Unit One',
        Department: 'Dept One',
        Geography: 'Geo One',
        Project: 'Project One',
      },
    ];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(wb, ws, 'template');
    XLSX.writeFile(wb, 'template.csv');
  };

  // Column names that should not be created as tags
  const generalColumnNames = (key) => {
    const LowerCaseKey = key.toLowerCase();
    if (
      LowerCaseKey === 'name' ||
      LowerCaseKey === 'first name' ||
      LowerCaseKey === 'last name' ||
      LowerCaseKey === 'email' ||
      LowerCaseKey === 'phone number' ||
      LowerCaseKey === 'start date' ||
      LowerCaseKey === 'tg'
    ) {
      return true;
    }
    return false;
  };

  function grabTags(uploadedData, newTagGroups) {
    let tempTagData = {};
    const tagGroups = newTagGroups || [];

    const tags = [];
    let rowMax = 0;
    tagGroups.forEach((tagName, index) => {
      tags.push([]);
      uploadedData.map((userd) => {
        Object.keys(userd).forEach((key) => {
          if (key === tagName) {
            let unique = true;
            tags[index].forEach((name) => {
              if (name === userd[key]) unique = false;
            });
            if (unique) tags[index].push(userd[key]);
          }
        });
        return true;
      });
    });
    tempTagData.tagGroups = tagGroups;
    tempTagData.tags = tags;
    tempTagData.rows = [];

    tags.forEach((tag) => {
      if (tag.length > rowMax) rowMax = tag.length;
    });

    for (let index = 0; index < rowMax; index++) {
      tempTagData.rows.push(index);
    }
    setTagData(tempTagData);
  }

  const manipulateUserData = (data, headers) => {
    const dateFormatArray = dateFormat.dateString.toLowerCase().split(']');
    const dateOrder = {
      year: dateFormatArray.indexOf('year'),
      day: dateFormatArray.indexOf('day'),
      month: dateFormatArray.indexOf('month'),
    };

    // TODO check for a better way then if last name exists

    const manipulatedData = data.map((u) => {
      const t = {
        ...u,
        // combines first and last name if in separate columns
        ...(headers.includes(firstNameColumn.mainName) && {
          [nameColumn.mainName]: reformatFirstLastNameTogether(
            u[firstNameColumn.mainName],
            u[lastNameColumn.mainName]
          ),
        }),
        ...(headers.includes(nameColumn.mainName) && {
          [nameColumn.mainName]: reformatName(u[nameColumn.mainName]),
        }),
        ...(u[phoneNumberColumn.mainName] && {
          [phoneNumberColumn.mainName]: u[phoneNumberColumn.mainName],
        }),
        ...(u[emailColumn.mainName] && {
          [emailColumn.mainName]: reformatEmail(u[emailColumn.mainName]),
        }),
        ...(u[startDateColumn.mainName] && {
          [startDateColumn.mainName]: reformatStartDate(
            u[startDateColumn.mainName],
            dateFormat.separator,
            dateOrder
          ),
        }),
      };

      delete t.firstName;
      delete t.lastName;

      return t;
    });

    setData(manipulatedData);
    validateData(manipulatedData);
  };

  useEffect(() => {
    setLoadingFile(false);
  }, [tagData]);

  // handle file upload
  const handleFileUpload = (e) => {
    setLoadingFile(true);
    setOpenDateFormatDialog(false);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
        dateNF: 'dd/mm/yyyy;@',
        cellDates: true,
        raw: true,
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      /* Convert column rows array of arrays, first row is header */
      let uploadedColumnHeader = XLSX.utils.sheet_to_json(ws, {
        header: 1,
      })[0];

      const newTagGroups = [];

      uploadedColumnHeader = uploadedColumnHeader.map((h) => {
        // replace all white space change all to lower space
        const modded = h.replace(/ /g, '').toLowerCase();

        if (firstNameColumn.otherNames.includes(modded))
          return firstNameColumn.mainName;
        if (lastNameColumn.otherNames.includes(modded))
          return lastNameColumn.mainName;
        if (startDateColumn.otherNames.includes(modded))
          return startDateColumn.mainName;
        if (phoneNumberColumn.otherNames.includes(modded))
          return phoneNumberColumn.mainName;
        if (emailColumn.otherNames.includes(modded)) {
          return emailColumn.mainName;
        }
        if (nameColumn.otherNames.includes(modded)) {
          return nameColumn.mainName;
        }
        if (modded === 'user_disabled') {
          return 'user_disabled';
        }
        const trimmedH = h.trim();
        newTagGroups.push(trimmedH);
        return trimmedH;
      });

      console.log('newTagswhen', newTagGroups);

      // let isFounded = uploadedColumnHeader.some((hn) =>
      //   firstNameColumn.includes(hn)
      // );

      /* Convert column rows array of arrays */
      const uploadedData = XLSX.utils
        .sheet_to_json(ws, {
          header: uploadedColumnHeader,
        })
        .slice(1);

      manipulateUserData(uploadedData, uploadedColumnHeader);

      grabTags(uploadedData, newTagGroups);

      setCsvColumnHeaders(uploadedColumnHeader);
    };
    reader.readAsBinaryString(file);
  };

  // TODO: What does fileRef do???
  // const fileRef = useRef();

  const clearDataAndFile = () => {
    // fileRef.current.value = null;
    setData([]);
    setTagData();
    setHappyWithData(false);
    setValidationError([]);
    setDataValid(true);
  };

  async function handleSubmit(_data) {
    // console.log(data);
    const userUpload = _data.map((userd) => {
      const tg = [];
      const userg = userd;
      Object.keys(userd).forEach((key) => {
        if (
          key === 'Name' ||
          key === 'Email' ||
          key === 'Phone Number' ||
          key === 'Start Date' ||
          key === 'tg'
        ) {
          // console.log('rejected');
        } else {
          const group = {};
          group.name = key;
          group.tag = userd[key];
          tg.push(group);
        }
      });
      userg.tg = tg;
      return userg;
    });

    const mappedUsers = userUpload.map((user) => {
      const mappedUser = {
        name: user.Name,
        email: user.Email,
        phoneNumber: reformatPhoneNumber(user['Phone Number']),
        startDate: user['Start Date'],
        tg: user.tg,
        userDisabled: user.user_disabled,
      };
      return mappedUser;
    });
    console.log(mappedUsers);
    // TODO: Add loading and error handling
    const result = await post({ url: 'users', body: mappedUsers });
    if (result.errors.length > 0) {
      alertContext.error(result, [
        ...result.errors.map((err) => <p>{err.name}</p>),
        ...[<div>FAILED to be created</div>],
      ]);
    }
    if (result.createdUsers.length > 0) {
      alertContext.success([
        ...result.createdUsers.map((u) => <p>{u.name}</p>),
        ...[<div>Created</div>],
      ]);
    }
    return result;
  }

  async function submitTagsData(_tagData) {
    console.log(_tagData);
    if (dataValid) {
      const result = await post({ url: 'createtags', body: _tagData });
      console.log(result);
      setHappyWithData(true);
    } else {
      setHappyWithData(true);
    }
    // TODO: Add loading and error handling
  }

  async function handleSubmitWithTags(_tagData, _data) {
    setLoading(true);
    await submitTagsData(_tagData);

    const userBlocks = sliceIntoChunks(_data, 200);

    console.time('upload');

    for (let i = 0; i < userBlocks.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      let result = await handleSubmit(userBlocks[i]);
      console.log(userBlocks[i]);
    }

    // TODO fix app crash when setting state here
    // Would be nice to add fancy loading but app crashes
    // setting state here causes app crash
    // cause currently unknown, URLparams has been used as a work around
    console.info('User Creation Complete');
    history.push(analyticsPageWithReload);
  }

  return (
    <div>
      <PageHeader
        title="Add Users:"
        subheader="Use the below CSV Uploader to add Multiple Users"
        backPath="back"
      />

      {loadingFile && <LoadingBackground visible />}

      <div>
        <DateFormatDialog
          handleClose={() => setOpenDateFormatDialog(false)}
          open={openDateFormatDialog}
          handleFileUpload={handleFileUpload}
          setLoadingFile={setLoadingFile}
          setDateFormat={setDateFormat}
        />
        {!tagData && (
          <>
            <Button
              variant="contained"
              color="primary"
              component="label"
              style={{ float: 'right' }}
              onClick={() => setOpenDateFormatDialog(true)}
            >
              Upload File
            </Button>
          </>
        )}
        {!tagData && (
          <Button
            variant="contained"
            color="primary"
            style={{ float: 'right', marginRight: 5 }}
            onClick={() => downloadCSV()}
          >
            Download Template
          </Button>
        )}
        {happyWithData ? (
          <div>
            <UploadTable
              users={data}
              dataErrors={validationError}
              disableActions
            />

            <div style={{ paddingTop: '1rem', paddingBottom: '10rem' }}>
              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right' }}
                onClick={() => setHappyWithData(false)}
                disabled={loading}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ float: 'right', marginRight: 5 }}
                onClick={() => clearDataAndFile()}
                disabled={loading}
              >
                Clear Data
              </Button>
              <Button
                disabled={!dataValid || loading}
                variant="contained"
                color="primary"
                style={{ float: 'right', marginRight: 5 }}
                onClick={() => handleSubmitWithTags(tagData, data)}
              >
                {loading ? 'Loading . . .' : 'Submit Data'}
              </Button>
              {loading && (
                <Typography
                  style={{ textDecoration: 'underline' }}
                  border
                  color="error"
                  align="center"
                >
                  CAUTION DO NOT CLOSE WINDOW
                </Typography>
              )}
            </div>
          </div>
        ) : (
          <div>
            {tagData && tagData.rows && tagData.rows.length > 0 ? (
              <TagTable tagData={tagData} />
            ) : (
              <Typography>
                Please use the Uploader to Submit User Data
              </Typography>
            )}
            <br />
            {tagData && (
              <div style={{ paddingBottom: '10rem' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'right' }}
                  onClick={() => clearDataAndFile()}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ float: 'right', marginRight: 5 }}
                  onClick={() => {
                    setHappyWithData(true);
                    window.scrollTo(0, 0);
                  }}
                >
                  Accept
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CSV;

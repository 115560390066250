import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import FormikForm from './FormikForm';
import { dateFormatSchema } from '../assets/validator';
import Select from './FormInputSelect';
import { Separators } from '../constants/Separators';

const styles = makeStyles(({ palette }) => ({
  dialogTitle: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
  },
}));

export default function DateFormatDialog(props) {
  const { open, loading, handleClose, handleFileUpload, setDateFormat } = props;
  const classes = styles();

  const formik = useFormik({
    initialValues: {
      dateOrder: '',
      separator: '', // separator currently just for ui
    },
    validationSchema: dateFormatSchema,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setDateFormat({
        dateString: values.dateOrder,
        separator: values.separator,
      });
      setSubmitting(false);
    },
  });

  const YearDayMonth = [
    'Day]Month]Year',
    'Day]Year]Month',
    'Month]Day]Year',
    'Month]Year]Day',
    'Year]Month]Day',
    'Year]Day]Month',
  ];

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <FormikForm formik={formik}>
        <DialogTitle className={classes.dialogTitle} id="dialog-title">
          Date Format
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            style={{ paddingTop: '0.5rem', paddingBottom: '1rem' }}
          >
            <Grid xs={12}>
              <DialogContentText>
                <Typography
                  style={{ paddingTop: '0.5rem', paddingBottom: '1rem' }}
                >
                  Select the date format of the CSV file you intend to upload
                </Typography>
              </DialogContentText>
            </Grid>

            <Grid item xs={8} style={{ paddingRight: '1rem' }}>
              <Select
                options={YearDayMonth.map((item) => ({
                  value: item,
                  label: item.replaceAll(']', formik.values.separator),
                }))}
                key="dateOrder"
                label="Date Format"
                name="dateOrder" // formik name
                errorText="Select date format"
              />
            </Grid>

            <Grid item xs={4} style={{ paddingRight: '1rem' }}>
              <Select
                options={Separators}
                key="separator"
                name="separator" // formik name
                label="Separator"
                errorText="Select date separator for date format"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {formik.values.dateOrder && formik.values.separator && (
            <Typography style={{ marginRight: '13.8rem' }} color="primary">
              {'Example '}
              {formik.values.dateOrder
                .replace('Day', '31')
                .replace('Month', '12')
                .replace('Year', new Date().getFullYear())
                .replaceAll(']', formik.values.separator)}
            </Typography>
          )}
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            component="label"
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            Continue
            {formik.isValid && (
              <input
                type="file"
                hidden
                accept=".csv,.xlsx,.xls"
                onChange={handleFileUpload} // pass file back to parent
              />
            )}
          </Button>
        </DialogActions>
      </FormikForm>
    </Dialog>
  );
}

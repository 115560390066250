/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import FormikForm from '../components/FormikForm';
import FormInputText from '../components/FormInputText';
import FormButton from '../components/FormButton';
import PageHeader from '../components/PageHeader';
import { CustomerFormSchema } from '../assets/validator';
import useApi from '../functions/useApi';
import Select from '../components/FormInputSelect';
import { TimeZones } from '../constants/TimeZones';

const style = makeStyles(() => ({
  buttonGroup: { marginTop: '1rem' },
  successAlert: { marginTop: '1rem', marginBottom: '1rem' },
}));

export default function ManageCustomer(props) {
  // TODO workout how to restructure
  const id = props.match.params.id;
  const classes = style();
  const { post, get, put } = useApi();
  const [successfullySubmitted, setSuccessfullySubmitted] = useState({
    submitted: false,
    error: false,
  });

  const [company, setCompany] = useState(false);

  const formik = useFormik({
    initialValues: {
      companyName: company.name || '',
      description: company.description || '',
      address: company.address || '',
      industry: company.industry || '',
      adminName: company.adminName || '',
      email: company.email || '',
      timeZone: company.timeZone || 'Australia/Brisbane',
    },
    enableReinitialize: true,
    validationSchema: CustomerFormSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        if (!id) {
          await post({ url: 'createcompany', body: values });
          console.log(values);
          setSuccessfullySubmitted({
            submitted: true,
            error: false,
          });
          resetForm();
        } else {
          // console.log(values);
          // await put({
          //   url: 'updatecompany',
          //   body: JSON.stringify(values),
          // });
          // TODO update put so it matches API
          console.log(values);
          setSuccessfullySubmitted({
            submitted: true,
            error: false,
          });
        }

        formik.setSubmitting(false);
      } catch (err) {
        // TODO do something with error
        console.log(err);
      }
    },
  });

  useEffect(async () => {
    if (id) {
      const results = await get({ url: `company/${id}` });
      setCompany(results.message);
    }
  }, [id]);

  return (
    <>
      <PageHeader
        title="Add Company:"
        subheader="Use the below form to add a company"
        backPath="back"
      />

      <div>
        {successfullySubmitted.submitted && (
          <Alert className={classes.successAlert} severity="success">
            Customer successfully created
          </Alert>
        )}

        <FormikForm formik={formik}>
          <FormInputText
            fullWidth
            name="companyName"
            label="Company Name:"
            type="text"
          />
          <Tooltip title="A description that the client wants an employees to see at the top of the app each login – eg Wellbeing Program, for company ABC:">
            <FormInputText
              fullWidth
              name="description"
              label="Assessment Title:"
              type="text"
            />
          </Tooltip>
          <FormInputText
            fullWidth
            name="address"
            label="Address:"
            type="text"
          />
          <FormInputText
            fullWidth
            name="industry"
            label="Industry:"
            type="text"
          />
          <FormInputText
            fullWidth
            name="adminName"
            label="Admin Name:"
            type="text"
          />
          <FormInputText fullWidth name="email" label="Email:" type="text" />
          <Select
            options={TimeZones}
            constantLabel
            key="timeZone"
            label="Time zone:" // label
            name="timeZone" // formik name
          />
          <FormButton
            customStyle={{ marginTop: '2rem', marginBottom: '1rem' }}
            fullWidth
            text={id ? 'Update' : 'Create'}
            loading={formik.isSubmitting}
          />
        </FormikForm>
      </div>
    </>
  );
}

import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useContext,
} from 'react';
// eslint-disable-next-line import/no-named-as-default
import NotificationsReducer from './NotificationsReducer';
import useApi from '../functions/useApi';
import { useAuthContext } from './AuthContext';

export const NotificationsContext = createContext();

export function useNotificationsContext() {
  return useContext(NotificationsContext);
}

const NotificationsContextProvider = (props) => {
  const [notifications, dispatchNotifications] = useReducer(
    NotificationsReducer,
    []
  );
  const [refresh, setRefresh] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const { get } = useApi();
  const { isAdmin } = useAuthContext();

  const notificationsReload = () => setRefresh(!refresh);

  useEffect(async () => {
    if (isAdmin) {
      setNotificationsLoading(true);
      try {
        const res = await get({ url: 'company/notification/templates' });

        dispatchNotifications({
          type: 'LOAD_NOTIFICATIONS',
          data: res.message,
        });
      } catch (error) {
        console.error(error);
      }
    }

    setNotificationsLoading(false);
  }, [refresh]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        dispatchNotifications,
        notificationsReload,
        notificationsLoading,
      }}
    >
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;

// eslint-disable-next-line import/no-duplicates
import React from 'react';
import Grid from '@mui/material/Grid';
import { useField } from 'formik';
// eslint-disable-next-line import/no-duplicates
import { addYears, formatISO, isValid } from 'date-fns';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import makeStyles from '@mui/styles/makeStyles';

const style = makeStyles(({ palette }) => ({
  keyboardDatePicker: {
    width: '100%',
  },
}));

const DatePickerComponent = (props) => {
  const classes = style();
  const [field, meta, helpers, fullWidth] = useField(props);
  const { id, label } = props;
  const hasError = Boolean(meta.touched && meta.error);
  const [selectedDate, setSelectedDate] = React.useState(
    formatISO(new Date(), { representation: 'date' })
  );
  const [value, setValue] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if (isValid(date)) {
      date.setHours(0, 0, 0, 0);
      helpers.setValue(date);
    } else {
      helpers.setError(true);
    }
  };

  return (
    <Grid item xs={12} style={{ paddingTop: '3rem' }}>
      <Container>
        {' '}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={label}
            value={selectedDate}
            onChange={(date) => {
              handleDateChange(date);
            }}
            disablePast
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                // sx={{ width: '90%', paddingLeft: '5%' }}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />
          {/*
          TODO: compare old and new picker
          <DatePicker
          className={classes.keyboardDatePicker}
          margin="normal"
          // label="Date picker dialog"
          format="dd/MM/yyyy"
          value={selectedDate}
          onChange={(date) => {
            handleDateChange(date);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          id={id}
          helperText={hasError ? `Please enter valid ${label}` : label}
          error={meta.error && meta.touched}
          onBlur={() => {
            helpers.setTouched(true);
          }}
          disableToolbar
          openTo="date"
          maxDate={addYears(new Date(), 1)}
          minDate={new Date()}
          autoOk
        /> */}
        </LocalizationProvider>
      </Container>
    </Grid>
  );
};

export default DatePickerComponent;

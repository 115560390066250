import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { PhotoCamera } from '@mui/icons-material';
import { ColorPicker } from 'mui-color';
import { makeStyles } from '@mui/styles';
import { DropzoneDialog } from 'react-mui-dropzone';
import useApi from '../functions/useApi';
import Logo from '../assets/demo_logo.png';

const styles = makeStyles(() => ({
  avatarLogo: {
    width: '192px',
    height: '192px',
  },
  colorPicker: {},
  uploadLogo: { marginTop: '160px' },
}));

export default function Settings(props) {
  const { put } = useApi();
  const { changePrimaryColor, changeSecondaryColor } = props;
  const { userDetails } = props;
  const [newlogo, setNewlogo] = useState(Logo);
  const [uploadOpen, setUploadOpen] = useState(false);
  const classes = styles();

  async function putTheme() {
    console.log('-', userDetails);
    const result = await put({
      url: 'updatecompany',
      body: JSON.stringify(userDetails),
    });
    console.log(result);
  }

  const handleThemeColorChange = (themeType, color) => {
    if (themeType === 'primary') {
      console.log('-', userDetails);
      changePrimaryColor(color);
      userDetails.theme_primary = color;
      putTheme();
    } else if (themeType === 'secondary') {
      userDetails.theme_secondary = color;
      changeSecondaryColor(color);
      putTheme();
    }
  };

  function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setNewlogo(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleUploadOpen = () => {
    setUploadOpen(!uploadOpen);
  };

  // TODO ADD route to post logo in base64
  // const token = await getAccessTokenSilently({
  //   audience: 'https://cernova-api-gateway.com',
  // });

  // axios({
  //   method: 'post',
  //   url: REACT_APP_API_URL + '/company/logo',
  //   responseType: 'stream',
  //   headers: { Authorization: `Bearer ${token}` },
  //   body: { image: base64 },
  // });

  return (
    <Container key="form20" maxWidth="md">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Tooltip title="Update Logo">
            <Button fullWidth onClick={handleUploadOpen}>
              <Avatar
                className={classes.avatarLogo}
                variant="square"
                src={newlogo || Logo}
              />
              <PhotoCamera className={classes.uploadLogo} />
            </Button>
          </Tooltip>

          <DropzoneDialog
            open={uploadOpen}
            acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
            showPreviews="true"
            onSave={(files) => {
              getBase64(files[0]);
              handleUploadOpen();
            }}
            dropzoneText="Drag and drop a logo here or click"
            maxFileSize={10000000}
            onClose={handleUploadOpen}
            filesLimit={1}
          />
        </Grid>
        <Grid item xs={12}>
          <div>
            <Tooltip title="Primary colour">
              <FormControlLabel
                className={classes.colorPicker}
                control={
                  <ColorPicker
                    hideTextfield
                    className={classes.colorPicker}
                    borderWidth={0}
                    value={userDetails.theme_primary}
                    onChange={(color) =>
                      handleThemeColorChange('primary', `#${color.hex}`)
                    }
                  />
                }
                label="Primary Colour"
              />
            </Tooltip>
            <Tooltip title="Secondary colour">
              <FormControlLabel
                className={classes.colorPicker}
                control={
                  <ColorPicker
                    hideTextfield
                    className={classes.colorPicker}
                    borderWidth={0}
                    value={userDetails.theme_secondary}
                    onChange={(color) =>
                      handleThemeColorChange('secondary', `#${color.hex}`)
                    }
                    defaultValue="red"
                  />
                }
                label="Secondary Colour"
              />
            </Tooltip>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

import React from 'react';
import Typography from '@mui/material/Typography';
import Error from '@mui/icons-material/Error';
import Card from '@mui/material/Card';

import makeStyles from '@mui/styles/makeStyles';

const NoUser = ({ user }) => {
  const useStyles = makeStyles((theme) => ({
    icon: { flex: 5, height: '5rem' },
    center: { justifyContent: 'center', display: 'flex' },
    card: { paddingBottom: '10rem', paddingTop: '2rem', marginTop: '1rem' },
    text: { paddingLeft: '0.5rem', paddingRight: '0.5rem' },
  }));
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.center}>
        <Error
          // fontSize="large"
          color="secondary"
          className={classes.icon}
        />
      </div>
      <Typography className={classes.text} variant="h6" align="center">
        Your account{' '}
        <Typography style={{ fontWeight: 'bold' }}>{user.email}</Typography> has
        not yet been connected to a organisation
      </Typography>
    </Card>
  );
};

export default NoUser;

/* eslint-disable react/no-array-index-key */
import React from 'react';
import format from 'date-fns/format';
import { jsPDF as JsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import GetApp from '@mui/icons-material/GetApp';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAssessmentContext } from '../context/AssessmentContext';
import PageHeader from '../components/PageHeader';
import { useCompanyContext } from '../context/CompanyContext';
import { useNotificationsContext } from '../context/NotificationsContext';
import { reduceArrayOfObjects, truncate, range } from '../functions/helpers';
import {
  BeforeAssessmentNotifications,
  OutOfScheduleNotifications,
} from '../constants/NotificationTypes';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const style = makeStyles(() => ({
  fab: {
    position: 'absolute',
    marginTop: 40,
    right: 40,
  },
  listItem: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  notificationListItem: {
    paddingTop: '0px',
    paddingBottom: '0px',
    lineHeight: '1.5',
    height: '40px',
  },
  tableContainer: {
    // margin: '1rem',
    width: '95%',
    marginLeft: '2.5%',
  },
  tableCell: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  tableCellTitle: {
    fontWeight: 'bold',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  tableHeadCell: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
}));

export default function AssessmentOverview() {
  const { form, nextAssessmentDate, futureAssessmentDates } =
    useAssessmentContext();
  const { notifications, notificationsLoading } = useNotificationsContext();
  const { timeZone } = useCompanyContext();
  const classes = style();

  const group = Object.values(
    form.reduce((r, a) => {
      // eslint-disable-next-line no-param-reassign
      r[a.category] = [...(r[a.category] || []), a];
      return r;
    }, {})
  );

  const handleClick = async () => {
    const input = document.getElementById('page');
    html2canvas(input, {
      scale: 1.1,
    }).then((canvas) => {
      const imgWidth = 208;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      const pdf = new JsPDF('p', 'mm', 'a4');
      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      // TODO make filename work in firefox
      // PDF file name does not work in firefox
      // currently works in chrome and edge
      pdf.save(`overview_${format(new Date(), 'dd_MM_yyyy')}.pdf`);
    });
  };

  return (
    <div>
      <Tooltip title="Download Report">
        <Fab
          onClick={() => handleClick()}
          className={classes.fab}
          edge="end"
          color="primary"
        >
          <GetApp id="icon_1" />
        </Fab>
      </Tooltip>
      <PageHeader
        title="Overview"
        subheader="Overview of configuration"
        backPath="back"
      />
      <Grid container id="page" style={{ paddingTop: '2rem' }}>
        <Grid item xs={6}>
          <TableContainer
            key="current-questions-table-container"
            component={Paper}
            className={classes.tableContainer}
          >
            <Table
              className={classes.table}
              aria-label="current question table"
            >
              <TableHead key="current-questions-head">
                <TableRow key="current-questions">
                  <TableCell className={classes.tableHeadCell}>
                    Current Questions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody key="current-questions-body">
                {group.map((category, i) => (
                  <>
                    <TableRow key={`${category[0].category}-category-row`}>
                      <TableCell
                        key={`${category[0].category}-cell`}
                        className={classes.tableCellTitle}
                      >
                        {category[0].category}
                      </TableCell>
                    </TableRow>

                    {category.map((q, o) => (
                      <TableRow key={`${q.question}-${o}-row`}>
                        <TableCell
                          key={`${q.question}-cell`}
                          className={classes.tableCell}
                          align="left"
                        >
                          {q.question}
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={6}>
          <TableContainer
            key="upcoming-assessments-table-container"
            component={Paper}
            className={classes.tableContainer}
          >
            <Table className={classes.table} aria-label="assessment table">
              <TableHead key="upcoming-assessments-head">
                <TableRow key="upcoming-assessments">
                  <TableCell
                    key="upcoming-assessments-cell"
                    className={classes.tableHeadCell}
                  >
                    Upcoming Assessments
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody key="time-body">
                <TableRow
                  key={`date-${JSON.stringify(nextAssessmentDate)}-row`}
                >
                  <TableCell
                    key="nextAssessmentDate-cell"
                    className={classes.tableCell}
                    align="left"
                  >
                    {nextAssessmentDate
                      ? format(
                          utcToZonedTime(nextAssessmentDate, timeZone),
                          'dd/MM/yyyy'
                        )
                      : ''}
                  </TableCell>
                </TableRow>
                {futureAssessmentDates.map((d, i) => (
                  <TableRow key={`date-${JSON.stringify(d)}-${i}`}>
                    <TableCell
                      key={`future-assessment-${format(d, 'dd/MM/yyyy')}-cell`}
                      className={classes.tableCell}
                      align="left"
                    >
                      {format(d, 'dd/MM/yyyy')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer
            key="notification-table-container"
            component={Paper}
            style={{ marginTop: '20px' }}
            className={classes.tableContainer}
          >
            <Table className={classes.table} aria-label="notification table">
              <TableHead key="Notification-head">
                <TableRow key="Notification-title">
                  <TableCell className={classes.tableHeadCell}>
                    Notifications
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} />
                </TableRow>
              </TableHead>

              <TableBody key="Notification-body">
                <TableRow key="Notification-type">
                  <TableCell className={classes.tableCellTitle} align="left">
                    Type
                  </TableCell>
                  <TableCell className={classes.tableCellTitle} align="left">
                    Time
                  </TableCell>
                </TableRow>

                {notifications
                  .filter((t) => !OutOfScheduleNotifications.includes(t.type))
                  .map((d) => (
                    <>
                      <TableRow key={`notification-${JSON.stringify(d)}}`}>
                        <TableCell
                          key={`${d.subject}-subject`}
                          className={classes.tableCell}
                          align="left"
                        >
                          {d.subject && truncate(d.subject, 50)}{' '}
                        </TableCell>
                        <TableCell
                          key={`${d.subject}-time`}
                          className={classes.tableCell}
                        >
                          {
                            reduceArrayOfObjects([
                              ...[...Array(16)].map((n, i) => ({
                                [`0 years 0 mons ${
                                  d.type === 'preAssessment' ? '-' : ''
                                }${i + 1} days 0 hours 0 mins 0.0 secs`]: `${
                                  !BeforeAssessmentNotifications.includes(
                                    d.type
                                  )
                                    ? 'In '
                                    : ''
                                }
                            ${i + 1} day${i + 1 <= 1 ? '' : 's'}${' '}
                            ${d.type === 'preAssessment' ? 'before' : ''}`,
                              })),
                              ...[
                                {
                                  '0 years 0 mons 0 days 0 hours 0 mins 0.0 secs':
                                    'Assessment day',
                                },
                              ],
                            ])[d.dayInterval]
                          }
                          {' at '}
                          {
                            reduceArrayOfObjects(
                              range(7, 17).map((n, i) => ({
                                [`0 years 0 mons 0 days ${n} hours 0 mins 0.0 secs`]:
                                  n <= 12 ? `${n} am` : `${n - 12}pm`,
                              }))
                            )[d.timeOfDayInterval]
                          }
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}

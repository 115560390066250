import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const FormInput = (props) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);
  const { options, label } = props;

  const radios = options.map((v) => {
    if (v instanceof Object && v.value && v.label)
      return (
        <FormControlLabel
          key={v.value}
          value={v.value}
          control={<Radio color="default" />}
          label={v.label}
          labelPlacement="end"
        />
      );
    if (typeof v === 'string' || typeof v === 'number') {
      return (
        <FormControlLabel
          key={v}
          value={v.toString()}
          control={<Radio color="default" />}
          label={v}
          labelPlacement="end"
        />
      );
    }
    return null;
  });

  return (
    <Grid key={label} item xs={12} style={{ marginTop: 10 }}>
      <Container align="left">
        <FormControl error={hasError} component="fieldset" size="small">
          <FormLabel
            component="label"
            sx={{
              color: 'black',
              fontSize: '1.2rem',
              '&.Mui-focused': {
                color: 'black',
                textDecoration: 'underline',
              },
            }}
          >
            {label}
          </FormLabel>
          <RadioGroup
            aria-label="position"
            {...field}
            value={
              typeof field.value === 'number'
                ? field.value.toString()
                : field.value
            }
            row
          >
            {radios}
          </RadioGroup>
          {hasError && (
            <FormHelperText>Please complete question</FormHelperText>
          )}
        </FormControl>
      </Container>
      <Divider sx={{ marginTop: '1rem' }} />
    </Grid>
  );
};

export default FormInput;

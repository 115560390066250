import React from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useField } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import Container from '@mui/material/Container';

const FormInputSelect = (props) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error);
  const { options, label, id, constantLabel, xs, disabled, errorText } = props;

  const selects = options.map((v) => {
    if (
      v instanceof Object &&
      (v.value || v.value === '' || v.value === 0) &&
      v.label
    )
      return (
        <MenuItem key={v.value + id} value={v.value}>
          {v.label}
        </MenuItem>
      );
    if (typeof v === 'string' || typeof v === 'number') {
      return (
        <MenuItem key={v} value={v.toString()}>
          {v}
        </MenuItem>
      );
    }
    return null;
  });

  return (
    <Grid key={label} item xs={xs || 12} style={{ marginTop: 5 }}>
      <Container>
        <FormControl fullWidth error={hasError} disabled={disabled}>
          {constantLabel ? (
            <FormLabel id={id}>{label}</FormLabel>
          ) : (
            <InputLabel id={id}>{label}</InputLabel>
          )}
          <Select
            labelId="demo-simple-select-label"
            label={label}
            id={id}
            {...field}
            defaultValue={field.value || ''}
          >
            {selects}
          </Select>
          {hasError && (
            <FormHelperText>
              {errorText || 'Please complete question'}
            </FormHelperText>
          )}
        </FormControl>
      </Container>
    </Grid>
  );
};

export default FormInputSelect;

/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const style = makeStyles(() => ({
  button: { float: 'right', marginRight: 5, marginTop: 15 },
}));

export default function PrivacyPage(props) {
  const classes = style();
  const { privacy } = props;
  const [privacyView, setPrivacyView] = useState(false);

  function handlePrivacyClose(event) {
    if (event) {
      setPrivacyView(false);
    }
  }
  function handlePrivacyOpen(event) {
    if (event) {
      setPrivacyView(true);
    }
  }

  const formattedPrivacy = privacy.map((v, i) => (
    <Typography
      key={`Privacy part ${i}`}
      style={{
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 16,
        marginRight: 16,
        textAlign: 'left',
      }}
    >
      {v}
    </Typography>
  ));

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="center"
      style={{ width: '100%' }}
    >
      {privacyView === false ? (
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handlePrivacyOpen}
        >
          View Privacy Policy
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handlePrivacyClose}
          >
            Hide Privacy Policy
          </Button>
          <Paper
            style={{ marginTop: 30, marginBottom: 30, textAlign: 'left' }}
            elevation={3}
          >
            {formattedPrivacy}
          </Paper>
        </>
      )}
    </Grid>
  );
}

import React from 'react';
import { useFormik } from 'formik';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import FormikForm from './FormikForm';
import Radio from './FormInputRadio';
import { useAuthContext } from '../context/AuthContext';
import { UserTypeObject } from '../constants/UserTypes';

const style = makeStyles(() => ({
  button: { float: 'right', marginRight: 5, marginTop: 15 },
  typography: { marginLeft: 15, marginBottom: '1rem' },
}));

export default function EulaPage(props) {
  const classes = style();
  const { hasUserType } = useAuthContext();
  const { acceptEula, setLoading } = props;
  const dummyRadio = {
    question: 'I don’t seem to be able to get much done at work',
    options: [
      '1: Rarely',
      '2: Occasionally',
      '3: Often',
      '4: Usually',
      '5: Most of the Time',
    ],
  };

  const formik = useFormik({
    initialValues: { [dummyRadio.question]: '' },
  });

  return (
    <div>
      <Typography className={classes.typography}>
        Cernova’s world leading Neurometric assessments analyse a multitude of
        factors including employee responses to the pressures they feel
        Mentally, Physically and Emotionally. You also have input into creating
        the questionnaire element of the Cernova assessments that the employees
        will be asked to complete.
      </Typography>
      <Typography className={classes.typography}>
        There are 3 sections and in each section you will be asked to select a
        maximum of 5 questions which will give you a total of 15 questions that
        the employees will be presented with each time. This is a real
        opportunity to delve into any known or perceived issues that employees
        may have, however if you don’t wish to select your own questions then
        our system will select the most requested questions for you.
      </Typography>
      <br />
      <Divider />
      <br />
      <Typography className={classes.typography}>
        The Questions will be answered by selection an answer from 1 - 5 example
        shown:
      </Typography>
      <FormikForm formik={formik}>
        <Radio
          options={dummyRadio.options}
          key={dummyRadio.question}
          label={dummyRadio.question}
          name={dummyRadio.question}
        />
      </FormikForm>
      <br />
      <br />
      <Typography className={classes.typography}>
        Your selection of questions will need to be chosen to finalise the
        onboarding process and before the system is able to go live to your
        employees. Please also ensure that you are happy with your selection of
        questions as your final list of questions will be locked in for 6 months
        for us to run our proprietary algorithms and triangulate the data.
      </Typography>
      {hasUserType(UserTypeObject.CERNOVA) && (
        <Button
          onClick={() => acceptEula({ type: 'default' })}
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={setLoading}
        >
          {/* Accept Terms */}
          Continue to select questions
        </Button>
      )}

      <Button
        onClick={() => acceptEula({ type: 'c25' })}
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={setLoading}
      >
        {/* Accept Terms */}
        Select C25 Questions
      </Button>
      {hasUserType(UserTypeObject.CERNOVA) && (
        <>
          <Button
            onClick={() => acceptEula({ type: 'c10' })}
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={setLoading}
          >
            {/* Accept Terms */}
            Select C10 Questions
          </Button>
          <Button
            disabled={setLoading}
            onClick={() => acceptEula({ type: 'custom' })}
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            {/* Accept Terms */}
            Continue to custom questions
          </Button>
        </>
      )}
    </div>
  );
}

import * as Yup from 'yup';
import {
  PhoneNumberUtil,
  PhoneNumberFormat,
  PhoneNumberType,
} from 'google-libphonenumber';
import { MonthNames } from '../constants/Months';
import { Separators } from '../constants/Separators';

export const validator = {
  name: /^(?=.{1,30}$)[a-zA-Z]+(?:['_.\s][a-zA-Z]+)*$/,
  email: /[a-z0-9._%+!$&*=^|~#%'`?{}/-]+@([a-z0-9-]+.){1,}([a-z]{2,16})/,
  username: /^[a-zA-Z0-9,.!?]*$/,
  phone: /^((?!(0))[+0-9]{5,15})$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})/,
};

export const CustomerFormSchema = Yup.object().shape({
  companyName: Yup.string().required('Please enter a Company Name'),
  description: Yup.string().required('Please enter a description.'),
  address: Yup.string().required('Please enter an Address.'),
  industry: Yup.string().required('Please enter an Industry.'),
  adminName: Yup.string().required('Please enter the Administrators Name.'),
  email: Yup.string().email('Email must be valid').required('Enter email'),
  timeZone: Yup.string().required('Select time zone'),
});

export const UserFormSchema = Yup.object().shape({
  userId: Yup.string(),
  companyName: Yup.string(),
  name: Yup.string()
    .matches(validator.name)
    .min(1, 'Too Short')
    .required('Please enter a valid Name'),
  email: Yup.string().email().required('Please enter a valid Email'),
  phoneNumber: Yup.string()
    .matches(validator.phone, 'Please enter a valid Phone Number')
    .required('Please enter a valid Phone Number'),
  role: Yup.string(),
  businessUnit: Yup.string(),
  department: Yup.string(),
  geography: Yup.string(),
  project: Yup.string(),
});

export const HelpFormSchema = Yup.object().shape({
  name: Yup.string()
    .matches(validator.name)
    .min(1, 'Too Short')
    .required('Please enter a valid Name'),
  email: Yup.string().email().required('Please enter a valid Email'),
  message: Yup.string().required('Please enter a message for help'),
});

export const QuestionFormSchema = Yup.object().shape({
  questionName: Yup.string().required('Please enter a valid Question'),
  questionCategory: Yup.string().required('Please select a Question Category'),
});

const phoneUtil = PhoneNumberUtil.getInstance();

Yup.addMethod(Yup.string, 'phone', function yupPhone() {
  return this.test('phone', 'Must be a valid mobile number.', (value) => {
    try {
      const phoneNumber = phoneUtil.parse(value, 'AU');
      return (
        phoneUtil.isValidNumber(phoneNumber) &&
        [PhoneNumberType.MOBILE, PhoneNumberType.FIXED_LINE_OR_MOBILE].includes(
          phoneUtil.getNumberType(phoneNumber)
        )
      );
    } catch (e) {
      if (value === undefined) {
        return true;
      }
      return false;
    }
  });
});

export function reformatPhoneNumber(value) {
  if (!value) {
    return value;
  }
  const phoneNumber = phoneUtil.parse(value, 'AU');
  const number = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
  return number.startsWith('+614') ? number : null;
}

export function reformatEmail(value) {
  if (!value) {
    return value;
  }

  return value.trim().toLowerCase();
}

export function reformatStartDate(value, separator, order) {
  if (!value) {
    // return value if not true
    return value;
  }

  // create array of separators found in string
  const characters = Separators.filter((s) => value.includes(s));

  // check if only 1 separator was found
  if (characters.length === 1) {
    // split string into hopefully 3 parts
    const dateParts = value.split(characters[0]);

    const year = () => {
      // if year is 4 numbers return
      // could fail is it is not numbers
      if (dateParts[order.year].length === 4) {
        return dateParts[order.year];
      }
      // convert year string to int so it can be compared
      const yearNumber = parseInt(dateParts[order.year], 10);

      // year is larger than current coming year probably is 20th century
      if (
        parseInt((new Date().getFullYear() + 1).toString().slice(2), 10) <
        yearNumber
      ) {
        // 20th century string
        return `19${dateParts[order.year]}`;
      }
      // 21st century string
      return `20${dateParts[order.year]}`;
    };

    const month = () => {
      // return if date is in number format
      if (parseInt(dateParts[order.month], 10)) {
        return dateParts[order.month];
      }

      // date should be string change lower case to find stuff easier
      const monthPart = dateParts[order.month].toLowerCase();

      // check if month matches a month as string
      if (MonthNames.includes(monthPart)) {
        // return month as int + 1 as array starts at 0
        // js browser month 1-12 node.js 0-11
        return MonthNames.indexOf(monthPart) + 1;
      }

      // check if month matches a month in shorthand as string
      const shortMonthNames = MonthNames.map((m) => m.slice(0, 3));
      if (shortMonthNames.includes(monthPart)) {
        // return month as int + 1 as array starts at 0
        // js browser month 1-12 node.js 0-11
        return shortMonthNames.indexOf(monthPart) + 1;
      }
      return dateParts[order.month];
    };

    // combine all together as string
    const dateObject = `${year()}-${month()}-${dateParts[order.day]}`;

    return dateObject;
  }
  return value;
}

export function reformatFirstLastNameTogether(firstName, lastName) {
  if (!firstName && !lastName) {
    return '';
  }

  return `${firstName.trim()} ${lastName.trim()}`.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
}

export function reformatName(name) {
  if (!name) {
    return name;
  }

  return name
    .trim()
    .replace(
      /(^\w|\s\w)(\S*)/g,
      (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
    );
}

export const CSVUploadSchema = Yup.object().shape({
  Email: Yup.string().matches(validator.email).required(),
  'Phone Number': Yup.string().phone(),
});
export const newTagScheme = Yup.object().shape({
  newTag: Yup.string().required(''),
});

export const dateFormatSchema = Yup.object().shape({
  dateOrder: Yup.string().required(),
  separator: Yup.string().required(),
});

export default validator;
